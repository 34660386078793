import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import TableInjector from './table/TableInjector';
import PictureInjector from './picture/PictureInjector';
import TextInjector from './text/TextInjector';
import FigureInjector from './figureInjector/FigureInjector';
import ITableCellTexture from '../graphic/table/cells/ITableCellTexture';
import { AnyComponentStructure, AnyConstructorInjector } from '../Types';
import InjectorFactory from '../factories/InjectorFactory';
import Dependent from '../utils/dependent/Dependent';
import ModuleInjector from './module/ModuleInjector';
import IComponent from '../components/IComponent';
import { Token } from '../mechanics/mext/parser';

export interface IComponentInjectorDependencies {
	injectorFactory: InjectorFactory,
}

/**
 * Сущность, отвечающая за процесс добавление новых компонентов в структуру.
 * Например, включает режим вставки фигуры, когда пользователь может выбрать
 * область, характеризующую размеры будущей фигуры.
 */
class ComponentInjector extends Dependent<IComponentInjectorDependencies> {
	private readonly injectors: AnyConstructorInjector[];
	private readonly disableInjectorListeners: VoidFunction[];
	private readonly prevInjectListeners: VoidFunction[];
	private readonly postInjectListeners: VoidFunction[];

	private textInjector: TextInjector;
	private pictureInjector: PictureInjector;
	private tableInjector: TableInjector;
	private figureInjector: FigureInjector;
	private moduleInjector: ModuleInjector;

	private runningInjector: AnyConstructorInjector | null;

	constructor() {
		super();
		this.injectors = [];
		this.runningInjector = null;
		this.prevInjectListeners = [];
		this.postInjectListeners = [];
		this.disableInjectorListeners = [];

		this.addPostInjectDependenciesListener(dependencies => {
			this.textInjector = dependencies.injectorFactory.getTextInjector();
			this.pictureInjector = dependencies.injectorFactory.getPictureInjector();
			this.tableInjector = dependencies.injectorFactory.getTableInjector();
			this.figureInjector = dependencies.injectorFactory.getFigureInjector();
			this.moduleInjector = dependencies.injectorFactory.getModuleInjector();

			this.injectors.push(
				this.textInjector,
				this.pictureInjector,
				this.tableInjector,
				this.figureInjector,
				this.moduleInjector,
			);
		});
	}

	/**
	 * Проверяет, активен ли в данный момент хотя бы один инжектор.
	 *
	 * @returns {boolean} `true`, если хотя бы один инжектор активен, иначе `false`.
	 */
	public isInjecting = (): boolean => this.injectors.some((injector) => injector.isInjecting());

	/**
	 * Запускает режим вставки текста.
	 */
	public runInjectText = () => {
		this.stopInjectors();
		this.textInjector.run();
		this.runningInjector = this.textInjector;
	};

	/**
	 * Запускает режим вставки текста с заданными параметрами.
	 * @param token - токен текста.
	 */
	public runInjectTextWithContent = (token: string | Token[]) => {
		this.stopInjectors();
		this.textInjector.loadToken(token);
		this.textInjector.run();
		this.runningInjector = this.textInjector;
	};

	/**
	 * Запускает режим вставки изображения.
	 */
	public runInjectPicture = () => {
		this.stopInjectors();
		this.pictureInjector.run();
		this.runningInjector = this.pictureInjector;
	};

	/**
	 * Вставляет таблицу с заданными параметрами.
	 *
	 * @param rowCount - количество строк.
	 * @param columnCount - количество столбцов.
	 * @param cells - массив текстур ячеек.
	 */
	public injectTable = (rowCount: number, columnCount: number, cells: ITableCellTexture[]) => {
		this.stopInjectors();
		this.tableInjector.loadSize(rowCount, columnCount);
		this.tableInjector.run();
		this.tableInjector.loadCellTextures(cells);
		this.runningInjector = this.tableInjector;
	};

	/**
	 * Вставляет пустую таблицу с заданными размерами.
	 *
	 * @param row - количество строк.
	 * @param columns - количество столбцов.
	 */
	public injectEmptyTable = (row: number, columns: number) => {
		this.stopInjectors();
		this.tableInjector.loadSize(row, columns);
		this.tableInjector.run();
		this.runningInjector = this.tableInjector;
	};

	/**
	 * Запускает режим вставки фигуры.
	 */
	public runInjectFigure = () => {
		this.stopInjectors();
		this.figureInjector.run();
		this.runningInjector = this.figureInjector;
	};

	/**
	 * Запускает режим вставки модуля.
	 * @param structure Структура модуля.
	 * @param preview Превью модуля.
	 */
	public injectModule = (structure: AnyComponentStructure, preview: string) => {
		this.stopInjectors();
		this.moduleInjector.loadStructure(structure, preview);
		this.moduleInjector.run();
		this.runningInjector = this.moduleInjector;
	};

	/**
	 * Останавливает процесс вставки.
	 */
	public stopInjecting = () => {
		if (this.runningInjector === null) {
			throw new ManipulatorError('a running injector cannot be null');
		}

		this.runningInjector.stop();
		this.runningInjector = null;
	};

	/**
	 * Выполняет вставку созданного компонента.
	 */
	public inject = () => {
		if (this.runningInjector === null) {
			throw new ManipulatorError('a running injector cannot be null');
		}
		this.callPrevInjectListeners();
		this.runningInjector.inject();
		this.callPostInjectListeners();
	};

	/**
	 * Проверяет, готов ли текущий инжектор к вставке компонента.
	 *
	 * @returns {boolean} `true`, если инжектор готов, иначе `false`.
	 */
	public isReadyInject = (): boolean => {
		if (this.runningInjector === null) {
			throw new ManipulatorError('a running injector cannot be null');
		}

		return this.runningInjector.isReadyInject();
	};

	/**
	 * Обработчик события нажатия кнопки мыши.
	 */
	public onMouseDown = () => {
		if (!this.runningInjector) {
			return;
		}
		this.runningInjector.onMouseDown();
	};

	/**
	 * Обработчик события отпускания кнопки мыши.
	 */
	public onMouseUp = () => {
		if (!this.runningInjector) {
			return;
		}
		this.runningInjector.onMouseUp();
	};

	public addSingleUseInjectListener = (listener: (component:IComponent) => void) => {
		if (this.runningInjector === null) {
			throw new ManipulatorError('running injector not found');
		}
		this.runningInjector.addSingleUseInjectListener(listener);
	};

	/**
	 * Добавляет обработчик события отключения всех инжекторов.
	 * @param listener - Обработчик события.
	 */
	public addDisableInjectorsListener = (listener: VoidFunction) => {
		this.disableInjectorListeners.push(listener);
	};

	/**
	 * Останавливает все инжекторы.
	 */
	public stopInjectors = () => {
		this.injectors.forEach(injector => injector.stop());
		this.callDisableInjectorsListeners();
	};

	public addPrevInjectListener = (listener: VoidFunction) => {
		this.prevInjectListeners.push(listener);
	};

	public addPostInjectListener = (listener: VoidFunction) => {
		this.postInjectListeners.push(listener);
	};

	private callDisableInjectorsListeners = () => {
		this.disableInjectorListeners.forEach(listener => listener());
	};

	private callPrevInjectListeners = () => {
		this.prevInjectListeners.forEach(listener => listener());
	};

	private callPostInjectListeners = () => {
		this.postInjectListeners.forEach(listener => listener());
	};
}

export default ComponentInjector;
