import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../../redux/reducers/reducer';
import CreateTagWindow from './module-tag/createTagWindow/CreateTagWindow';
import ModuleWindow from './ModuleWindow';
import css from './moduleWindow.module.scss';
import store from '../../../../redux/store/store';
import { onCloseModuleWindow, onThunkCloseCreateModuleWindow } from '../../../../redux/thunk-creators/moduleWindow';
import TagsMenuModalWrapper from './module-tag/tagMenuModal/TagsMenuModalWrapper';
import TagsGroupMenuModalWrapper from './module-tag/tagMenuModal/TagsGroupMenuModalWrapper';
import DeletingTagWrapper from './module-tag/deletingTagWindow/DeletingTagWrapper';
import DeletingTagGroupWrapper from './module-tag/deletingTagWindow/DeletingTagGroupWrapper';
import EditingTagWrapper from './module-tag/editTagWindow/EditingTagWrapper';
import EditingTagGroupWrapper from './module-tag/editTagWindow/EditingTagGroupWrapper';
import { categoryModalsActive } from '../../../../redux/selectors/module/category';
import { tagModalsActive } from '../../../../redux/selectors/module/tag';
import { tagsGroupModalsActive } from '../../../../redux/selectors/module/tagGroup';
import { all, moduleModalsActive } from '../../../../redux/selectors/module/module';
import UnbindTagWrapper from './module-tag/deletingTagWindow/UnbindTagWrapper';
import MultipleTagSelectionWindow
	from './editModuleWindow/editBlocks/tagGroupBlock/group/multipleTagSelectionWindow/MultipleTagSelectionWindow';
import CreateModuleWindowContainer from './createModuleWindow/CreateModuleWindowContainer';
import CreateTableModal from '../createTableModal/CreateTableModal';
import { NewModal } from '../../../ui/modals/new-modal';
import CreateTagGroupWindow from './module-tag/createTagGroupWindow/CreateTagGroupWindow';
import { DeleteCategoryModal } from '../../../../features/category/delete';
import { EditCategoryModal } from '../../../../features/category/edit/ui/edit-category-modal';
import { CreateCategoryModal } from '../../../../features/category/create/ui/create-category-modal';
import { setCreateTemplateWindowPreview } from '../../../../entities/modules/module/model/slice';
import {
	CreateWorkshopModuleModal,
} from '../../../../features/manage-workshop-module';
import { EditingTemplateModules } from '../../../../widgets/editing-template-modules';
import { WorkshopModulesModal } from '../../../../widgets/workshop-modules-modal/ui/modal/WorkshopModulesModal';
import { TemplateModulesModal } from '../../../../widgets/template-modules-modal';
import { BindWorkshopModuleTagModal } from '../../../../widgets/workshop-module-bind-tag-modal';
import { EditingWorkshopModules } from '../../../../widgets/editing-workshop-modules';
import {
	BindWorkshopModuleCategoryModal,
} from '../../../../widgets/workshop-module-bing-category-modal/ui/modal/BindWorkshopModuleCategoryModal';
import { AiCategoryModal } from '../../../../features/ai/ui/ai-category-modal/ai-category-modal';

interface ModuleWindowWrapperProps {
	setCreateTemplateWindowPreview: (preview: string) => void;
}

const ModalWindowWrapper: React.FC<ModuleWindowWrapperProps> = ({
	setCreateTemplateWindowPreview,
}: ModuleWindowWrapperProps) => {
	const [isCreateTemplateModalActive, setIsCreateTemplateModalActive] = useState(false);

	const {
		isModuleWindowOpen,
		isEditModuleModalActive,
		isCreateModuleWindowOpen,
		isBindingTagModuleModalActive,
	} = useSelector((state: RootState) => moduleModalsActive(state));

	const {
		isEditCategoryModalActive,
		isCreatingCategoryModalActive,
		isDeletingCategoryModalActive,
	} = useSelector((state: RootState) => categoryModalsActive(state));

	const {
		isCreatingTagModalActive,
		isTagMenuModalActive,
		isEditingTagModalActive,
		isDeletingTagModalActive,
	} = useSelector((state: RootState) => tagModalsActive(state));

	const {
		isEditTagGroupModalActive,
		isTagGroupMenuModalActive,
		isDeletingTagGroupModalActive,
		isCreatingTagGroupModalActive,
		isUnbindingTagGroupModalActive,
		isBindingTagGroupModalActive,
	} = useSelector((state: RootState) => tagsGroupModalsActive(state));

	const modalInModalActive = isCreatingTagModalActive
		|| isEditCategoryModalActive
		|| isCreateTemplateModalActive
		|| isCreatingCategoryModalActive
		|| isDeletingCategoryModalActive
		|| isTagMenuModalActive
		|| isEditingTagModalActive
		|| isCreatingTagGroupModalActive
		|| isTagGroupMenuModalActive
		|| isEditModuleModalActive
		|| isBindingTagModuleModalActive;

	const closeModuleModal = useSelector((state: RootState) => all(state).onCloseModuleModal);

	const onHandleModuleWindow = (value: boolean) => {
		if (!value && isModuleWindowOpen) {
			onCloseModuleWindow();

			if (closeModuleModal) {
				closeModuleModal();
			}
		}
	};

	const onHandleCreateModuleWindow = (value: boolean) => {
		if (!value && isCreateModuleWindowOpen) {
			store.dispatch(onThunkCloseCreateModuleWindow() as any);
		}
	};

	return (
		<>
			{/* <NewModal */}
			{/*	active={isModuleWindowOpen} */}
			{/*	setActive={onHandleModuleWindow} */}
			{/*	modalInModalActive={modalInModalActive} */}
			{/*	styles={css.modalContentCss} */}
			{/* > */}
			{/*	<ModuleWindow /> */}
			{/* </NewModal> */}
			<EditingWorkshopModules />
			<TemplateModulesModal />
			<BindWorkshopModuleTagModal />
			<BindWorkshopModuleCategoryModal />
			{/* <Modal */}
			{/* 	active={isModuleWindowOpen} */}
			{/* 	setActive={onHandleModuleWindow} */}
			{/* 	modalContentCss={css.modalContentCss} */}
			{/* 	modalInModalActive={modalInModalActive} */}
			{/* > */}
			{/* 	<ModuleWindow /> */}
			{/* </Modal> */}
			<CreateTableModal />
			<EditingTemplateModules
				modalInModalActive={
					isCreatingCategoryModalActive
					|| isEditCategoryModalActive
					|| isDeletingCategoryModalActive

					|| isEditingTagModalActive
					|| isDeletingTagModalActive
					|| isCreatingTagModalActive

					|| isEditTagGroupModalActive
					|| isDeletingTagGroupModalActive
					|| isUnbindingTagGroupModalActive
					|| isBindingTagGroupModalActive
					|| isCreatingTagGroupModalActive
				}
			/>
			<CreateTagGroupWindow />
			<DeletingTagGroupWrapper />
			<EditingTagGroupWrapper />
			<UnbindTagWrapper />
			<CreateCategoryModal />
			<MultipleTagSelectionWindow />
			<EditCategoryModal />
			<EditingTagWrapper />
			<DeletingTagWrapper />
			<DeleteCategoryModal />
			<CreateTagWindow />
			<TagsMenuModalWrapper
				modalInModalActive={
					isEditingTagModalActive
					|| isDeletingTagModalActive
					|| isCreatingTagModalActive
				}
			/>
			<TagsGroupMenuModalWrapper
				modalInModalActive={
					isEditTagGroupModalActive
					|| isDeletingTagGroupModalActive
					|| isCreatingTagGroupModalActive
				}
			/>
			<CreateWorkshopModuleModal />
			<WorkshopModulesModal />
			<AiCategoryModal />
			<NewModal
				active={isCreateModuleWindowOpen}
				closeModal={onHandleCreateModuleWindow}
			>
				<CreateModuleWindowContainer
					isModalActive={isCreateModuleWindowOpen}
				/>
			</NewModal>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setCreateTemplateWindowPreview: (value: string) => dispatch(setCreateTemplateWindowPreview(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalWindowWrapper);
