import React, {
	FC, useEffect, useRef, useState, 
} from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import css from './style.module.scss';
import { dropDownTransitionClasses } from '../../../../styles/transitionStyles/transitionStyles';
import { ReactComponent as OpenActiveArrowIcon } from '../../../../shared/images/open-active-arrow.svg';
import { ReactComponent as CloseArrowIcon } from '../../../../shared/images/close-arrow.svg';

interface IOption {
	title: string,
	value: string,
}

interface ISelect {
	options: IOption[];
	defaultOption: IOption,
	title: string,
}

export const AiCSelect: FC<ISelect> = ({
	title,
	options,
	defaultOption,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState(defaultOption);
	const dropDownRef = useRef(null);
	const titleRef = useRef(null);

	const handleIsOpen = () => {
		setIsOpen(isOpen => !isOpen);
	};

	const onClick = (event: React.MouseEvent) => {
		setData({
			title: event.currentTarget.innerHTML,
			value: event.currentTarget.innerHTML,
		});
		setIsOpen(false);
	};

	useEffect(() => {
		const handleBodyClick = (event: MouseEvent) => {
			const element = event.target as HTMLElement;
			if ((element
				&& dropDownRef.current !== null
				&& element !== dropDownRef.current
				&& element.contains(dropDownRef.current)
			) || element === titleRef.current
			// && !element.contains(dropDownRef.current)
			) {
				setIsOpen(false);
			}
		};
		document.body.addEventListener('click', handleBodyClick);

		return () => {
			document.body.removeEventListener('click', handleBodyClick);
		};
	}, []);

	return (
		<div className={css.select}>
			<div ref={titleRef} className={css.title}>{title}</div>
			<div
				className={css.selected}
				onClick={handleIsOpen}
				role="presentation"
			>
				<button
					type="button"
				>
					{data.title}
				</button>
				{
					isOpen
						? <OpenActiveArrowIcon />
						: <CloseArrowIcon />
				}
			</div>
			<CSSTransition
				in={isOpen}
				classNames={dropDownTransitionClasses}
				nodeRef={dropDownRef}
				timeout={200}
				unmountOnExit
			>
				<div ref={dropDownRef} className={css.options}>
					{
						options.map((opt) => {
							if	(opt.title === data.title) {
								return (
									<button
										type="button"
										onClick={onClick}
										className={css.checked}
									>
										{opt.title}
									</button>
								);
							} 
							return (
								<button
									type="button"
									onClick={onClick}
									className={css.option}
								>
									{opt.title}
								</button>
							);
						})
					}
				</div>
			</CSSTransition>
		</div>
	);
};
