export const styleTextSelectOptions = [
	{ title: 'По умолчанию...', value: 'Нейтральный' },
	{ title: 'Разговорный', value: 'Разговорный' },
	{ title: 'Деловой', value: 'Деловой' },
	{ title: 'Нейтральный', value: 'Нейтральный' },
	{ title: 'Информационный', value: 'Информационный' },
	{ title: 'Литературный', value: 'Литературный' },
	{ title: 'Дипломатический', value: 'Дипломатический' },
	{ title: 'Уверенный', value: 'Уверенный' },
	{ title: 'Дружелюбный', value: 'Дружелюбный' },
];
