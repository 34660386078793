import { notificationError, notificationSuccess } from '../../../../../../Notifications/callNotifcation';
import { workshopTemplateAPI } from '../../../../../../../entities/templates/workshop/template/api/api';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/libs/redux';
import { setSelectedTemplate } from '../../../../../../../entities/templates/workshop/template/model/slice';

export const useDuplicateWorkshopTemplates = () => {
	const dispatch = useAppDispatch();

	const [duplicate] = workshopTemplateAPI.useDuplicateMutation();

	const selectedTemplate = useAppSelector(state => state.workshopTemplate.selectedTemplate);

	const clearSelectedTemplate = () => dispatch(setSelectedTemplate([]));

	const duplicateTemplates = async () => {
		try {
			const templateIds = selectedTemplate.map(template => template.id);

			await duplicate({ templates: templateIds })
				.unwrap();
			notificationSuccess('Шаблон успешно дублирован!');
		} catch (e) {
			notificationError('Ошибка при дублировании шаблона');
		} finally {
			clearSelectedTemplate();
		}
	};

	return { duplicateTemplates };
};
