import ISketchStructure from '../../Sketch/ISketchStructure';
import MultiPageManipulator from '../MultiPageManipulator';
import StaticGraphicFactory from '../../Sketch/factories/graphic/StaticGraphicFactory';
import IPagesComponentTree from '../../Sketch/component-tree/IPagesComponentTree';
import PagesComponentTree from '../../Sketch/component-tree/impl/PagesComponentTree';
import ManipulatorSocket from '../../Sketch/replication/ManipulatorSocket';
import StaticComponentFactory from '../../Sketch/factories/component/StaticComponentFactory';

/**
 * Манипулятор компонентов, предназначенный только презентации предложения клиенту.
 */
class OfferPresenter
	extends MultiPageManipulator<IPagesComponentTree, StaticGraphicFactory, StaticComponentFactory, null, null> {
	private readonly socketConnection: ManipulatorSocket;

	constructor(manipulatorContainer: HTMLDivElement, structure: ISketchStructure) {
		super(manipulatorContainer);

		this.componentTree = new PagesComponentTree(manipulatorContainer);
		this.componentFactory = new StaticComponentFactory();
		this.graphicFactory = new StaticGraphicFactory();
		this.socketConnection = new ManipulatorSocket(structure.id);

		this.componentTree.connectDependencies({
			graphicFactory: this.graphicFactory,
			componentFactory: this.componentFactory,
		});
		this.graphicFactory.connectDependencies({
			componentTree: this.componentTree,
		});

		this.componentTree.injectDependencies();
		this.graphicFactory.injectDependencies();

		this.setID(structure.id);
		this.setName(structure.name);
		this.componentTree.load(structure.root);

		const embedElement = this.componentTree.getElementForEmbedding();
		this.manipulatorElement.append(embedElement);
	}

	protected savePreviewFile: (fileID: string) => {
		// nothing
	};
}

export default OfferPresenter;
