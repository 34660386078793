/**
 * Список категорий текста для AI
 */
export enum TextCategories {
	GREETINGS = 'Приветствие',
	PRODUCT_DESCRIPTION = 'Описание товара или услуги',
	ADVANTAGES = 'Преимущества (родительская)',
	ADVANTAGES_LIST = 'Список преимуществ',
	ADVANTAGES_DESCRIPTION = 'Описание преимуществ',
	TEAM = 'Команда',
	HEADER = 'Шапка',
	SLOGAN = 'Слоган',
	ABOUT_COMPANY = 'О компании',
	UNIQUE_PROPOSITION = 'Уникальное торговое предложение',
	WORK_STAGES = 'Этапы работы',
}

export interface ICategory {
	title: string,
	childs: ICategory[] | null,
}
