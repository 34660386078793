import React, { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Loader from 'react-ts-loaders';
import { useForm } from 'react-hook-form';
import { ModalWrapper } from '../../modal-wrapper/modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import {
	getIsAdvantagesListOpen,
	getIsGreetingsOpen, setAdvantagesListOpen,
	setIsAiCategoriesModalOpen,
} from '../../../model/slice/aiSlice';
import css from './css.module.scss';
import { ReactComponent as AiBtn } from '../../../../../shared/images/ai.svg';
import { AiCSelect } from '../../ai-select/AiCSelect';
import { styleTextSelectOptions } from '../../../model/styleTextSelectOptions';

type FormData = {
	ProductName: string,
	FieldActivity: string,
	StyleText: string,
};

/**
 * Модальное окно - список преимуществ
 */
export const AdvantagesListModal: FC = () => {
	const dispatch = useAppDispatch();
	const active = useAppSelector(getIsAdvantagesListOpen);

	const [textResponses, setTextResponses] = useState<string[]>([
		'нейросеть ответила тест 1',
	]);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<FormData>();

	const [data, setData] = useState('');

	// Добавить обработку запроса к нейросети (пока ждем видим loader)
	const [isLoading, setIsLoading] = useState(false);

	const onClose = () => {
		dispatch(setAdvantagesListOpen(false));
	};
	const onGoBack = () => {
		dispatch(setAdvantagesListOpen(false));
		dispatch(setIsAiCategoriesModalOpen(true));
	};

	const onSubmit = handleSubmit((data) => console.log(data));

	return (
		<ModalWrapper
			active={active}
			closeModal={onClose}
			onGoBack={onGoBack}
			title="Список преимуществ"
		>
			<form className={css.form} onSubmit={handleSubmit((data) => setData(JSON.stringify(data)))}>
				<label htmlFor="ProductName">
					Название товара или услуги
					<textarea 
						id="ProductName"
						{...register('ProductName', { required: true })}
						placeholder="Сайт под ключ"
					/>
					{errors.ProductName && (
						<span className={css.error} role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<label htmlFor="FieldActivity">
					Сфера деятельности
					<textarea
						id="FieldActivity"
						{...register('FieldActivity', {
							required: true,
						})}
						placeholder="IT"
					/>
					{errors.FieldActivity && (
						<span className={css.error} role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<div className={css.selectWrapper}>
					<AiCSelect
						title="Стиль текста"
						options={styleTextSelectOptions}
						defaultOption={{ title: 'По умолчанию...', value: 'Нейтральный' }}
					/>
				</div>

				<div className={css.submit}>
					<input className={css.submit_btn} type="submit" value="Написать" />
					<AiBtn className={css.submit_icon} />
				</div>
			</form>

			{
				(isLoading && textResponses.length == 0) && (
					<Loader
						type="roller"
						color="rgba(57, 150, 218, 1)"
						size={100}
						className="alt-loader"
					/>
				)
			}

			{textResponses.length >= 1 && (
				<div className={css.responsesWrapper}>
					<div className={css.responses}>
						{textResponses.map((data) => (
							<div key={uuid()} className={css.response}>
								{data}
							</div>
						))}
					</div>

					{isLoading && (
						<Loader
							type="roller"
							color="rgba(57, 150, 218, 1)"
							size={100}
							className="alt-loader"
						/>
					)}
				</div>
			)}
		</ModalWrapper>
	);
};
