import React, { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Loader from 'react-ts-loaders';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ModalWrapper } from '../../modal-wrapper/modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import {
	getIsTeamOpen,
	setIsAiCategoriesModalOpen,
	setTeamOpen,
} from '../../../model/slice/aiSlice';
import css from './css.module.scss';
import { ReactComponent as AiBtn } from '../../../../../shared/images/ai.svg';
import { symbolsCountSelectOptions } from '../../../model/symbolsCountSelectOptions';
import { AiCSelect } from '../../ai-select/AiCSelect';

type FormData = {
	KeywordName: string,
	gender: string,
	symbolsCount: string,
};

export const TeamModal: FC = () => {
	const dispatch = useAppDispatch();
	const active = useAppSelector(getIsTeamOpen);

	const [textResponses, setTextResponses] = useState<string[]>([
		'нейросеть ответила тест 1',
	]);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<FormData>();

	const [data, setData] = useState('');

	// Добавить обработку запроса к нейросети (пока ждем видим loader)
	const [isLoading, setIsLoading] = useState(false);

	const onClose = () => {
		dispatch(setTeamOpen(false));
	};
	const onGoBack = () => {
		dispatch(setTeamOpen(false));
		dispatch(setIsAiCategoriesModalOpen(true));
	};

	const onSubmit = handleSubmit((data) => console.log(data));

	return (
		<ModalWrapper
			active={active}
			closeModal={onClose}
			onGoBack={onGoBack}
			title="Описание члена команды"
		>
			<form className={css.form} onSubmit={handleSubmit((data) => setData(JSON.stringify(data)))}>
				<label htmlFor="KeywordName">
					Ключевые слова
					<textarea 
						id="KeywordName"
						{...register('KeywordName', { required: true })}
						placeholder="Старший менеджер Иванов"
					/>
					{errors.KeywordName && (
						<span className={css.error} role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<div className={css.selectWrapper}>
					<AiCSelect
						title="Пол"
						options={[
							{ title: 'муж', value: 'муж' },
							{ title: 'жен', value: 'жен' },
						]}
						defaultOption={{ title: 'муж', value: 'муж' }}
					/>

					<AiCSelect
						title="Количество символов"
						options={symbolsCountSelectOptions}
						defaultOption={{ title: 'По умолчанию...', value: '3000' }}
					/>
				</div>

				<div className={css.submit}>
					<input className={css.submit_btn} type="submit" value="Написать" />
					<AiBtn className={css.submit_icon} />
				</div>
			</form>

			{
				(isLoading && textResponses.length == 0) && (
					<Loader
						type="roller"
						color="rgba(57, 150, 218, 1)"
						size={100}
						className="alt-loader"
					/>
				)
			}

			{ textResponses.length >= 1 && (
				<div className={css.responsesWrapper}>
					<div className={css.responses}>
						{textResponses.map((data, index) => (
							<div key={uuid()} className={css.response}>
								{data}
							</div>
						))}
					</div>

					{isLoading && (
						<Loader
							type="roller"
							color="rgba(57, 150, 218, 1)"
							size={100}
							className="alt-loader"
						/>
					)}
				</div>
			)}
		</ModalWrapper>
	);
};
