import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import css from './TemplateModulesModal.module.scss';
import { classNames } from '../../../../shared/libs/classNames';
import { TemplateModulesModalTabs } from '../tabs/TemplateModulesModalTabs';
import { SettingsButton } from '../../../../shared/ui';
import { TemplateGalleryModules } from '../gallery-modules/TemplateGalleryModules';
import { TemplateEntityModules } from '../entity-modules/TemplateEntityModules';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import { templateModuleActions, templateModuleSelectors } from '../../../../entities/template-module';
import { ModuleTagBindModal } from '../../../module-tag-bind';
import { TemplateModuleCategoriesBar } from '../categories/bar/TemplateModuleCategoriesBar';

export enum TemplateModulesModalTabsEnum {
	ENTITY = 'Entity',
	GALLERY = 'Gallery',
}

interface TemplateModulesModalProps {
	className?: string,
}

export const TemplateModulesModal = ({ className }: TemplateModulesModalProps) => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(templateModuleSelectors.getIsTemplateModuleActive);

	const isBindingModuleActive = useAppSelector(
		state => state.moduleWindow.modalsActive.isBindingTagModuleModalActive,
	);
	const isEditModalActive = useSelector(templateModuleSelectors.getIsEditTemplateModuleActive);

	const [currentTab, setCurrentTab] = useState(TemplateModulesModalTabsEnum.GALLERY);

	const onOpenEditModuleWindow = () => {
		dispatch(templateModuleActions.setIsEditTemplateModuleActive(true));
	};

	const handleTemplateModal = (value: boolean) => {
		dispatch(templateModuleActions.setIsTemplateModuleActive(value));
	};

	return (
		<>
			<NewModal
				active={isActive}
				closeModal={handleTemplateModal}
				modalInModalActive={
					isEditModalActive || isBindingModuleActive
				}
			>
				<div className={classNames(css.wrapper, {}, [className])}>
					<div className={css.left_side}>
						<div className={css.sticky_tabs}>
							<TemplateModulesModalTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
						</div>
						<div className={css.modules_list}>
							{
								currentTab === TemplateModulesModalTabsEnum.ENTITY
									? <TemplateEntityModules />
									: <TemplateGalleryModules />
							}
						</div>
					</div>

					{/* Блок с категориями */}
					<div className={css.right_side}>
						<div className={css.categories}>
							<TemplateModuleCategoriesBar />
						</div>
						{
							currentTab === TemplateModulesModalTabsEnum.ENTITY && (
								<SettingsButton onClick={onOpenEditModuleWindow} />
							)
						}
					</div>
				</div>
			</NewModal>
			<ModuleTagBindModal />
		</>
	);
};
