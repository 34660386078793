import SpatialAreaTree from '../spatial-quadrants/spatial-tree/SpatialAreaTree';
import SketchStructureStabilizer from '../mutation-observer/SketchStructureStabilizer';
import ComponentFocusObserver from '../../utils/observers/ComponentFocusObserver';
import TableSplitter, { ITableSplitterDependencies } from './TableSplitter';
import ComponentOrganizer from '../component-organizer/ComponentOrganizer';

interface IMultiPageTableSplitterDependencies extends ITableSplitterDependencies {
    spatialTree: SpatialAreaTree,
    componentOrganizer: ComponentOrganizer,
    sketchStabilizer: SketchStructureStabilizer,
    componentFocusObserver: ComponentFocusObserver,
}

class MultiPageTableSplitter extends TableSplitter<IMultiPageTableSplitterDependencies> {
	constructor() {
		super();
		this.addPostSplitListeners(() => {
			setTimeout(this.dependencies.componentOrganizer.sync, 0);
			setTimeout(this.dependencies.componentFocusObserver.sync.bind(this), 100);
			setTimeout(this.dependencies.sketchStabilizer.stopUserAction, 100);
		});
	}
}

export default MultiPageTableSplitter;
