import React, { FC } from 'react';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import css from './modal.module.scss';
import CloseModalBtn from '../../../../components/ui/modals/closeModalBtn/CloseModalBtn';
import { useModal } from '../../../../components/ui/modals/new-modal/util/useModal';
import { ReactComponent as GoBackIcon } from '../../../../shared/images/collection/go-back-gray-arrow.svg';

interface Props {
	active: boolean,
	closeModal: (active: boolean) => void,
	onGoBack: () => void,
	styles?: string,
	children: React.ReactNode,
	modalInModalActive?: boolean,
	title: string,
}

const portal = document.getElementById('portal');

/** Обёртка с порталом для модальных окон AI */
export const ModalWrapper: FC<Props> = ({
	active,
	closeModal,
	title,
	styles,
	children,
	modalInModalActive,
	onGoBack,
}) => {
	const {
		modalRef,
		modalContentRef,
	} = useModal(closeModal, active, modalInModalActive);

	const onClose = () => closeModal(false);

	return (
		<>
			{
				ReactDOM.createPortal(
					<CSSTransition
						in={active}
						timeout={700}
						nodeRef={modalRef}
						classNames={{
							enter: css.modal_enter,
							enterActive: css.modal_enter_active,
							exit: css.modal_exit,
							exitActive: css.modal_exit_active,
						}}
						unmountOnExit
					>
						<div
							className={css.modal}
							ref={modalRef}
							role="presentation"
						>
							<div
								className={cx(css.content_wrapper, styles)}
								ref={modalContentRef}
							>
								<div className={css.header}>
									<GoBackIcon className={css.backBtn} onClick={onGoBack} />
									<h3>{title}</h3>
									<CloseModalBtn onClose={onClose} className={css.closeBtn} />
								</div>
								{children}
							</div>
						</div>
					</CSSTransition>,
					portal as Element,
				)
			}
		</>
	);
};
