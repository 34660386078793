import React, {
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import cx from 'classnames';
import css from './template-card.module.scss';
import { TemplatePicture } from './picture/picture';
import { SELECTABLE_ITEM_CLASS } from '../../../../hooks/template-selection/useSelection';
import { ITemplateTags } from '../../../../entities/templates/types';
import { TemplateTags } from './tags/tags';

interface ITemplateCard {
	id: string,
	name: string,
	preview: string,
	tags?: ITemplateTags,
}

export interface TemplateCardProps {
	styles?: string,
	selected?: boolean,
	description?: string,
	template: ITemplateCard,
	children?: ReactNode,
	isEditTitle?: boolean,
	onFinishEditTemplateTitle?: (id: string, name: string) => void,
}

export const TemplateCard = ({
	styles,
	template,
	selected,
	description,
	children,
	isEditTitle,
	onFinishEditTemplateTitle,
}: TemplateCardProps) => {
	const {
		id,
		preview,
		name,
		tags,
	} = template;

	// Для редактирования заголовка
	const [inputValue, setInputValue] = useState<string>(name);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const sizerRef = useRef<HTMLSpanElement | null>(null);

	const setFocusOnEditableTitle = () => {
		if (!inputRef.current) return;
		inputRef.current.focus();
		const { length } = inputRef.current.value;
		inputRef.current.setSelectionRange(length, length); // Устанавливаем каретку в конец
	};

	useEffect(() => {
		if (sizerRef.current && inputRef.current) {
			// Изменяем ширину input в зависимости от ширины sizer
			inputRef.current.style.width = `${sizerRef.current.scrollWidth + 9}px`;
		}
		if	(isEditTitle) setFocusOnEditableTitle();
	}, [inputValue, isEditTitle]);

	const onFinishEditTitle = onFinishEditTemplateTitle
		|| (() => console.log('Обработчик завершения редактирования имени шаблона не передан!'));

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter'
			|| event.key === 'Escape') {
			onFinishEditTitle(id, inputValue);
			inputRef.current?.blur();
		}
	};

	// Обработчик изменения текста в поле ввода
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
	};

	const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		// updateTemplate({ template: id, name: inputValue });
		onFinishEditTitle(id, inputValue);
		inputRef.current?.blur();
	};

	const inputClass = cx(css.title, {
		[css.titleActive]: isEditTitle,
	});

	// * Класс SELECTABLE_ITEM_CLASS необходим для корректной работы выделения карточек шаблонов
	// * Поменять в хуке useSelection
	return (
		<div id={id} className={cx(SELECTABLE_ITEM_CLASS, css.wrapper, selected && css.selected, styles)}>
			<TemplatePicture preview={preview} />
			<div className={css.info}>
				<input
					type="text"
					className={inputClass}
					ref={inputRef}
					value={inputValue}
					disabled={!isEditTitle}
					onKeyDown={handleKeyDown}
					onChange={handleInputChange}
					onBlur={onBlur}
				/>
				{tags && <TemplateTags tags={tags} />}
				<div className={css.description}>{description}</div>
			</div>
			{children}
		</div>
	);
};
