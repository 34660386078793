import React, { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Loader from 'react-ts-loaders';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ModalWrapper } from '../../modal-wrapper/modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import {
	getIsGreetingsOpen,
	getIsUniquePropositionOpen,
	setIsAiCategoriesModalOpen,
	setUniquePropositionOpen,
} from '../../../model/slice/aiSlice';
import css from './css.module.scss';
import { ReactComponent as AiBtn } from '../../../../../shared/images/ai.svg';

type FormData = {
	titleService: string,
	detailsInfo: string,
};

export const UniquePropositionalModal: FC = () => {
	const dispatch = useAppDispatch();
	const active = useAppSelector(getIsUniquePropositionOpen);

	// Для подсчета символов поля ввода доп. информации
	const [symbolsCount, setSymbolsCount] = useState(0);
	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setSymbolsCount(event.target.value.length);
	};

	const [textResponses, setTextResponses] = useState<string[]>([
		'нейросеть ответила тест 1',
		// 'нейросеть ответила тест 2',
	]);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<FormData>();

	const [data, setData] = useState('');

	// Добавить обработку запроса к нейросети (пока ждем видим loader)
	const [isLoading, setIsLoading] = useState(false);

	const onClose = () => {
		dispatch(setUniquePropositionOpen(false));
	};
	const onGoBack = () => {
		dispatch(setUniquePropositionOpen(false));
		dispatch(setIsAiCategoriesModalOpen(true));
	};

	const onSubmit = handleSubmit((data) => console.log(data));

	return (
		<ModalWrapper
			active={active}
			closeModal={onClose}
			onGoBack={onGoBack}
			title="Уникальное торговое предложение"
		>
			<form className={css.form} onSubmit={handleSubmit((data) => setData(JSON.stringify(data)))}>
				<label htmlFor="titleService">
					Название компании/товара/услуги
					<textarea 
						id="titleService"
						{...register('titleService', { required: true })}
						placeholder="Металлопрофиль"
					/>
					{errors.titleService && (
						<span className={css.error} role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				{/* textarea - Дополнительная информация */}
				<label htmlFor="detailsInfo">
					Дополнительная информация
					<span className={css.symbolsCount}>
						{symbolsCount}
						/200
					</span>
					<textarea
						id="detailsInfo"
						{...register('detailsInfo', {
							required: true,
						})}
						placeholder="Лучшее предложение на рынке"
						onChange={handleChange}
						maxLength={200}
					/>
					{errors.detailsInfo && (
						<span className={css.error} role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<div className={css.submit}>
					<input className={css.submit_btn} type="submit" value="Написать" />
					<AiBtn className={css.submit_icon} />
				</div>
			</form>

			{
				(isLoading && textResponses.length == 0) && (
					<Loader
						type="roller"
						color="rgba(57, 150, 218, 1)"
						size={100}
						className="alt-loader"
					/>
				)
			}

			{ textResponses.length >= 1 && (
				<div className={css.responsesWrapper}>
					<div className={css.responses}>
						{textResponses.map((data) => (
							<div key={uuid()} className={css.response}>
								{data}
							</div>
						))}
					</div>

					{isLoading && (
						<Loader
							type="roller"
							color="rgba(57, 150, 218, 1)"
							size={100}
							className="alt-loader"
						/>
					)}
				</div>
			)}
		</ModalWrapper>
	);
};
