import CursorHandler, { ICursorHandlerDependencies } from '../CursorHandler';
import SpatialAreaTree from '../../mechanics/spatial-quadrants/spatial-tree/SpatialAreaTree';
import IManipulatorInterface from '../../interface/IManipulatorInterface';
import ComponentInjector from '../../component-injector/ComponentInjector';
import ComponentOrganizer from '../../mechanics/component-organizer/ComponentOrganizer';
import IMutablePagesComponentTree from '../../component-tree/IMutablePagesComponentTree';
import SketchStructureStabilizer from '../../mechanics/mutation-observer/SketchStructureStabilizer';
import PrivateTemplateKeyboardObserver from '../../mechanics/keyboard-observer/PrivateTemplateKeyboardObserver';
import PrivateTemplateConstructor
	from '../../../SketchManipulators/PrivateTemplateConstructor/PrivateTemplateConstructor';
import ComponentFocusObserver from '../../utils/observers/ComponentFocusObserver';
import MagneticPositionCorrector from '../../mechanics/magnetic-lines/corrector/MagneticPositionCorrector';
import MagneticSizeCorrector from '../../mechanics/magnetic-lines/corrector/MagneticSizeCorrector';
import MouseButton from '../MouseButton';
import SpatialResizeArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/SpatialResizeArea';
import SpatialAreaType from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/SpatialAreaType';
import SpatialTableColumnResizeArea
	from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableColumnResizeArea';
import SpatialTableCellArea
	from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableCellArea';
import GraphicType from '../../graphic/GraphicType';
import IComponent from '../../components/IComponent';
import TableComponent from '../../components/table/TableComponent';
import SketchComponentType from '../../components/SketchComponentType';
import MouseEventType from '../MouseEventType';
import IOnStartMutationListenerProps
	from '../../mechanics/spatial-quadrants/area-mutators/events/IOnStartMutationListenerProps';
import HighlightArea from '../../mechanics/HighlightArea';
import AreaPositionWithCloneMutator
	from '../../mechanics/spatial-quadrants/area-mutators/mutators/AreaPositionWithCloneMutator';
import IBaseUseCases from '../../use-cases/base/IBaseUseCases';
import PrivateTemplateContextMenuHandler from '../../mechanics/context-menu/impl/PrivateTemplateContextMenuHandler';
import IMagneticLines from '../../mechanics/magnetic-lines/IMagneticLines';
import PageTextOverflowObserver from '../../utils/observers/PageTextOverflowObserver';
import PageStructureObserver from '../../utils/observers/PageStructureObserver';

interface IPrivateTemplateCursorHandlerDependencies extends ICursorHandlerDependencies {
	useCases: IBaseUseCases,
	areaTree: SpatialAreaTree,
	magneticLines: IMagneticLines,
	interface: IManipulatorInterface,
	componentInjector: ComponentInjector,
	componentOrganizer: ComponentOrganizer,
	componentTree: IMutablePagesComponentTree,
	sketchStabilizer: SketchStructureStabilizer,
	pageStructureObserver: PageStructureObserver,
	sketchConstructor: PrivateTemplateConstructor,
	componentFocusObserver: ComponentFocusObserver,
	contextMenu: PrivateTemplateContextMenuHandler,
	keyboardObserver: PrivateTemplateKeyboardObserver,
	pageTextOverflowObserver: PageTextOverflowObserver,
}

class PrivateTemplateCursorHandler extends CursorHandler<IPrivateTemplateCursorHandlerDependencies> {
	protected readonly highlightArea: HighlightArea;

	protected magneticPositionCorrector: MagneticPositionCorrector;
	protected areaPositionMutator: AreaPositionWithCloneMutator;
	protected magneticSizeCorrector: MagneticSizeCorrector;

	constructor() {
		super();

		this.highlightArea = new HighlightArea();

		this.addPostInjectDependenciesListener((dependencies) => {
			this.areaPositionMutator = new AreaPositionWithCloneMutator(
				dependencies.keyboardObserver,
				dependencies.useCases.cloneWithAlt,
			);

			this.magneticPositionCorrector = new MagneticPositionCorrector(
				dependencies.magneticLines,
				this.areaPositionMutator,
			);

			// this.tableRowMutator.addStartMutationListener(this.dependencies.sketchStabilizer.startUserAction);
			// this.tableRowMutator.addStopMutationListener(this.dependencies.componentOrganizer.sync);
			// this.tableRowMutator.addStopMutationListener(this.dependencies.areaTree.sync);
			// this.tableRowMutator.addStopMutationListener(this.dependencies.sketchStabilizer.stopUserAction);

			this.tableColumnMutator.addStartMutationListener(this.dependencies.sketchStabilizer.startUserAction);
			this.tableColumnMutator.addStopMutationListener(this.dependencies.componentOrganizer.sync);
			this.tableColumnMutator.addStopMutationListener(this.dependencies.areaTree.sync);
			this.tableColumnMutator.addStopMutationListener(this.dependencies.sketchStabilizer.stopUserAction);
			this.tableColumnMutator.addStopMutationListener(this.dependencies.pageStructureObserver.sync);

			this.areaPositionMutator.addStartMutationListener(this.dependencies.sketchStabilizer.startUserAction);
			this.areaPositionMutator.addStartMutationListener(this.onStartMagneticLines);
			this.areaPositionMutator.addPrevMutationListener(this.magneticPositionCorrector.move);
			this.areaPositionMutator.addPostMutationListener(this.dependencies.componentFocusObserver.sync);
			this.areaPositionMutator.addPostMutationListener(dependencies.interface.sync);
			this.areaPositionMutator.addStopMutationListener(dependencies.magneticLines.stop);
			this.areaPositionMutator.addStopMutationListener(this.dependencies.sketchStabilizer.stopUserAction);
			this.areaPositionMutator.addStopMutationListener(this.dependencies.pageTextOverflowObserver.sync);
			this.areaPositionMutator.addStopMutationListener(this.dependencies.pageStructureObserver.sync);

			this.magneticSizeCorrector = new MagneticSizeCorrector(
				dependencies.magneticLines,
				this.areaSizeMutator,
			);

			this.areaSizeMutator.addStartMutationListener(this.dependencies.sketchStabilizer.startUserAction);
			this.areaSizeMutator.addStartMutationListener(this.onStartMagneticLines);
			this.areaSizeMutator.addPrevMutationListener(this.magneticSizeCorrector.move);
			this.areaSizeMutator.addStopMutationListener(dependencies.magneticLines.stop);
			this.areaSizeMutator.addPostMutationListener(this.dependencies.componentFocusObserver.sync);
			this.areaSizeMutator.addPostMutationListener(dependencies.interface.sync);
			this.areaSizeMutator.addStopMutationListener(this.dependencies.sketchStabilizer.stopUserAction);
			this.areaSizeMutator.addStopMutationListener(this.dependencies.pageTextOverflowObserver.sync);
			this.areaSizeMutator.addStopMutationListener(this.dependencies.pageStructureObserver.sync);

			const workAreaElement = dependencies.componentTree.getWorkAreaElement();

			this.highlightArea.connectDependencies({
				areaTree: dependencies.areaTree,
				componentTree: dependencies.componentTree,
				rootElement: workAreaElement,
				mousePositionObserver: dependencies.mousePositionObserver,
			});

			this.highlightArea.injectDependencies();
		});
	}

	/**
	 * @inheritDoc
	 */
	protected onMouseMove = (offsetX: number, offsetY: number, event: MouseEvent): void => {
		const mousePosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		const components = this.dependencies.componentTree.getComponents();

		this.dependencies.areaTree.updateAreaOnPositionChange(mousePosition);
		this.syncHover(components);

		const activeArea = this.dependencies.areaTree.getActiveArea();
		this.dependencies.cursorView.setActiveArea(activeArea);

		this.areaSizeMutator.mutate(offsetX, offsetY);
		this.tableColumnMutator.mutate(offsetX, offsetY);
		this.areaPositionMutator.mutate(offsetX, offsetY);
		this.cellSelectionHandler.move(offsetX, offsetY, activeArea);

		if (this.highlightArea.isAreaActivated()) {
			this.dependencies.componentFocusObserver.sync();
		}
	};

	protected onMouseDown = (ev: MouseEvent) => {
		this.dependencies.contextMenu.hide();
		if (ev.button === MouseButton.RIGHT) {
			return;
		}

		const isInjectingComponent = this.dependencies.componentInjector.isInjecting();
		if (isInjectingComponent) {
			const isReadyInject = this.dependencies.componentInjector.isReadyInject();
			if (!isReadyInject) {
				this.dependencies.componentInjector.onMouseDown();
				return;
			}
			this.dependencies.componentInjector.inject();
			return;
		}

		const components = this.dependencies.componentTree.getComponents();
		const mousePosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		this.dependencies.areaTree.updateAreaOnPositionChange(mousePosition);
		const activeArea = this.dependencies.areaTree.getActiveArea();

		this.syncFocusMouseDown(ev);
		this.syncEditMode(components, ev);
		this.dependencies.interface.sync();
		const hasEditableOrFocusComponent = this.dependencies.componentTree.hasEditableOrFocusComponent();

		if (!hasEditableOrFocusComponent) {
			this.highlightArea.start(mousePosition);
		}
		if (activeArea === null) {
			this.dependencies.componentFocusObserver.sync();
			return;
		}
		if (activeArea.isResizeType()) {
			this.areaSizeMutator.start(activeArea as SpatialResizeArea<any>);
			return;
		}
		if (activeArea.type === SpatialAreaType.TABLE_COLUMN_RESIZE) {
			this.tableColumnMutator.start(activeArea as SpatialTableColumnResizeArea);
			return;
		}
		if (activeArea.type === SpatialAreaType.TABLE_CELL) {
			this.cellSelectionHandler.start(activeArea as SpatialTableCellArea);
		}
		const activeGraphicType = activeArea.getData().graphic.type;

		if (activeGraphicType === GraphicType.PAGE) {
			this.areaPositionMutator.startMoveComponents(
				activeArea,
				[this.dependencies.componentTree.getPagesContainer()],

			);
		}
		const focusComponents = this.dependencies.componentTree.getFocusComponents();

		if (focusComponents === null) {
			this.dependencies.componentFocusObserver.sync();
			return;
		}
		this.areaPositionMutator.startMoveComponents(activeArea, focusComponents);
		this.dependencies.componentFocusObserver.sync();
	};

	protected onMouseUp = (ev: MouseEvent) => {
		this.injectComponent();

		this.syncFocusMouseUp(ev);

		this.highlightArea.stop();
		this.areaSizeMutator.stop();
		this.tableColumnMutator.stop();
		this.areaPositionMutator.stop();
		this.cellSelectionHandler.stop();

		this.dependencies.componentOrganizer.sync();

		this.dependencies.areaTree.sync();
		this.dependencies.interface.sync();

		setTimeout(this.dependencies.componentFocusObserver.sync, 100);
	};

	protected onMouseContextMenu = (ev: MouseEvent) => {
		ev.preventDefault();
		this.dependencies.contextMenu.show();
	};

	/**
	 * Производить вставку нового компонента в случае, если был включен режим вставки.
	 */
	private injectComponent = () => {
		const isInjectingComponent = this.dependencies.componentInjector.isInjecting();
		if (isInjectingComponent) {
			const isReadyInject = this.dependencies.componentInjector.isReadyInject();
			if (!isReadyInject) {
				this.dependencies.componentInjector.onMouseUp();
			}
		}
	};

	/**
	 * Обновляет эффекты наведения на компоненты в зависимости от положения курсора.
	 */
	private syncHover = (components: IComponent[] | null) => {
		if (components === null) {
			return;
		}

		const crossedComponent = this.dependencies.areaTree.getHighestCrossComponent();

		// Если компонент является вложенным компонентом группы, проверяем нажат ли ctrl
		const parentComponent = crossedComponent?.getParentComponent();
		if (parentComponent?.isUniter && this.dependencies.keyboardObserver.isCtrlPressed) {
			components.forEach(component => {
				if (component === parentComponent) {
					component.enableHover();
					return;
				}
				component.disableHover();
			});
			return;
		}

		components.forEach(component => {
			if (component === crossedComponent) {
				component.enableHover();
				return;
			}
			component.disableHover();
		});
	};

	/**
	 * Обновляет эффекты фокуса на компонентах.
	 */
	private syncFocusMouseDown(ev: MouseEvent) {
		/* При получении самого близкого к пользователю компонента нужно учитывать находится ли какой-либо
		* uniter-компонент в режиме редактирования */
		const crossedComponent = this.dependencies.areaTree.getHighestCrossComponent();

		if (crossedComponent === null || crossedComponent.type === SketchComponentType.PAGES_CONTAINER) {
			this.disableFocusComponents(ev);
			return;
		}

		/* Логика для работы с uniter-компонентами */
		// Отключено для того, чтобы можно было перемещать несколько модулей одновременно
		// if (crossedComponent.isUniter || isFocusComponentsContainsGroup) {
		// 	focusComponents?.forEach(component => component.disableFocus());
		// }

		const isCrossedComponentNotFocus = !crossedComponent.isEnableFocus();
		if (isCrossedComponentNotFocus) {
			this.disableFocusComponents(ev);
		}

		// Если компонент isUniter и он в режиме редактирования
		const parent = crossedComponent.getParentComponent();
		if (parent && parent.isUniter && parent.isEnableEditMode()) {
			const childComponents = parent.getComponents();

			if (!childComponents) {
				return;
			}

			childComponents.forEach((component) => {
				if (component.type === SketchComponentType.TABLE) {
					(component as TableComponent).getFocusCellContexts()?.forEach((cell) => {
						cell.disableFocus();
					});
				}
				component.disableFocus();
			});
			crossedComponent.enableFocus();
			return;
		}

		/* Проверим на вложенную таблицу - она должна потерять фокус при клике не по вне её области, но
		в области uniter-компонента */
		if (crossedComponent.isUniter && crossedComponent.isEnableEditMode()) {
			const childComponents = crossedComponent.getComponents();
			if (!childComponents) return;
			childComponents.forEach((component) => {
				if (component.type === SketchComponentType.TABLE) {
					(component as TableComponent).getFocusCellContexts()?.forEach((cell) => {
						cell.disableFocus();
					});
				}
				component.disableFocus();
			});
			crossedComponent.enableFocus();
			return;
		}

		crossedComponent.enableFocus();
	}

	/**
	 * Отключает фокус у компонентов в фокусе.
	 * @param ev Событие мыши.
	 */
	private disableFocusComponents = (ev: MouseEvent) => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();

		focusComponents?.forEach(component => {
			if (ev.altKey || ev.metaKey) {
				return;
			}

			component.disableFocus();
		});
	};

	/**
	 * Синхронизирует фокус компонентов при отжатии кнопки мыши.
	 * @param ev
	 */
	private syncFocusMouseUp = (ev: MouseEvent) => {
		if (this.areaPositionMutator.isRunningMutate() && !this.areaPositionMutator.isBlindZoneOverstepping()) {
			const focusComponents = this.dependencies.componentTree.getFocusComponents();
			const crossedComponent = this.dependencies.areaTree.getHighestCrossComponent();

			// Если компонент является вложенным компонентом группы, проверяем нажат ли ctrl
			// const parentComponent = crossedComponent?.getParentComponent();
			// if (parentComponent?.isUniter && this.dependencies.keyboardObserver.isCtrlPressed) {
			// 	return;
			// }
			if (focusComponents !== null) {
				focusComponents.forEach(component => {
					if (ev.altKey || ev.metaKey) {
						return;
					}

					if (component !== crossedComponent) {
						component.disableFocus();
					}
				});
			}
		}
	};

	/**
	 * Обновляет состояния режима редактирования у компонентов.
	 */
	private syncEditMode = (components: IComponent[], ev: MouseEvent) => {
		const crossedComponent = this.dependencies.areaTree.getHighestCrossComponent();
		// Если текущий crossedComponent не пуст и событие двойного клика, включаем режим редактирования
		if (crossedComponent && ev.type === MouseEventType.DOUBLE_CLICK) {
			crossedComponent.enableEditMode();
			if (crossedComponent.isUniter) this.dependencies.editModeContext.setCurrentEditContext(crossedComponent);
			return;
		}

		if (ev.type === MouseEventType.MOUSE_DOWN) {
			let uniterContext = this.dependencies.editModeContext.getCurrentEditContext();
			// Если контекст есть, посмотреть нужно ли его обнулять
			if (uniterContext) {
				if (!crossedComponent || (crossedComponent !== uniterContext
					&& !uniterContext.isIncludeComponent(crossedComponent))
				) {
					this.dependencies.editModeContext.setCurrentEditContext(null);
					uniterContext = null;
				}
			}

			components.forEach(component => {
				// Для групп в группах необходимо заменить проверку на uniterContext
				if (component !== uniterContext && component !== crossedComponent) {
					component.disableEditMode();
				}
			});
		}
	};

	/**
	 * Обработчик события двойного нажатия мыши.
	 * @param ev Событие мыши.
	 */
	protected onDoubleClick = (ev: MouseEvent) => {
		const components = this.dependencies.componentTree.getComponents();

		this.syncEditMode(components, ev);
	};

	/**
	 * Обработчик события начала отрисовки магнитных линий.
	 * @param props
	 */
	private onStartMagneticLines = (props: IOnStartMutationListenerProps) => {
		if (this.dependencies.componentTree.hasEditableComponent()) {
			return;
		}

		this.dependencies.magneticLines.start(props);
	};
}

export default PrivateTemplateCursorHandler;
