import React, { FC, useRef, useState } from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import css from './aiCategory.module.scss';
import { dropDownTransitionClasses } from '../../../../styles/transitionStyles/transitionStyles';
import { ReactComponent as OpenActiveArrowIcon } from '../../../../shared/images/open-active-arrow.svg';
import { ReactComponent as CloseArrowIcon } from '../../../../shared/images/close-arrow.svg';
import { ICategory, TextCategories } from '../../model/types/types';
import {
	setAboutCompanyOpen,
	setAdvantagesDescriptionOpen,
	setAdvantagesListOpen, setSloganOpen,
	setIsAiCategoriesModalOpen,
	setIsGreetingsOpen,
	setIsProductDescriptionOpen, setTeamOpen, setUniquePropositionOpen, setWorkStagesOpen,
} from '../../model/slice/aiSlice';
import { useAppDispatch } from '../../../../shared/libs/redux';

interface IAiCategory {
	title: string;
	childs: ICategory[] | null,
	cssClass?: string,
}

export const AiCategory: FC<IAiCategory> = ({
	title,
	childs,
	cssClass,
}) => {
	AiCategory.defaultProps = {
		cssClass: undefined,
	};

	const [isOpen, setIsOpen] = useState(false);
	const dropDownRef = useRef(null);
	const dispatch = useAppDispatch();

	const handleIsOpen = () => {
		if (!childs) return;
		setIsOpen(isOpen => !isOpen);
	};

	const onClick = (ev: React.MouseEvent) => {
		if (childs) return;
		switch (ev.currentTarget.innerHTML) {
		case TextCategories.GREETINGS:
			dispatch(setIsAiCategoriesModalOpen(false));
			dispatch(setIsGreetingsOpen(true));
			break;
		case TextCategories.PRODUCT_DESCRIPTION:
			dispatch(setIsAiCategoriesModalOpen(false));
			dispatch(setIsProductDescriptionOpen(true));
			break;
		case TextCategories.ADVANTAGES_LIST:
			dispatch(setIsAiCategoriesModalOpen(false));
			dispatch(setAdvantagesListOpen(true));
			break;
		case TextCategories.ADVANTAGES_DESCRIPTION:
			dispatch(setIsAiCategoriesModalOpen(false));
			dispatch(setAdvantagesDescriptionOpen(true));
			break;
		case TextCategories.TEAM:
			dispatch(setIsAiCategoriesModalOpen(false));
			dispatch(setTeamOpen(true));
			break;
		case TextCategories.SLOGAN:
			dispatch(setIsAiCategoriesModalOpen(false));
			dispatch(setSloganOpen(true));
			break;
		case TextCategories.ABOUT_COMPANY:
			dispatch(setIsAiCategoriesModalOpen(false));
			dispatch(setAboutCompanyOpen(true));
			break;
		case TextCategories.UNIQUE_PROPOSITION:
			dispatch(setIsAiCategoriesModalOpen(false));
			dispatch(setUniquePropositionOpen(true));
			break;
		case TextCategories.WORK_STAGES:
			dispatch(setIsAiCategoriesModalOpen(false));
			dispatch(setWorkStagesOpen(true));
			break;
		}
	};

	const categoryName = cx(css.category_name, {
		[css.openTitle]: isOpen,
	});

	return (
		<div className={css.wrapper}>
			<div
				className={css.category}
				onClick={handleIsOpen}
				role="presentation"
			>
				<button	
					type="button"
					onClick={onClick}
					className={categoryName}
				>
					{title}
				</button>
				{
					childs && (isOpen
						? <OpenActiveArrowIcon />
						: <CloseArrowIcon />
					)
				}
			</div>
			<CSSTransition
				in={isOpen}
				classNames={dropDownTransitionClasses}
				nodeRef={dropDownRef}
				timeout={200}
				unmountOnExit
			>
				<div ref={dropDownRef} className={css.childs}>
					{childs && childs.map((child) => <AiCategory title={child.title} childs={child.childs} />)}
				</div>
			</CSSTransition>
		</div>
	);
};
