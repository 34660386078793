import ElementContainer from '../../utils/ElementContainer';
import HTMLElementName from '../../utils/HTMLElementName';
import IBaseUseCases from '../../use-cases/base/IBaseUseCases';
import SvgCollection from '../../utils/SvgCollection';
import IGraphic from '../../graphic/IGraphic';
import HTMLGenerator from '../../utils/HTMLGenerator';
import Utils from '../../utils/impl/Utils';

interface ICreateButtonProps {
	icon: SvgCollection,
	text: string,
	buttonClassName: string,
	onClickListener: VoidFunction,
}

class PictureActionLayer extends ElementContainer<HTMLDivElement> {
	private readonly COMPACT_MODE_CLASS_NAME = 'compact-mode';
	private readonly LAYER_CLASS_NAME = 'picture-action-layer';
	private readonly REPLACE_CLASS_NAME = 'picture-action-layer_replace';

	private readonly replaceButton: HTMLElement;

	constructor(containerElement: HTMLElement, graphic: IGraphic, useCases: IBaseUseCases) {
		super(HTMLElementName.DIV);

		this.setRootElementClassName(this.LAYER_CLASS_NAME);
		containerElement.append(this.rootElement);

		this.replaceButton = this.createButton({
			icon: SvgCollection.IMG_LOAD,
			buttonClassName: this.REPLACE_CLASS_NAME,
			onClickListener: useCases.loadPictureToFocusComponentWithAdaptPicture,
			text: 'Заменить',
		});

		this.rootElement.append(this.replaceButton);
	}

	public enableCompactMode = () => {
		this.rootElement.classList.add(this.COMPACT_MODE_CLASS_NAME);
		this.replaceButton.classList.add(this.COMPACT_MODE_CLASS_NAME);
	};

	public disableCompactMode = () => {
		this.rootElement.classList.add(this.COMPACT_MODE_CLASS_NAME);
		this.replaceButton.classList.remove(this.COMPACT_MODE_CLASS_NAME);
	};

	public enable = () => {
		this.replaceButton.style.display = 'flex';
	};

	public disable = () => {
		this.replaceButton.style.display = 'none';
	};

	private createButton({
		icon, buttonClassName, onClickListener, text,
	}: ICreateButtonProps): HTMLElement {
		const container = HTMLGenerator.getDiv();
		container.className = buttonClassName;
		Utils.DOM.injectSVG(container, icon);
		container.addEventListener('click', onClickListener);

		const textElement = HTMLGenerator.getSpan({ text });
		container.append(textElement);

		return container;
	}
}
export default PictureActionLayer;
