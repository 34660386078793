enum SvgCollection {
	COMPONENT_PICTURE_DEFAULT = `<svg class="picture-block__pending-picture" 
		viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M46 62H15.1046C13.0852 62 12.0755 62 11.6079 61.6007C11.2023 61.2542 10.987 
		60.7344 11.0288 60.2026C11.0771 59.5896 11.791 58.8756 13.219 57.4477L41.5621 29.1046C42.8821 
		27.7845 43.5422 27.1245 44.3033 26.8772C44.9728 26.6597 45.6939 26.6597 46.3634 26.8772C47.1245 
		27.1245 47.7845 27.7845 49.1046 29.1046L62 42V46M46 62C51.6005 62 54.4008 62 56.5399 
		60.9101C58.4215 59.9513 59.9513 58.4215 60.9101 56.5399C62 54.4008 62 51.6005 62 46M46 
		62H18C12.3995 62 9.59921 62 7.46009 60.9101C5.57847 59.9513 4.04867 58.4215 3.08993 
		56.5399C2 54.4008 2 51.6005 2 46V18C2 12.3995 2 9.59921 3.08993 7.46009C4.04867 
		5.57847 5.57847 4.04867 7.46009 3.08993C9.59921 2 12.3995 2 18 2H46C51.6005 2 
		54.4008 2 56.5399 3.08993C58.4215 4.04867 59.9513 5.57847 60.9101 7.46009C62 9.59921 
		62 12.3995 62 18V46M27 20.3333C27 24.0152 24.0152 27 20.3333 27C16.6514 27 13.6667 
		24.0152 13.6667 20.3333C13.6667 16.6514 16.6514 13.6667 20.3333 13.6667C24.0152 13.6667 
		27 16.6514 27 20.3333Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	BACKGROUND_PICTURE = `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" 
	viewBox="0 0 21 21" fill="none">
	<path d="M10.5 2H5.8C4.11984 2 3.27976 2 2.63803 2.32698C2.07354 2.6146 1.6146 3.07354 1.32698 3.63803C1 
	4.27976 1 5.11984 1 6.8V15.2C1 16.8802 1 17.7202 1.32698 18.362C1.6146 18.9265 2.07354 19.3854 2.63803 
	19.673C3.27976 20 4.11984 20 5.8 20H15C15.93 20 16.395 20 16.7765 19.8978C17.8117 19.6204 18.6204 18.8117 
	18.8978 17.7765C19 17.395 19 16.93 19 16M17 7V1M14 4H20M8.5 7.5C8.5 8.60457 7.60457 9.5 6.5 9.5C5.39543 9.5 
	4.5 8.60457 4.5 7.5C4.5 6.39543 5.39543 5.5 6.5 5.5C7.60457 5.5 8.5 6.39543 8.5 7.5ZM12.99 10.9181L4.53115 
	18.608C4.05536 19.0406 3.81747 19.2568 3.79643 19.4442C3.77819 19.6066 3.84045 19.7676 3.96319 19.8755C4.10478 
	20 4.42628 20 5.06929 20H14.456C15.8951 20 16.6147 20 17.1799 19.7582C17.8894 19.4547 18.4547 18.8894 
	18.7582 18.1799C19 17.6147 19 16.8951 19 15.456C19 14.9717 19 14.7296 18.9471 14.5042C18.8805 14.2208 18.753 
	13.9554 18.5733 13.7264C18.4303 13.5442 18.2412 13.3929 17.8631 13.0905L15.0658 10.8527C14.6874 10.5499 
	14.4982 10.3985 14.2898 10.3451C14.1061 10.298 13.9129 10.3041 13.7325 10.3627C13.5279 10.4291 13.3486 10.5921 
	12.99 10.9181Z" stroke="#7F8184" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>`,
	COMPONENT_PICTURE_PLUS = `<svg class="picture-block__pending-plus"
		viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M34.9993 21.667V48.3337M21.666 35.0003H48.3327M68.3327 35.0003C68.3327 53.4098 
		53.4088 68.3337 34.9993 68.3337C16.5899 68.3337 1.66602 53.4098 1.66602 35.0003C1.66602 
		16.5908 16.5899 1.66699 34.9993 1.66699C53.4088 1.66699 68.3327 16.5908 68.3327 35.0003Z" 
		stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	PAGE_PANEL_TOP_ARROW = `<svg class="stroke" xmlns="http://www.w3.org/2000/svg" width="14" height="8" 
		viewBox="0 0 14 8" fill="none">
		<path d="M13 7L7 1L1 7"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	PAGE_PANEL_BOTTOM_ARROW = `<svg class="stroke" xmlns="http://www.w3.org/2000/svg" 
		width="14" height="8" viewBox="0 0 14 8" fill="none">
		<path d="M1 1L7 7L13 1"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	PAGE_PANEL_CART = `<svg class="stroke" xmlns="http://www.w3.org/2000/svg" width="20" height="22" 
		viewBox="0 0 20 22" fill="none">
		<path d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 
		1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 
		2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 
		19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 
		20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5"  stroke-width="2"
		 stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	PAGE_PANEL_PLUS = `<svg class="fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
		viewBox="0 0 24 24" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M3.90476 12C3.90476 16.4709 7.52912 20.0952 12 20.0952C16.4709 
		20.0952 20.0952 16.4709 20.0952 12C20.0952 7.52912 16.4709 3.90476 12 3.90476C7.52912 3.90476 3.90476 7.52912 
		3.90476 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 
		17.5228 2 12 2Z" />
		<path d="M12.9524 8.13653C12.9524 7.61055 12.526 7.18415 12 7.18415C11.474 7.18415 11.0476 7.61055 11.0476 
		8.13653V10.8207H8.13653C7.61055 10.8207 7.18415 11.2471 7.18415 11.7731C7.18415 12.2991 7.61055 12.7254 8.13653 
		12.7254H11.0476V15.8638C11.0476 16.3898 11.474 16.8162 12 16.8162C12.526 16.8162 12.9524 16.3898 12.9524 
		15.8638V12.7254H15.8638C16.3898 12.7254 16.8162 12.2991 16.8162 11.7731C16.8162 11.2471 16.3898 10.8207 15.8638 
		10.8207H12.9524V8.13653Z" />
		</svg>`,
	PENCIL_BUTTON = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 19" fill="none">
		<path d="M9.16663 2.93427H5.66663C4.26649 2.93427 3.56643 2.93427 3.03165 3.20676C2.56124 
		3.44644 2.17879 3.82889 1.93911 4.2993C1.66663 4.83407 1.66663 5.53414 1.66663 6.93427V13.9343C1.66663 
		15.3344 1.66663 16.0345 1.93911 16.5692C2.17879 17.0397 2.56124 17.4221 3.03165 17.6618C3.56643 17.9343 
		4.26649 17.9343 5.66663 17.9343H12.6666C14.0668 17.9343 14.7668 17.9343 15.3016 17.6618C15.772 17.4221 
		16.1545 17.0397 16.3941 16.5692C16.6666 16.0345 16.6666 15.3344 16.6666 13.9343V10.4343M6.6666 
		12.9343H8.06206C8.46971 12.9343 8.67353 12.9343 8.86535 12.8882C9.03541 12.8474 9.19798 12.7801 
		9.3471 12.6887C9.5153 12.5856 9.65942 12.4415 9.94767 12.1532L17.9166 4.18427C18.607 3.49392 18.607 
		2.37463 17.9166 1.68427C17.2263 0.993916 16.107 0.993915 15.4166 1.68427L7.44765 9.65322C7.1594 9.94148 
		7.01527 10.0856 6.9122 10.2538C6.82082 10.4029 6.75348 10.5655 6.71265 10.7356C6.6666 10.9274 6.6666 
		11.1312 6.6666 11.5388V12.9343Z" stroke-width="1.66667" stroke-linecap="round" 
		stroke-linejoin="round"/></svg>`,
	PAGE_PANEL_COLLAGE_PLUS = `<svg width="22" height="22" viewBox="0 0 17 17" fill="none" 
		xmlns="http://www.w3.org/2000/svg">
		<path d="M11.342 15.75H11.75C12.4475 15.75 12.7962 15.75 13.0823 15.6733C13.4016 15.5878 13.6921 
		15.4349 13.938 15.2305M11.342 15.75H4.85M11.342 15.75C12.4214 15.75 12.961 15.75 13.3849 15.5687C13.5864 
		15.4825 13.7723 15.3682 13.938 15.2305M4.85 15.75C3.58988 15.75 2.95982 15.75 2.47852 15.5048C2.05516 
	 	15.289 1.71095 14.9448 1.49524 14.5215C1.25 14.0402 1.25 13.4101 1.25 12.15V5.85C1.25 4.58988 1.25 
	 	3.95982 1.49524 3.47852C1.71095 3.05516 2.05516 2.71095 2.47852 2.49524C2.95982 2.25 3.58988 2.25 
	 	4.85 2.25H8.375M4.85 15.75H4.30196M14.75 12.75C14.75 13.4475 14.75 13.7962 14.6733 14.0823C14.5823 
		14.422 14.415 14.7292 14.1906 14.9847M13.938 15.2305C14.0285 15.1553 14.113 15.0731 14.1906 14.9847M14.1906 
		14.9847C14.3462 14.8076 14.4743 14.6055 14.5687 14.3849C14.75 13.961 14.75 13.4214 14.75 12.342V11.6289M13.25 
		6V3.75M13.25 3.75V1.5M13.25 3.75H11M13.25 3.75H15.5M6.875 6.375C6.875 7.20343 6.20343 7.875 5.375 7.875C4.54657 
		7.875 3.875 7.20343 3.875 6.375C3.875 5.54657 4.54657 4.875 5.375 4.875C6.20343 4.875 6.875 5.54657 6.875 
		6.375ZM6.875 11.6282C6.875 12.4566 6.20343 13.1282 5.375 13.1282C4.54657 13.1282 3.875 12.4566 3.875 
		11.6282C3.875 10.7997 4.54657 10.1282 5.375 10.1282C6.20343 10.1282 6.875 10.7997 6.875 11.6282ZM12.1289 
		11.5C12.1289 12.364 11.4285 13.0645 10.5645 13.0645C9.70043 13.0645 9 12.364 9 11.5C9 10.636 9.70043 9.93555 
		10.5645 9.93555C11.4285 9.93555 12.1289 10.636 12.1289 11.5Z" 
		stroke="#7F8184" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
	portraitOrientationBtn = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" 
		fill="none">
		<path d="M11 1.26946V5.4C11 5.96005 11 6.24008 11.109 6.45399C11.2049 6.64215 11.3578 6.79513 11.546 
		6.89101C11.7599 7 12.0399 7 12.6 7H16.7305M17 8.98822V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854
		19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H5.8C4.11984 21 3.27976 21 2.63803 
		20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V5.8C1 4.11984 1 3.27976 
		1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H9.01178C9.74555 
		1 10.1124 1 10.4577 1.08289C10.7638 1.15638 11.0564 1.27759 11.3249 1.44208C11.6276 1.6276 11.887 1.88703
		12.4059 2.40589L15.5941 5.59411C16.113 6.11297 16.3724 6.3724 16.5579 6.67515C16.7224 6.94356 16.8436 
		7.2362 16.9171 7.5423C17 7.88757 17 8.25445 17 8.98822Z" stroke-width="2" 
		stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	landscapeOrientationBtn = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18"
 		fill="none">
		<path d="M20.7305 11L16.6 11C16.0399 11 15.7599 11 15.546 11.109C15.3578 11.2049 15.2049 11.3578 15.109 
		11.546C15 11.7599 15 12.0399 15 12.6L15 16.7305M13.0118 17L5.8 17C4.11984 17 3.27976 17 2.63803 
		16.673C2.07354 16.3854 1.6146 15.9265 1.32698 15.362C1 14.7202 0.999999 13.8802 1 12.2L1 5.8C1 4.11984 1 
		3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 0.999999 4.11984 0.999999 
		5.8 0.999999L16.2 1C17.8802 1 18.7202 1 19.362 1.32698C19.9265 1.6146 20.3854 2.07354 20.673 2.63803C21 
		3.27976 21 4.11984 21 5.8L21 9.01178C21 9.74555 21 10.1124 20.9171 10.4577C20.8436 10.7638 20.7224 
		11.0564 20.5579 11.3249C20.3724 11.6276 20.113 11.887 19.5941 12.4059L16.4059 15.5941C15.887 16.113 
		15.6276 16.3724 15.3249 16.5579C15.0564 16.7224 14.7638 16.8436 14.4577 16.9171C14.1124 17 13.7455 17 
		13.0118 17Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	backButton = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="23" viewBox="0 0 12 23" fill="none">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M11.6897 0.275955C12.0896 0.656841 12.105 1.28982 
			11.7241 1.68975L2.38095 11.5001L11.7241 21.3104C12.105 21.7104 12.0896 22.3433 11.6897 22.7242C11.2897 
			23.1051 10.6567 23.0897 10.2759 22.6897L0.275861 12.1897C-0.0919547 11.8035 -0.0919547 11.1966 0.275861 
			10.8104L10.2759 0.310438C10.6567 -0.0894921 11.2897 -0.104931 11.6897 0.275955Z" fill="#87909A"/>
			</svg>`,
	topPanelModules = `<svg xmlns="http://www.w3.org/2000/svg" 
			width="18" height="4" viewBox="0 0 18 4" fill="none">
			<path d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 
			3 9 3Z" stroke="#7F8184" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M16 3C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 
			15.4477 3 16 3Z" stroke="#7F8184" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 
			2 3Z" stroke="#7F8184" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>`,
	textTool = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M27 0C27.5523 -2.41411e-08 28 0.466027 28 1.0409V4.9591C28 
		5.53397 27.5523 6 27 6C26.4477 6 26 5.53397 26 4.9591V1.0409C26 0.466027 26.4477 2.41411e-08 27 0Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M1 0C1.55228 -2.41411e-08 2 0.466027 2 1.0409L2 4.9591C2 
		5.53397 1.55228 6 1 6C0.447716 6 2.85609e-07 5.53397 2.6048e-07 4.9591L8.92107e-08 1.0409C6.40822e-08 0.466027 
		0.447715 2.41411e-08 1 0Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C-2.41411e-08 0.447716 0.456746 1.21584e-06 1.02017 
		1.19121e-06L26.9798 5.64785e-08C27.5433 3.18503e-08 28 0.447715 28 0.999999C28 1.55228 27.5433 2 26.9798 
		2L1.02017 2C0.456746 2 2.41411e-08 1.55229 0 1Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M13 26.9791V1H15V26.9791C15 27.5429 14.5523 28 14 
		28C13.4477 28 13 27.5429 13 26.9791Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M18.0001 27.0001C18.0001 27.5523 17.5838 28.0001 17.0703 
		28.0001H10.9298C10.4163 28.0001 10.0001 27.5523 10.0001 27.0001C10.0001 26.4478 10.4163 26.0001 10.9298 
		26.0001H17.0703C17.5838 26.0001 18.0001 26.4478 18.0001 27.0001Z" />
		</svg>`,
	imgTool = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4774 3.11072e-07H15.5226C18.1976 -1.72055e-05 20.3014 
        -3.1019e-05 21.9445 0.220879C23.6294 0.447401 24.9702 0.92162 26.0243 1.97572C27.0784 3.02981 27.5526 4.37063 
        27.7791 6.05548C28 7.69858 28 9.80234 28 12.4774V15.5226C28 18.1977 28 20.3014 27.7791 21.9445C27.5526 23.6294 
        27.0784 24.9702 26.0243 26.0243C24.9702 27.0784 23.6294 27.5526 21.9445 27.7791C20.3014 28 18.1977 28 15.5226 
        28H12.4774C9.80234 28 7.69858 28 6.05548 27.7791C4.37063 27.5526 3.02981 27.0784 1.97572 26.0243C0.92162 
        24.9702 0.447401 23.6294 0.220879 21.9445C-3.1019e-05 20.3014 -1.72055e-05 18.1976 3.11072e-07 
        15.5226V12.4774C-1.72055e-05 9.80236 -3.1019e-05 7.69859 0.220879 6.05548C0.447401 4.37063 0.92162 
        3.02981 1.97572 1.97572C3.02981 0.92162 4.37063 0.447401 6.05548 0.220879C7.69859 -3.1019e-05 9.80236 
        -1.72055e-05 12.4774 3.11072e-07ZM6.3065 2.08793C4.83055 2.28636 3.95353 2.66206 3.3078 3.3078C2.66206 
        3.95353 2.28636 4.83055 2.08793 6.3065C1.88585 7.80954 1.88385 9.78664 1.88385 12.5491V15.4509C1.88385 
        15.6839 1.88386 15.9113 1.88401 16.1333C2.30095 15.6611 2.80973 15.1525 3.4281 14.5344C3.46852 14.494 
        3.5094 14.4531 3.55075 14.4118L3.8121 14.1504C4.75456 13.208 5.51639 12.4461 6.17646 11.9148C6.85007 
        11.3727 7.53825 10.9679 8.34528 10.8998C9.05047 10.8403 9.7584 10.9766 10.3911 11.2938C11.1172 11.6578 
        11.6078 12.2963 12.0344 13.0568C12.4536 13.8041 12.8838 14.8078 13.4169 16.0519L13.4389 16.1032C13.7063 
        16.7272 13.8798 17.1292 14.0355 17.4107C14.1648 17.6444 14.2328 17.7004 14.2432 17.7078C14.3376 17.7599 
        14.4461 17.7808 14.5531 17.7673C14.539 17.7691 14.6098 17.7701 14.8514 17.5731C15.0964 17.3735 15.401 
        17.0705 15.8742 16.5973L15.9044 16.5671C16.4971 15.9744 16.986 15.4854 17.4196 15.1234C17.8711 14.7464 
        18.3284 14.449 18.8761 14.2962C19.4857 14.1262 20.1277 14.1093 20.7453 14.247C21.3004 14.3708 21.7727 
        14.6438 22.2433 14.9965C22.6953 15.3353 23.2093 15.7979 23.8323 16.3586L24.1754 16.6674C24.2209 16.7083 
        24.2658 16.7488 24.3103 16.7888C25.0361 17.4418 25.6248 17.9713 26.1016 18.4703C26.1158 17.5813 26.1162 
        16.5819 26.1162 15.4509V12.5491C26.1162 9.78664 26.1142 7.80954 25.9121 6.3065C25.7136 4.83055 25.3379 
        3.95353 24.6922 3.3078C24.0465 2.66206 23.1695 2.28636 21.6935 2.08793C20.1905 1.88585 18.2134 1.88385 
        15.4509 1.88385H12.5491C9.78664 1.88385 7.80954 1.88585 6.3065 2.08793ZM25.9213 21.6235C25.905 21.5881 
        25.8883 21.5518 25.8713 21.515C25.7299 21.2086 25.5914 20.9143 25.5421 20.822C25.1585 20.1043 24.5309 
        19.5218 22.9151 18.0676L22.6038 17.7875C21.9411 17.191 21.4897 16.7859 21.1135 16.504C20.7482 16.2302 
        20.5237 16.1277 20.3353 16.0857C20.0204 16.0155 19.693 16.0241 19.3822 16.1108C19.1963 16.1626 18.9775 
        16.2767 18.6271 16.5693C18.2662 16.8707 17.8368 17.2989 17.2063 17.9294L17.1729 17.9627C16.7427 18.393 
        16.37 18.7657 16.0415 19.0335C15.7012 19.3108 15.2961 19.5723 14.789 19.6363C14.285 19.6999 13.7739 
        19.6015 13.3296 19.3552C12.8805 19.1064 12.6005 18.7085 12.387 18.3224C12.1798 17.9478 11.9695 17.457 
        11.7259 16.8885L11.7074 16.8453C11.1471 15.5379 10.7555 14.6275 10.3914 13.9785C10.029 13.3325 9.76935 
        13.0894 9.54681 12.9778C9.22423 12.8161 8.86326 12.7466 8.50369 12.777C8.25326 12.7981 7.9246 12.926 
        7.35765 13.3824C6.78639 13.8422 6.09605 14.5306 5.10447 15.5222L4.88283 15.7439C3.41425 17.2125 2.84393 
        17.7997 2.50336 18.4983C2.37941 18.7525 2.27787 19.0171 2.19992 19.289C2.08073 19.7048 2.05102 19.8872 
        2.04832 20.1807C2.04603 20.4299 2.0634 20.7304 2.09211 21.2273C2.10491 21.4487 2.11995 21.7091 2.13652 
        22.0212C2.3453 23.302 2.71136 24.0958 3.3078 24.6922C3.95353 25.3379 4.83055 25.7136 6.3065 25.9121C7.80954 
        26.1142 9.78664 26.1162 12.5491 26.1162H15.4509C18.2134 26.1162 20.1905 26.1142 21.6935 25.9121C23.1695 
        25.7136 24.0465 25.3379 24.6922 24.6922C25.3379 24.0465 25.7136 23.1695 25.9121 21.6935C25.9152 21.6703 
        25.9183 21.6469 25.9213 21.6235ZM14.2446 17.7087C14.2446 17.7087 14.2441 17.7084 14.2432 17.7078L14.2446 
        17.7087ZM20.529 6.23654C19.8473 6.23654 19.2946 6.78921 19.2946 7.47096C19.2946 8.15272 19.8473 8.70539 
        20.529 8.70539C21.2108 8.70539 21.7635 8.15272 21.7635 7.47096C21.7635 6.78921 21.2108 6.23654 20.529 
        6.23654ZM17.4108 7.47096C17.4108 5.74879 18.8069 4.35269 20.529 4.35269C22.2512 4.35269 23.6473 5.74879 
        23.6473 7.47096C23.6473 9.19314 22.2512 10.5892 20.529 10.5892C18.8069 10.5892 17.4108 
        9.19314 17.4108 7.47096Z" />
    	</svg>`,
	lineTool = `
		<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7165 0.283454C28.0945 0.661393 28.0945 1.27415 27.7165 
        1.65209L1.65209 27.7165C1.27415 28.0945 0.661393 28.0945 0.283454 27.7165C-0.0944848 27.3386 -0.0944848 
        26.7258 0.283454 26.3479L26.3479 0.283454C26.7258 -0.0944848 27.3386 -0.0944848 27.7165 0.283454Z" />
    	</svg>
	`,
	pagesTool = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
		<path d="M17 9.5V5.8C17 4.11984 17 3.27976 16.673 2.63803C16.3854 2.07354 15.9265 1.6146 15.362 
		1.32698C14.7202 1 13.8802 1 12.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 
		2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 
		19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21H8.5M19 21L17.5 19.5M18.5 17C18.5 
		18.933 16.933 20.5 15 20.5C13.067 20.5 11.5 18.933 11.5 17C11.5 15.067 13.067 13.5 15 13.5C16.933 
		13.5 18.5 15.067 18.5 17Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	modulesTool = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="5.5" cy="5.5" r="2.625" stroke-width="1.75"/>
		<circle cx="16" cy="5.5" r="2.625" stroke-width="1.75"/>
		<circle cx="26.5" cy="5.5" r="2.625"  stroke-width="1.75"/>
		<circle cx="5.5" cy="16" r="2.625" stroke-width="1.75"/>
		<circle cx="16" cy="16" r="2.625" stroke-width="1.75"/>
		<circle cx="26.5" cy="16" r="2.625" stroke-width="1.75"/>
		<circle cx="5.5" cy="26.5" r="2.625" stroke-width="1.75"/>
		<circle cx="16" cy="26.5" r="2.625" stroke-width="1.75"/>
		<circle cx="26.5" cy="26.5" r="2.625" stroke-width="1.75"/>
		</svg>`,
	backTool = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
		<path d="M6.95833 13.5L1 7.25M1 7.25L6.95833 1M1 7.25L18.0625 7.24999C22.9985 7.24999 27 11.4473 27 16.625C27 
		21.8027 22.9985 26 18.0625 26L13.4583 26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	moveForward = `<svg class='layers-panel__row-svg' xmlns="http://www.w3.org/2000/svg" width="24" 
		height="24" viewBox="0 0 24 24" fill="none">
		<path d="M2 14.4996L11.6422 19.3207C11.7734 19.3863 11.839 19.4191 11.9078 19.432C11.9687 19.4434 12.0313 
		19.4434 12.0922 19.432C12.161 19.4191 12.2266 19.3863 12.3578 19.3207L22 14.4996M2 9.49958L11.6422 
		4.67846C11.7734 4.61287 11.839 4.58008 11.9078 4.56717C11.9687 4.55574 12.0313 4.55574 12.0922 
		4.56717C12.161 4.58008 12.2266 4.61287 12.3578 4.67846L22 9.49958L12.3578 14.3207C12.2266 14.3863 12.161 
		14.4191 12.0922 14.432C12.0313 14.4434 11.9687 14.4434 11.9078 14.432C11.839 14.4191 11.7734 14.3863 
		11.6422 14.3207L2 9.49958Z" stroke="#272727" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M12.3578 4.67846C12.2266 4.61287 12.161 4.58008 12.0922 4.56717C12.0313 4.55574 11.9687 4.55574 
		11.9078 4.56717C11.839 4.58008 11.7734 4.61287 11.6422 4.67846L2 9.49958L11.6422 14.3207C11.7734 14.3863 
		11.839 14.4191 11.9078 14.432C11.9687 14.4434 12.0313 14.4434 12.0922 14.432C12.161 14.4191 12.2266 14.3863 
		12.3578 14.3207L22 9.49958L12.3578 4.67846Z" fill="#0075FF" stroke="#0075FF" stroke-width="2" 
		stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	moveBack = `<svg class='layers-panel__row-svg'  xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
		viewBox="0 0 24 24" fill="none">
		<path d="M2 13.9414L11.6422 18.7625C11.7734 18.8281 11.839 18.8609 11.9078 18.8738C11.9687 18.8852 12.0313 
		18.8852 12.0922 18.8738C12.161 18.8609 12.2266 18.8281 12.3578 18.7625L22 13.9414" stroke="#0075FF" 
		stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M11.6422 4.11987L2 8.94098L11.6422 13.7621C11.7734 13.8277 11.839 13.8605 11.9078 
		13.8734C11.9687 13.8848 12.0313 13.8848 12.0922 13.8734C12.161 13.8605 12.2266 13.8277 12.3578 13.7621L22 
		8.94098L12.3578 
		4.11987C12.2266 4.05428 12.161 4.02148 12.0922 4.00857C12.0313 3.99714 11.9687 3.99714 11.9078 
		4.00857C11.839 4.02148 11.7734 4.05428 11.6422 4.11987Z" fill="#272727" stroke="#272727" stroke-width="2"
		stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	moveForeground = `<svg class='layers-panel__row-svg' xmlns="http://www.w3.org/2000/svg" width="24" 
		height="24" viewBox="0 0 24 24" fill="none">
		<path d="M2 11.9996L11.6422 16.8207C11.7734 16.8863 11.839 16.9191 11.9078 16.932C11.9687 16.9434 12.0313 
		16.9434 12.0922 16.932C12.161 16.9191 12.2266 16.8863 12.3578 16.8207L22 11.9996M2 16.9996L11.6422 
		21.8207C11.7734 21.8863 11.839 21.9191 11.9078 21.932C11.9687 21.9434 12.0313 21.9434 12.0922 21.932C12.161 
		21.9191 12.2266 21.8863 12.3578 21.8207L22 16.9996M2 6.99958L11.6422 2.17846C11.7734 2.11287 11.839 2.08008
		11.9078 2.06717C11.9687 2.05574 12.0313 2.05574 12.0922 2.06717C12.161 2.08008 12.2266 2.11287 12.3578 
		2.17846L22 6.99958L12.3578 11.8207C12.2266 11.8863 12.161 11.9191 12.0922 11.932C12.0313 11.9434 11.9687 
		11.9434 11.9078 11.932C11.839 11.9191 11.7734 11.8863 11.6422 11.8207L2 6.99958Z" stroke="black" 
		stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M12.3578 2.17846C12.2266 2.11287 12.161 2.08008 12.0922 2.06717C12.0313 2.05574 11.9687 2.05574 
		11.9078 2.06717C11.839 2.08008 11.7734 2.11287 11.6422 2.17846L2 6.99958L11.6422 11.8207C11.7734 11.8863 
		11.839 11.9191 11.9078 11.932C11.9687 11.9434 12.0313 11.9434 12.0922 11.932C12.161 11.9191 12.2266 11.8863 
		12.3578 11.8207L22 6.99958L12.3578 2.17846Z" fill="#0075FF" stroke="#0075FF" stroke-width="2" 
		stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	moveBackground = `<svg class='layers-panel__row-svg' xmlns="http://www.w3.org/2000/svg" width="24" 
		height="24" viewBox="0 0 24 24" fill="none">
		<path d="M2 11.9996L11.6422 16.8207C11.7734 16.8863 11.839 16.9191 11.9078 16.932C11.9687 16.9434 
		12.0313 16.9434 12.0922 16.932C12.161 16.9191 12.2266 16.8863 12.3578 16.8207L22 11.9996M2 
		16.9996L11.6422 21.8207C11.7734 21.8863 11.839 21.9191 11.9078 21.932C11.9687 21.9434 12.0313 21.9434 
		12.0922 21.932C12.161 21.9191 12.2266 21.8863 12.3578 21.8207L22 16.9996M2 6.99958L11.6422 
		2.17846C11.7734 2.11287 11.839 2.08008 11.9078 2.06717C11.9687 2.05574 12.0313 2.05574 12.0922 
		2.06717C12.161 2.08008 12.2266 2.11287 12.3578 2.17846L22 6.99958L12.3578 11.8207C12.2266 11.8863 
		12.161 11.9191 12.0922 11.932C12.0313 11.9434 11.9687 11.9434 11.9078 11.932C11.839 11.9191 11.7734 
		11.8863 11.6422 11.8207L2 6.99958Z" stroke="#0075FF" stroke-width="2" 
		stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M11.6422 2.17846L2 6.99958L11.6422 11.8207C11.7734 11.8863 11.839 11.9191 11.9078 11.932C11.9687 
		11.9434 12.0313 11.9434 12.0922 11.932C12.161 11.9191 12.2266 11.8863 12.3578 11.8207L22 
		6.99958L12.3578 2.17846C12.2266 2.11287 12.161 2.08008 12.0922 2.06717C12.0313 2.05574 11.9687 2.05574 
		11.9078 2.06717C11.839 2.08008 11.7734 2.11287 11.6422 2.17846Z" fill="#272727"/>
		<path d="M2 11.9996L11.6422 16.8207C11.7734 16.8863 11.839 16.9191 11.9078 16.932C11.9687 16.9434 12.0313 
		16.9434 12.0922 16.932C12.161 16.9191 12.2266 16.8863 12.3578 16.8207L22 11.9996M2 6.99958L11.6422 
		2.17846C11.7734 2.11287 11.839 2.08008 11.9078 2.06717C11.9687 2.05574 12.0313 2.05574 12.0922 
		2.06717C12.161 2.08008 12.2266 2.11287 12.3578 2.17846L22 6.99958L12.3578 11.8207C12.2266 11.8863 12.161 
		11.9191 12.0922 11.932C12.0313 11.9434 11.9687 11.9434 11.9078 11.932C11.839 11.9191 11.7734 11.8863 
		11.6422 11.8207L2 6.99958Z" stroke="#272727" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	x = `<svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" width="9" 
		height="11" viewBox="0 0 9 11" fill="none">
		<path d="M1.4517 0L4.0767 4.2358H4.15625L6.78125 0H8.23295L5.03125 5.09091L8.23295 10.1818H6.78125L4.15625 
		6.02557H4.0767L1.4517 10.1818H0L3.28125 5.09091L0 0H1.4517Z" />
		</svg>`,
	y = `<svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" width="9" 
		height="11" viewBox="0 0 9 11" fill="none">
		<path d="M0 0H1.41193L4.2358 4.75284H4.35511L7.17898 0H8.59091L4.91193 5.9858V10.1818H3.67898V5.9858L0 0Z"/>
		</svg>`,
	rotate = `<svg class="svg-stroke" xmlns="http://www.w3.org/2000/svg" width="14" 
		height="15" viewBox="0 0 14 15" fill="none">
		<path d="M5.66667 1.33325C5.66667 1.33325 3.84548 2.66991 2.75883 3.75581C1.67218 4.84171 1 6.34232 1 7.99992C1 
		11.3136 3.68629 13.9999 7 13.9999C10.3137 13.9999 13 11.3136 13 7.99992C13 5.26452 11.1695 2.95666 8.66667 
		2.23443M5.66667 1.33325L1.66667 1.33325M5.66667 1.33325L5.66667 5.33325"  stroke-linecap="round"
		 stroke-linejoin="round"/>
		</svg>`,
	width = `<svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" width="13" 
		height="11" viewBox="0 0 13 11" fill="none">
		<path d="M2.78409 10.1818L0 0H1.25284L3.38068 8.29261H3.48011L5.64773 0H7.03977L9.20739 8.29261H9.30682L11.4347
		 0H12.6875L9.90341 10.1818H8.63068L6.38352 2.06818H6.30398L4.05682 10.1818H2.78409Z" />
		</svg>`,
	height = `<svg class="svg-fill" xmlns="http://www.w3.org/2000/svg"
		width="8" height="11"
		viewBox="0 0 8 11" fill="none">
		<path d="M0 10.1818V0H1.23295V4.53409H6.66193V0H7.89489V10.1818H6.66193V5.62784H1.23295V10.1818H0Z" />
		</svg>`,
	alignLeft = `<svg class="svg-stroke" 
		xmlns="http://www.w3.org/2000/svg" width="32" 
		height="32" viewBox="0 0 32 32" fill="none">
		<path d="M6 7V25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M26 10H10V14H26V10Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M20 18H10V22H20V18Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	alignCenter = `<svg class="svg-stroke" 
		xmlns="http://www.w3.org/2000/svg" width="32"
		 height="32" viewBox="0 0 32 32" fill="none">
		<path d="M16 7V25"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M20 10H24V14H20"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M12 14H8V10H12"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M20 18H22V22H20"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M12 18H10V22H12"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	alignRight = `<svg class="svg-stroke" xmlns="http://www.w3.org/2000/svg" 
		width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path d="M26 7V25"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M22 10H6V14H22V10Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M22 18H12V22H22V18Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	alignCenterVertical = `<svg class="svg-stroke" xmlns="http://www.w3.org/2000/svg" width="32" height="32" 
		viewBox="0 0 32 32"
		fill="none">
		<path d="M25 16H7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M10 20V24H14V20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M18 20V22H22V20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M18 12V10H22V12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M14 12V8H10V12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	alignBottom = `<svg class="svg-stroke" xmlns="http://www.w3.org/2000/svg" width="32" height="32" 
		viewBox="0 0 32 32" 
		fill="none">
		<path d="M25 26H7"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M10 22V6H14V22H10Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M18 22V12H22V22H18Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	alignTop = `<svg class="svg-stroke" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
		fill="none">
		<path d="M25 6H7"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M10 26V10H14V26H10Z" stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		<path d="M18 20V10H22V20H18Z" stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		</svg>`,
	boom = `<svg class="svg-stroke" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
		fill="none">
		
		<path d="M19 13L25 7M25 7H19M25 7V13M13 13L7 7M7 7L7 13M7 7L13 7M13 19L7 25M7 25H13M7 25L7 19M19 19L25 
		25M25 25V19M25 25H19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	CORNER_RADIUS = `<svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M5.6 2.88771e-07H4.60696C3.96301 -8.77156e-06 3.43149 -1.62489e-05 2.99854 0.0353568C2.54886 
         0.0720968  2.1355 0.150948 1.74723 0.348781C1.14511 0.655576 0.655576 1.14511 0.348781 1.74723C0.150948 
         2.1355 0.0720968 2.54886 0.0353568 2.99854C-1.62489e-05 3.43149 -8.77156e-06 3.96296 2.88771e-07 
         4.60691V5.6H1.6V4.64C1.6 3.95474 1.60062 3.4889 1.63004 3.12883C1.6587 2.7781 1.71064 2.59874 
         1.77439 2.47362C1.92779 2.17256 2.17256 1.92779 2.47362 1.77439C2.59874 1.71064 2.7781 1.6587 3.12883 
         1.63004C3.4889 1.60062 3.95474 1.6 4.64 1.6H5.6V2.88771e-07Z" fill="#C2C2C2" stroke-width="2"/>
         <path d="M14.4 5.6V4.64C14.4 3.95474 14.3994 3.4889 14.37 3.12883C14.3413 2.7781 14.2894 2.59874 14.2256 
         2.47362C14.0722 2.17256 13.8274 1.92779 13.5264 1.77439C13.4013 1.71064 13.2219 1.6587 12.8712 
         1.63004C12.5111 1.60062 12.0453 1.6 11.36 1.6H10.4V2.88771e-07H11.393C12.037 -8.77156e-06 12.5685 
         -1.62489e-05 13.0015 0.0353568C13.4511 0.0720969 13.8645 0.150948 14.2528 0.348781C14.8549 0.655576 
         15.3444 1.14511 15.6512 1.74723C15.8491 2.1355 15.9279 2.54886 15.9646 2.99854C16 3.4315 16 3.96298 16 
         4.60696V5.6H14.4Z" fill="#C2C2C2" stroke-width="2"/>
         <path d="M10.4 14.4H11.36C12.0453 14.4 12.5111 14.3994 12.8712 14.37C13.2219 14.3413 13.4013 14.2894 
         13.5264 14.2256C13.8274 14.0722 14.0722 13.8274 14.2256 13.5264C14.2894 13.4013 14.3413 13.2219 14.37 
         12.8712C14.3994 12.5111 14.4 12.0453 14.4 11.36V10.4H16V11.393C16 12.037 16 12.5685 15.9646 
         13.0015C15.9279 13.4511 15.8491 13.8645 15.6512 14.2528C15.3444 14.8549 14.8549 15.3444 14.2528 
         15.6512C13.8645 15.8491 13.4511 15.9279 13.0015 15.9646C12.5685 16 12.037 16 11.393 16H10.4V14.4Z" 
         fill="#C2C2C2" stroke-width="2"/>
         <path d="M1.6 10.4V11.36C1.6 12.0453 1.60062 12.5111 1.63004 12.8712C1.6587 13.2219 1.71064 
         13.4013 1.77439 13.5264C1.92779 13.8274 2.17256 14.0722 2.47362 14.2256C2.59874 14.2894 2.7781 
         14.3413 3.12883 14.37C3.4889 14.3994 3.95474 14.4 4.64 14.4H5.6V16H4.60696C3.96298 16 3.4315 
         16 2.99854 15.9646C2.54886 15.9279 2.1355 15.8491 1.74723 15.6512C1.14511 15.3444 0.655576 
         14.8549 0.348781 14.2528C0.150948 13.8645 0.0720968 13.4511 0.0353568 13.0015C-1.62489e-05 
         12.5685 -8.77156e-06 12.037 2.88771e-07 11.3931V10.4H1.6Z" fill="#C2C2C2" stroke-width="2"/>
         </svg>`,
	reflectVertical = `<svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" width="32" height="32" 
		viewBox="0 0 32 32" 
		fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M7.47749 5.14737C7.77415 4.96557 8.14397 4.95104 8.45398 
		5.10899L24.454 13.261C24.8659 13.4709 25.0803 13.9359 24.9724 14.3855C24.8644 14.8351 24.4624 15.152 24 
		15.152H8C7.44772 15.152 7 14.7043 7 14.152V6C7 5.65208 7.18084 5.32916 7.47749 5.14737ZM12.0497 
		9.18564C10.6542 8.47464 9 9.48835 9 11.0545C9 12.213 9.93908 13.152 11.0975 13.152C13.3022 13.152 14.0141 
		10.1865 12.0497 9.18564Z" />
		<path d="M11.0975 17.848C9.38679 17.848 8 19.2348 8 20.9455C8 23.2583 10.4429 24.7554 12.5037 
		23.7054C15.4047 22.2273 14.3533 17.848 11.0975 17.848Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M7.47749 26.8526C7.77415 27.0344 8.14397 27.049 8.45397 
		26.891L24.454 18.739C24.8659 18.5291 25.0803 18.0641 24.9724 17.6145C24.8644 17.1649 24.4624 16.848 24 
		16.848L8 16.848C7.44772 16.848 7 17.2957 7 17.848L7 26C7 26.3479 7.18084 26.6708 7.47749 26.8526ZM12.0497 
		22.8144C10.6542 23.5254 9 22.5116 9 20.9455C9 19.787 9.93908 18.848 11.0975 18.848C13.3022 18.848 14.0141 
		21.8135 12.0497 22.8144Z" />
		</svg>`,
	reflectHorizont = `<svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" width="32" height="32" 
		viewBox="0 0 32 32" 
		fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M5.14737 24.5226C4.96557 24.226 4.95104 23.8561 5.10899 
		23.5461L13.261 7.54612C13.4709 7.13415 13.9359 6.91979 14.3855 7.02773C14.8351 7.13566 15.152 7.53773 
		15.152 8.0001L15.152 24.0001C15.152 24.5524 14.7043 25.0001 14.152 25.0001H6C5.65208 25.0001 5.32916 
		24.8193 5.14737 24.5226ZM9.18564 19.9504C8.47464 21.3459 9.48835 23.0001 11.0545 23.0001C12.213 23.0001 
		13.152 22.061 13.152 20.9026C13.152 18.6979 10.1865 17.986 9.18564 19.9504Z" />
		<path d="M17.848 20.9026C17.848 22.6133 19.2348 24.0001 20.9455 24.0001C23.2583 24.0001 24.7554 21.5572 
		23.7054 19.4964C22.2273 16.5954 17.848 17.6468 17.848 20.9026Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M26.8526 24.5226C27.0344 24.226 27.049 23.8561 26.891 
		23.5461L18.739 7.54612C18.5291 7.13415 18.0641 6.91979 17.6145 7.02773C17.1649 7.13566 16.848 7.53774 
		16.848 8.0001L16.848 24.0001C16.848 24.5524 17.2957 25.0001 17.848 25.0001L26 25.0001C26.3479 25.0001 
		26.6708 24.8193 26.8526 24.5226ZM22.8144 19.9504C23.5254 21.3459 22.5116 23.0001 20.9455 23.0001C19.787 
		23.0001 18.848 22.061 18.848 20.9026C18.848 18.6979 21.8135 17.986 22.8144 19.9504Z" />
		</svg>`,
	rotateLeft = `<svg class="svg-stroke" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" 
		fill="none">
		<path d="M6 14C6 14 8.00498 11.2682 9.63384 9.63824C11.2627 8.00827 13.5136 7 16 7C20.9706 7 25 11.0294 25 
		16C25 20.9706 20.9706 25 16 25C11.8969 25 8.43511 22.2543 7.35177 18.5M6 14V8M6 14H12"  
		stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	rotateRight = `<svg class="svg-stroke" xmlns="http://www.w3.org/2000/svg" width="32" height="32" 
		viewBox="0 0 32 32" 
		fill="none">
		<path d="M25 14C25 14 22.995 11.2682 21.3662 9.63824C19.7373 8.00827 17.4864 7 15 7C10.0294 7 6 11.0294 6 
		16C6 20.9706 10.0294 25 15 25C19.1031 25 22.5649 22.2543 23.6482 18.5M25 14V8M25 14H19" stroke-width="2" 
		stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	IMG_LOAD = `<svg width="14" height="14" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.25 6L15.5 3.75M15.5 3.75L13.25 1.5M15.5 3.75L11 3.75M8.375 2.25H4.85C3.58988 2.25 
		2.95982 2.25 2.47852 2.49524C2.05516 2.71095 1.71095 3.05516 1.49524 3.47852C1.25 3.95982 1.25
		 4.58988 1.25 5.85V12.15C1.25 13.4101 1.25 14.0402 1.49524 14.5215C1.71095 14.9448 2.05516 15.289 
		 2.47852 15.5048C2.95982 15.75 3.58988 15.75 4.85 15.75H11.75C12.4475 15.75 12.7962 15.75 13.0823 
		 15.6733C13.8588 15.4653 14.4653 14.8588 14.6733 14.0823C14.75 13.7962 14.75 13.4475 14.75 
		 12.75M6.875 6.375C6.875 7.20343 6.20343 7.875 5.375 7.875C4.54657 7.875 3.875 7.20343 3.875 
		 6.375C3.875 5.54657 4.54657 4.875 5.375 4.875C6.20343 4.875 6.875 5.54657 6.875 6.375ZM10.2425 
		 8.93861L3.89836 14.706C3.54152 15.0304 3.3631 15.1926 3.34732 15.3331C3.33364 15.4549 3.38034 
		 15.5757 3.47239 15.6566C3.57858 15.75 3.81971 15.75 4.30196 15.75H11.342C12.4214 15.75 12.961 
		 15.75 13.3849 15.5687C13.9171 15.341 14.341 14.9171 14.5687 14.3849C14.75 13.961 14.75 13.4214 
		 14.75 12.342C14.75 11.9788 14.75 11.7972 14.7103 11.6281C14.6604 11.4156 14.5647 11.2165 14.4299 
		 11.0448C14.3227 10.9082 14.1809 10.7947 13.8973 10.5678L11.7994 8.8895C11.5155 8.66243 11.3736 
		 8.5489 11.2173 8.50883C11.0796 8.47352 10.9346 8.47809 10.7994 8.52202C10.646 8.57186 10.5115 
		 8.69411 10.2425 8.93861Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	cropImg = `<svg class="svg-stroke" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" 
		fill="none">
		<path d="M6 10H18.8C19.9201 10 20.4802 10 20.908 10.218C21.2843 10.4097 21.5903 10.7157 21.782 11.092C22
		 11.5198 22 12.0799 22 13.2V26M26 22L13.2 22C12.0799 22 11.5198 22 11.092 21.782C10.7157 21.5903 10.4097
		  21.2843 10.218 20.908C10 20.4802 10 19.9201 10 18.8V6" stroke-width="2" 
		  stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	layout = `<svg class="svg-stroke" <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" 
		viewBox="0 0 32 32" fill="none">
		<path d="M6 16.0001L15.6422 20.8212C15.7734 20.8868 15.839 20.9196 15.9078 20.9325C15.9687 20.9439 16.0313 
		20.9439 16.0922 20.9325C16.161 20.9196 16.2266 20.8868 16.3578 20.8212L26 16.0001M6 21.0001L15.6422 
		25.8212C15.7734 25.8868 15.839 25.9196 15.9078 25.9325C15.9687 25.9439 16.0313 25.9439 16.0922 
		25.9325C16.161 25.9196 16.2266 25.8868 16.3578 25.8212L26 21.0001M6 11.0001L15.6422 6.17895C15.7734 6.11336 
		15.839 6.08056 15.9078 6.06766C15.9687 6.05622 16.0313 6.05622 16.0922 6.06766C16.161 6.08056 16.2266 
		6.11336 16.3578 6.17895L26 11.0001L16.3578 15.8212C16.2266 15.8868 16.161 15.9196 16.0922 15.9325C16.0313 
		15.9439 15.9687 15.9439 15.9078 15.9325C15.839 15.9196 15.7734 15.8868 15.6422 15.8212L6 11.0001Z" 
		stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	forwardTool = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
		<path d="M21.0417 13.5L27 7.25M27 7.25L21.0417 1M27 7.25L9.9375 7.24999C5.00145 7.24999 0.999999 11.4473 
		0.999999 16.625C0.999999 21.8027 5.00145 26 9.9375 26L14.5417 26"  stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		</svg>`,
	solid = `<svg xmlns="http://www.w3.org/2000/svg" width="42" height="2" viewBox="0 0 42 2" fill="none">
		<path d="M14 0.0880681V1.18182H0V0.0880681H14ZM28 0.0880681V1.18182H14V0.0880681H28ZM42 
		0.0880681V1.18182H28V0.0880681H42Z" fill="#272727"/>
		</svg>`,
	dashed = `<svg xmlns="http://www.w3.org/2000/svg" width="44" height="2" viewBox="0 0 44 2" fill="none">
		<path d="M4.91078 0.0880681V1.18182H0.456232V0.0880681H4.91078ZM11.3502
		0.0880681V1.18182H6.89569V0.0880681H11.3502ZM17.7897
		0.0880681V1.18182H13.3351V0.0880681H17.7897ZM24.2291
		0.0880681V1.18182H19.7746V0.0880681H24.2291ZM30.6686 0.0880681V1.18182H26.214V0.0880681H30.6686ZM37.108
		0.0880681V1.18182H32.6535V0.0880681H37.108ZM43.5475 0.0880681V1.18182H39.093V0.0880681H43.5475Z" 
		fill="#272727"/>
		</svg>`,
	dotted = `<svg xmlns="http://www.w3.org/2000/svg" width="44" 
		height="6" viewBox="0 0 44 6" fill="none">
		<path d="M2.3125 5.13068C1.91146 5.13068 1.54522 5.03291 1.21378 4.83736C0.882339 4.63849 0.617188 4.37334 
		0.418324 4.0419C0.222775 3.71046 0.125 3.34422 0.125 2.94318C0.125 2.53883 0.222775 2.17259 0.418324 
		1.84446C0.617188 1.51302 0.882339 1.24953 1.21378 1.05398C1.54522 0.855113 1.91146 0.755682 2.3125 
		0.755682C2.71686 0.755682 3.0831 0.855113 3.41122 1.05398C3.74266 1.24953 4.00616 1.51302 4.2017 
		1.84446C4.40057 2.17259 4.5 2.53883 4.5 2.94318C4.5 3.34422 4.40057 3.71046 4.2017 4.0419C4.00616 
		4.37334 3.74266 4.63849 3.41122 4.83736C3.0831 5.03291 2.71686 5.13068 2.3125 5.13068ZM10.1875 
		5.13068C9.78646 5.13068 9.42022 5.03291 9.08878 4.83736C8.75734 4.63849 8.49219 4.37334 8.29332 
		4.0419C8.09777 3.71046 8 3.34422 8 2.94318C8 2.53883 8.09777 2.17259 8.29332 1.84446C8.49219 1.51302 8.75734 
		1.24953 9.08878 1.05398C9.42022 0.855113 9.78646 0.755682 10.1875 0.755682C10.5919 0.755682 10.9581 0.855113 
		11.2862 1.05398C11.6177 1.24953 11.8812 1.51302 12.0767 1.84446C12.2756 2.17259 12.375 2.53883 12.375 
		2.94318C12.375 3.34422 12.2756 3.71046 12.0767 4.0419C11.8812 4.37334 11.6177 4.63849 11.2862 4.83736C10.9581 
		5.03291 10.5919 5.13068 10.1875 5.13068ZM18.0625 5.13068C17.6615 5.13068 17.2952 5.03291 16.9638 4.83736C16.6323
		4.63849 16.3672 4.37334 16.1683 4.0419C15.9728 3.71046 15.875 3.34422 15.875 2.94318C15.875 2.53883 15.9728 
		2.17259 16.1683 1.84446C16.3672 1.51302 16.6323 1.24953 16.9638 1.05398C17.2952 0.855113 17.6615 0.755682 
		18.0625 0.755682C18.4669 0.755682 18.8331 0.855113 19.1612 1.05398C19.4927 1.24953 19.7562 1.51302 19.9517 
		1.84446C20.1506 2.17259 20.25 2.53883 20.25 2.94318C20.25 3.34422 20.1506 3.71046 19.9517 4.0419C19.7562 
		4.37334 19.4927 4.63849 19.1612 4.83736C18.8331 5.03291 18.4669 5.13068 18.0625 5.13068ZM25.9375 5.13068C25.5365
		5.13068 25.1702 5.03291 24.8388 4.83736C24.5073 4.63849 24.2422 4.37334 24.0433 4.0419C23.8478 3.71046 23.75 
		3.34422 23.75 2.94318C23.75 2.53883 23.8478 2.17259 24.0433 1.84446C24.2422 1.51302 24.5073 1.24953 24.8388 
		1.05398C25.1702 0.855113 25.5365 0.755682 25.9375 0.755682C26.3419 0.755682 26.7081 0.855113 27.0362 
		1.05398C27.3677 1.24953 27.6312 1.51302 27.8267 1.84446C28.0256 2.17259 28.125 2.53883 28.125 2.94318C28.125 
		3.34422 28.0256 3.71046 27.8267 4.0419C27.6312 4.37334 27.3677 4.63849 27.0362 4.83736C26.7081 5.03291 26.3419 
		5.13068 25.9375 5.13068ZM33.8125 5.13068C33.4115 5.13068 33.0452 5.03291 32.7138 4.83736C32.3823 4.63849 32.1172
		4.37334 31.9183 4.0419C31.7228 3.71046 31.625 3.34422 31.625 2.94318C31.625 2.53883 31.7228 2.17259 31.9183 
		1.84446C32.1172 1.51302 32.3823 1.24953 32.7138 1.05398C33.0452 0.855113 33.4115 0.755682 33.8125 
		0.755682C34.2169 0.755682 34.5831 0.855113 34.9112 1.05398C35.2427 1.24953 35.5062 1.51302 35.7017 
		1.84446C35.9006 2.17259 36 2.53883 36 2.94318C36 3.34422 35.9006 3.71046 35.7017 4.0419C35.5062 4.37334 35.2427
		4.63849 34.9112 4.83736C34.5831 5.03291 34.2169 5.13068 33.8125 5.13068ZM41.6875 5.13068C41.2865 5.13068 
		40.9202 5.03291 40.5888 4.83736C40.2573 4.63849 39.9922 4.37334 39.7933 4.0419C39.5978 3.71046 39.5 3.34422 
		39.5 2.94318C39.5 2.53883 39.5978 2.17259 39.7933 1.84446C39.9922 1.51302 40.2573 1.24953 40.5888 
		1.05398C40.9202 0.855113 41.2865 0.755682 41.6875 0.755682C42.0919 0.755682 42.4581 0.855113 42.7862 
		1.05398C43.1177 1.24953 43.3812 1.51302 43.5767 1.84446C43.7756 2.17259 43.875 2.53883 43.875 2.94318C43.875 
		3.34422 43.7756 3.71046 43.5767 4.0419C43.3812 4.37334 43.1177 4.63849 42.7862 4.83736C42.4581 5.03291 42.0919 
		5.13068 41.6875 5.13068Z" fill="#272727"/>
		</svg>`,
	ARROW = `<svg xmlns="http://www.w3.org/2000/svg" width="10" 
		height="6" viewBox="0 0 10 6" fill="none">
		<path d="M1 1L5 5L9 1" stroke="#7F8184" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,

	CUSTOM_VALUES = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.28571C0 1.02335 1.07452 0 2.4 0H16C17.3255 0 18.4 
		1.02335 18.4 2.28571V6.09821H16.8V2.28571C16.8 1.86493 16.4418 1.52381 16 1.52381H2.4C1.95817 1.52381 
		1.6 1.86493 1.6 2.28571V13.7143C1.6 14.1341 1.95869 14.4762 2.4015 14.4762H16.0119C16.4473 14.4762 
		16.8 14.1398 16.8 13.7271H18.4C18.4 14.9834 17.3289 16 16.0119 16H2.4015C1.07699 16 0 14.9776 0 
		13.7143V2.28571Z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M16.8 6.09524L1.6 6.09524L1.6 4.57143L16.8 4.57143V6.09524Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M7.2 5.33333V14.4762H5.6L5.6 5.33333H7.2Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M12.8 5.33333V14.4762H11.2V5.33333H12.8Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M18.4 7.61905V12.1905H16.8V7.61905H18.4Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M20 10.6667H15.2V9.14286H20V10.6667Z" />
		</svg>`,

	EXCEL_LOADER = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
			<path d="M9.25638 1.53352C9.18062 1.52801 9.07886 1.52758 8.89307 1.52758H4.92932C4.09913 1.52758 3.5454 
			1.52897 3.13335 1.58198C2.73711 1.63295 2.58543 1.72002 2.49531 1.80625C2.41325 1.88477 2.33324 2.00707 
			2.28374 2.3593C2.23027 2.73989 2.22844 3.25632 2.22844 4.05538L2.22815 13.9449C2.22815 14.7439 2.22998 
			15.2604 2.28346 15.641C2.33295 15.9932 2.41297 16.1155 2.49502 16.194C2.58514 16.2803 2.73682 16.3673 
			3.13306 16.4183C3.54511 16.4713 4.09884 16.4727 4.92903 16.4727H10.4652C11.2954 16.4727 11.8491 16.4713 
			12.2612 16.4183C12.6574 16.3673 12.8091 16.2803 12.8992 16.194C12.9813 16.1155 13.0613 15.9932 13.1108 
			15.641C13.1404 15.4305 13.1541 15.1784 13.1605 14.86H14.6391C14.6319 15.2326 14.6147 15.5677 14.5736 
			15.8604C14.4971 16.4045 14.3253 16.9111 13.9036 17.3146C13.4899 17.7104 12.9843 17.8644 12.4438 
			17.9339C11.9296 18 11.2829 18 10.5123 18H4.88192C4.11129 18 3.46468 18 2.95044 17.9339C2.40993 
			17.8644 1.90432 17.7104 1.49068 17.3146C1.06896 16.9111 0.897091 16.4045 0.820643 15.8604C0.749923 
			15.3571 0.749959 14.7285 0.750002 13.999L0.750291 4.00126C0.750248 3.27178 0.750211 2.64317 0.820932 
			2.13986C0.897382 1.59579 1.06925 1.08917 1.49097 0.685667C1.90461 0.289881 2.41022 0.135936 2.95073 
			0.0664042C3.46498 0.000249985 4.11158 0.000272657 4.88222 0.000299675L8.89307 0.00030084L8.96356 
			0.000152785C9.24117 -0.000717052 9.55221 -0.00169162 9.84308 0.113591C10.1393 0.230973 10.3658 0.449139 
			10.562 0.638178L10.6114 0.685667L13.9038 3.83593C13.9207 3.85203 13.938 3.86844 13.9556 3.88519C14.1488 
			4.06847 14.3848 4.29236 14.5146 4.59239C14.6474 4.89896 14.6459 5.22966 14.6447 5.49088C14.6446 5.51442 
			14.6445 5.53758 14.6445 5.55993L14.6445 6.47554H13.1663L13.1664 5.55993C13.1664 5.3797 13.1658 5.28975 
			13.1607 5.22512L13.1599 5.21599C13.1565 5.21197 13.1524 5.20729 13.1476 5.20192C13.1008 5.14979 13.0318 
			5.08314 12.8995 4.95652L9.60711 1.80625C9.47503 1.67988 9.40504 1.61358 9.34989 1.56827C9.33091 1.55268 
			9.31905 1.54415 9.31271 1.53991C9.30432 1.53833 9.28703 1.53575 9.25638 1.53352Z" fill="#1E7049"/>
			<path d="M16.4144 9.36833C16.4281 9.4303 16.4781 9.47953 16.5396 9.47953H17.6724C17.7379 9.47953 17.7913 
			9.42422 17.787 9.35665C17.7234 8.3635 16.9438 7.71454 15.725 7.71454C14.4796 7.71454 13.5999 8.40679 
			13.6076 9.43726C13.605 10.2828 14.1727 10.7531 15.101 10.9592L15.6227 11.0754C16.2057 11.2075 16.4052 
			11.3581 16.4103 11.6039C16.4052 11.8707 16.1699 12.0583 15.7147 12.0583C15.2141 12.0583 14.9014 11.8351 
			14.8362 11.4133C14.8263 11.3489 14.7752 11.2974 14.7121 11.2974H13.5933C13.5278 11.2974 13.4745 11.3524 
			13.4776 11.42C13.5347 12.6921 14.4115 13.2632 15.7352 13.2632C17.0624 13.2632 17.8577 12.6713 17.8628 
			11.6039C17.8577 10.7795 17.382 10.2009 16.2262 9.94456L15.7966 9.84944C15.3005 9.74111 15.078 9.59051 
			15.0908 9.33157C15.0933 9.09377 15.2851 8.91938 15.725 8.91938C16.1344 8.91938 16.3516 9.08453 16.4144 
			9.36833Z" fill="#1E7049"/>
			<path d="M5.45642 7.78852C5.4991 7.78852 5.53847 7.81228 5.55943 7.8507L6.37465 9.34481C6.38088 9.35622 
			6.39257 9.36328 6.40524 9.36328C6.41786 9.36328 6.4295 9.35629 6.43575 9.34497L7.26107 7.85016C7.28211 
			7.81205 7.32131 7.78852 7.36378 7.78852H8.67214C8.76396 7.78852 8.82075 7.89194 8.77364 7.97338L7.35252 
			10.4302C7.32982 10.4695 7.33022 10.5185 7.35357 10.5574L8.82975 13.0131C8.87868 13.0945 8.82203 13.1998 
			8.7293 13.1998H7.38412C7.34172 13.1998 7.30256 13.1763 7.28149 13.1383L6.4358 11.6116C6.42952 11.6003 
			6.41784 11.5933 6.40518 11.5933C6.39253 11.5933 6.38085 11.6003 6.37456 11.6116L5.52888 13.1383C5.50781 
			13.1763 5.46865 13.1998 5.42625 13.1998H4.08989C3.99746 13.1998 3.94077 13.0951 3.98909 13.0137L5.44728 
			10.557C5.47022 10.5183 5.47056 10.4697 5.44815 10.4307L4.03585 7.97308C3.98905 7.89163 4.04586 7.78852 
			4.13753 7.78852H5.45642Z" fill="#1E7049"/>
			<path d="M9.61192 13.1998C9.54661 13.1998 9.49367 13.1451 9.49367 13.0776V7.9107C9.49367 7.84322 9.54661 
			7.78852 9.61192 7.78852H10.7972C10.8625 7.78852 10.9155 7.84322 10.9155 7.9107V11.8939C10.9155 11.9614 
			10.9684 12.0161 11.0337 12.0161H12.9146C12.9799 12.0161 13.0329 12.0708 13.0329 12.1382V13.0776C13.0329 
			13.1451 12.9799 13.1998 12.9146 13.1998H9.61192Z" fill="#1E7049"/>
			<path d="M19.8587 7.78852C19.9014 7.78852 19.9407 7.81228 19.9617 7.8507L20.7769 9.34481C20.7831 9.35622 
			20.7948 9.36328 20.8075 9.36328C20.8201 9.36328 20.8318 9.35629 20.838 9.34497L21.6633 7.85016C21.6844 
			7.81205 21.7236 7.78852 21.766 7.78852H23.0744C23.1662 7.78852 23.223 7.89194 23.1759 7.97338L21.7548 
			10.4302C21.7321 10.4695 21.7325 10.5185 21.7558 10.5574L23.232 13.0131C23.2809 13.0945 23.2243 13.1998 
			23.1316 13.1998H21.7864C21.744 13.1998 21.7048 13.1763 21.6838 13.1383L20.8381 11.6116C20.8318 11.6003 
			20.8201 11.5933 20.8074 11.5933C20.7948 11.5933 20.7831 11.6003 20.7768 11.6116L19.9311 13.1383C19.9101 
			13.1763 19.8709 13.1998 19.8285 13.1998H18.4922C18.3997 13.1998 18.343 13.0951 18.3913 13.0137L19.8495 
			10.557C19.8725 10.5183 19.8728 10.4697 19.8504 10.4307L18.4381 7.97308C18.3913 7.89163 18.4481 7.78852 
			18.5398 7.78852H19.8587Z" fill="#1E7049"/>
		</svg>`,
	X = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
			<path d="M11 1L1 11M1 1L11 11" stroke="#EDEDED" stroke-width="2" stroke-linecap="round" 
			stroke-linejoin="round"/>
		</svg>`,
	CLOSE = `<svg xmlns="http://www.w3.org/2000/svg" width="14" 
			height="14" viewBox="0 0 14 14" fill="none">
			<path d="M13 1L1 13M1 1L13 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>`,
	TABLE_TOOL = `<svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" >
		<path fill-rule="evenodd" clip-rule="evenodd" d="M4 0H26C26.7 0 27.3 3.7998e-07 27.8 0.1C28.3 0.2 28.8 0.300001 
		29.3 0.700001C29.7 1.1 29.9 1.6 30 2.1C30.1 2.6 30.1 3.2 30.1 3.8V22.1C30.1 22.8 30.1 23.4 30 23.8C29.9 24.3 
		29.8 24.8 29.3 25.2C28.9 25.6 28.3 25.8 27.8 25.8C27.3 26 26.7 26 26 26H4C3.3 26 2.7 26 2.2 25.9C1.7 25.8 1.2 
		25.7 0.7 25.3C0.3 24.9 0.1 24.4 0 23.9C0 23.4 0 22.8 0 22.1V3.9C0 3.9 0 3.9 0 3.8C0 3.1 3.7998e-07 2.5 0.1 
		2.1C0.2 1.6 0.3 1.1 0.8 0.700001C1.2 0.300001 1.8 0.1 2.3 0.1C2.7 3.7998e-07 3.3 0 4 0ZM2.4 
		1.8C2.1 1.9 2 2 2 2C2 2 1.9 2.1 1.9 2.4C1.9 2.7 1.9 3.2 1.9 3.9V7.5H28.2V3.9C28.2 
		3.2 28.2 2.7 28.2 2.4C28.2 2.1 28.1 2 28.1 2C28.1 2 28 1.9 27.7 
		1.9C27.3 1.9 26.9 1.9 26.1 1.9H4C3.3 1.8 2.8 1.8 
		2.4 1.8ZM28.2 9.4H20.6V15.8945V17.7017V24.2H26C26.8 24.2 27.2 24.2 27.6 24.2C27.9 24.2 28 24.1 28 24.1C28 
		24.1 28.1 24 28.1 23.7C28.1 23.4 28.1 22.9 28.1 22.2V17.7017V15.8945V9.4H28.2ZM18.8 
		24.2V17.7017V15.8945V9.4H11.2V15.8945V17.7017V24.2H18.8ZM9.4 
		24.2V17.7017V16.8V15.8945V9.4H1.8V15.8945V16.8V17.7017V22.1C1.8 22.8 1.8 23.3 1.8 23.6C1.8 
		23.9 1.9 24 1.9 24C1.9 24 2 24.1 2.3 24.1C2.7 24.1 3.1 24.1 3.9 24.1H9.4V24.2Z" />
		<path d="M1.8 15.8945V16.8V17.7017H9.4V16.8V15.8945H1.8Z" />
		<path d="M18.8 15.8945H11.2V17.7017H18.8V15.8945Z" />
		<path d="M20.6 17.7017H28.1V15.8945H20.6V17.7017Z" />
		</svg>`,
	CROSS = `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
		<path d="M6.42552 7.20455L0.81756 1.59659L1.57324 0.840909L7.1812 6.44886L6.42552 7.20455ZM1.57324 
		7.20455L0.81756 6.44886L6.42552 0.840909L7.1812 1.59659L1.57324 7.20455Z" fill="#272727"/>
		</svg>`,

	TEXT_PANEL_OL = `<svg xmlns="http://www.w3.org/2000/svg" width="24" 
		height="24" viewBox="0 0 24 24" fill="none">
		<path d="M21 12L9 12M21 6L9 6M21 18L9 18" stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		<mask id="path-2-inside-1_3331_9232" fill="white">
		<path d="M4 3C4.55228 3 5 3.44772 5 4V7.17913C5 7.63249 4.63249 8 4.17913 8V8C3.72578 8 3.35827 7.63249 3.35827 
		7.17913V4.25H3.32283L2.74739 4.53462C2.40323 4.70485 2 4.45444 2 4.07048V4.07048C2 3.87513 2.10994 3.6964
		2.28429 3.60831L3.2755 3.10747C3.41534 3.03681 3.56981 3 3.72648 3H4Z"/>
		</mask>
		<path d="M4 3C4.55228 3 5 3.44772 5 4V7.17913C5 7.63249 4.63249 8 4.17913 8V8C3.72578 8 3.35827 7.63249 3.35827 
		7.17913V4.25H3.32283L2.74739 4.53462C2.40323 4.70485 2 4.45444 2 4.07048V4.07048C2 3.87513 2.10994 3.6964 
		2.28429 3.60831L3.2755 3.10747C3.41534 3.03681 3.56981 3 3.72648 3H4Z"  stroke-width="4" 
		mask="url(#path-2-inside-1_3331_9232)"/>
		<mask id="path-3-inside-2_3331_9232" fill="white">
		<path d="M2.7504 14.5C2.39078 14.5 2.09926 14.2085 2.09926 13.8489V13.8489C2.09926 13.6515 2.18881 13.4647 
		2.34273 13.3411L3.99504 12.0145C4.12076 11.9133 4.22829 11.8186 4.31762 11.7303C4.4086 11.6403 4.47808 11.548 
		4.52605 11.4533C4.57568 11.3585 4.6005 11.2534 4.6005 11.1378C4.6005 11.0109 4.57237 10.9025 4.51613 
		10.8126C4.46154 10.7227 4.38544 10.6537 4.28784 10.6055C4.19024 10.5557 4.07775 10.5308 3.95037 10.5308C3.82299 
		10.5308 3.7105 10.5557 3.6129 10.6055C3.51696 10.6553 3.44251 10.7283 3.38958 10.8247V10.8247C3.29268 11.001 
		3.14706 11.1763 2.94584 11.1763H2.65509C2.29329 11.1763 1.9806 10.8718 2.11168 10.5346C2.14653 10.445 2.18954 
		10.3602 2.24069 10.2803C2.40116 10.0299 2.62779 9.83719 2.9206 9.70231C3.2134 9.56744 3.55666 9.5 3.95037 
		9.5C4.35732 9.5 4.70968 9.56342 5.00744 9.69027C5.30687 9.81551 5.53763 9.99213 5.69975 10.2201C5.86352 10.4481 
		5.94541 10.7155 5.94541 11.0222C5.94541 11.2116 5.90488 11.4003 5.82382 11.5882C5.74276 11.7744 5.59719 11.9807 
		5.3871 12.2071C5.17701 12.4335 4.87841 12.7033 4.49131 13.0164L4.02215 13.3959C4.01756 13.3996 4.01489 13.4052 
		4.01489 13.4111V13.4111C4.01489 13.4219 4.02365 13.4306 4.03445 13.4306H5.46532C5.76061 13.4306 6 13.67 6 
		13.9653V13.9653C6 14.2606 5.76061 14.5 5.46532 14.5H2.7504Z"/>
		</mask>
		<path d="M2.7504 14.5C2.39078 14.5 2.09926 14.2085 2.09926 13.8489V13.8489C2.09926 13.6515 2.18881 13.4647 
		2.34273 13.3411L3.99504 12.0145C4.12076 11.9133 4.22829 11.8186 4.31762 11.7303C4.4086 11.6403 4.47808 11.548 
		4.52605 11.4533C4.57568 11.3585 4.6005 11.2534 4.6005 11.1378C4.6005 11.0109 4.57237 10.9025 4.51613 
		10.8126C4.46154 10.7227 4.38544 10.6537 4.28784 10.6055C4.19024 10.5557 4.07775 10.5308 3.95037 10.5308C3.82299 
		10.5308 3.7105 10.5557 3.6129 10.6055C3.51696 10.6553 3.44251 10.7283 3.38958 10.8247V10.8247C3.29268 11.001 
		3.14706 11.1763 2.94584 11.1763H2.65509C2.29329 11.1763 1.9806 10.8718 2.11168 10.5346C2.14653 10.445 2.18954 
		10.3602 2.24069 10.2803C2.40116 10.0299 2.62779 9.83719 2.9206 9.70231C3.2134 9.56744 3.55666 9.5 3.95037 
		9.5C4.35732 9.5 4.70968 9.56342 5.00744 9.69027C5.30687 9.81551 5.53763 9.99213 5.69975 10.2201C5.86352 10.4481 
		5.94541 10.7155 5.94541 11.0222C5.94541 11.2116 5.90488 11.4003 5.82382 11.5882C5.74276 11.7744 5.59719 11.9807 
		5.3871 12.2071C5.17701 12.4335 4.87841 12.7033 4.49131 13.0164L4.02215 13.3959C4.01756 13.3996 4.01489 13.4052 
		4.01489 13.4111V13.4111C4.01489 13.4219 4.02365 13.4306 4.03445 13.4306H5.46532C5.76061 13.4306 6 13.67 6 
		13.9653V13.9653C6 14.2606 5.76061 14.5 5.46532 14.5H2.7504Z" stroke-width="4" 
		mask="url(#path-3-inside-2_3331_9232)"/>
		<mask id="path-4-inside-3_3331_9232" fill="white">
		<path d="M3.97621 21C3.593 21 3.25255 20.9343 2.95485 20.8028C2.65874 20.6697 2.42596 20.4867 2.25653 
		20.2538C2.21975 20.2032 2.18692 20.1511 2.15805 20.0972C1.98282 19.7704 2.30163 19.4506 2.67244 
		19.4506H3.0596C3.20975 19.4506 3.326 19.582 3.41565 19.7025V19.7025C3.47266 19.7753 3.55025 19.8324 3.64842 
		19.8736C3.7466 19.9148 3.85903 19.9354 3.98571 19.9354C4.10764 19.9354 4.21532 19.914 4.30875 19.8712C4.40217 
		19.8268 4.47501 19.7658 4.52727 19.6882C4.57952 19.6106 4.60486 19.5219 4.60328 19.4221C4.60486 19.3238 4.57477 
		19.2367 4.51302 19.1606C4.45284 19.0846 4.36813 19.0252 4.25886 18.9824C4.1496 18.9396 4.02371 18.9183 3.8812 
		18.9183H3.84342C3.59143 18.9183 3.38715 18.714 3.38715 18.462V18.462C3.38715 18.21 3.59143 18.0057 3.84342 
		18.0057H3.8812C4.01263 18.0057 4.12823 17.9843 4.22799 17.9415C4.32933 17.8988 4.40771 17.8394 4.46314 
		17.7633C4.52014 17.6873 4.54785 17.6001 4.54627 17.5019C4.54785 17.4068 4.52489 17.3229 4.47739 17.25C4.42988 
		17.1771 4.36338 17.1201 4.27787 17.0789C4.19394 17.0377 4.09656 17.0171 3.98571 17.0171C3.86536 17.0171 3.75769 
		17.0385 3.66268 17.0813C3.56925 17.124 3.49562 17.1835 3.44178 17.2595V17.2595C3.35245 17.3857 3.23463 17.5209
		3.08003 17.5209H2.7336C2.38147 17.5209 2.07858 17.2188 2.2363 16.904C2.26605 16.8446 2.30051 16.7872 2.33966 
		16.7319C2.50276 16.5038 2.72604 16.3248 3.00948 16.1949C3.29293 16.065 3.61834 16 3.98571 16C4.34517 16 4.66266 
		16.061 4.93819 16.183C5.2153 16.305 5.43145 16.4729 5.58663 16.6868C5.7434 16.8991 5.82099 17.1423 5.81941 
		17.4163C5.82257 17.6888 5.72915 17.9122 5.53913 18.0865C5.35069 18.2608 5.11158 18.3638 4.8218 
		18.3954V18.4335C5.21451 18.4762 5.50983 18.5966 5.70777 18.7947C5.90571 18.9911 6.00309 19.2383 5.99993 
		19.5361C6.00151 19.8213 5.916 20.074 5.7434 20.2942C5.57238 20.5144 5.33406 20.6871 5.02845 20.8123C4.72441 
		20.9374 4.37367 21 3.97621 21Z"/>
		</mask>
		<path d="M3.97621 21C3.593 21 3.25255 20.9343 2.95485 20.8028C2.65874 20.6697 2.42596 20.4867 2.25653 
		20.2538C2.21975 20.2032 2.18692 20.1511 2.15805 20.0972C1.98282 19.7704 2.30163 19.4506 2.67244 
		19.4506H3.0596C3.20975 19.4506 3.326 19.582 3.41565 19.7025V19.7025C3.47266 19.7753 3.55025 19.8324 3.64842 
		19.8736C3.7466 19.9148 3.85903 19.9354 3.98571 19.9354C4.10764 19.9354 4.21532 19.914 4.30875 19.8712C4.40217 
		19.8268 4.47501 19.7658 4.52727 19.6882C4.57952 19.6106 4.60486 19.5219 4.60328 19.4221C4.60486 19.3238 4.57477 
		19.2367 4.51302 19.1606C4.45284 19.0846 4.36813 19.0252 4.25886 18.9824C4.1496 18.9396 4.02371 18.9183 3.8812 
		18.9183H3.84342C3.59143 18.9183 3.38715 18.714 3.38715 18.462V18.462C3.38715 18.21 3.59143 18.0057 3.84342 
		18.0057H3.8812C4.01263 18.0057 4.12823 17.9843 4.22799 17.9415C4.32933 17.8988 4.40771 17.8394 4.46314 
		17.7633C4.52014 17.6873 4.54785 17.6001 4.54627 17.5019C4.54785 17.4068 4.52489 17.3229 4.47739 17.25C4.42988 
		17.1771 4.36338 17.1201 4.27787 17.0789C4.19394 17.0377 4.09656 17.0171 3.98571 17.0171C3.86536 17.0171 3.75769 
		17.0385 3.66268 17.0813C3.56925 17.124 3.49562 17.1835 3.44178 17.2595V17.2595C3.35245 17.3857 3.23463 17.5209 
		3.08003 17.5209H2.7336C2.38147 17.5209 2.07858 17.2188 2.2363 16.904C2.26605 16.8446 2.30051 16.7872 2.33966 
		16.7319C2.50276 16.5038 2.72604 16.3248 3.00948 16.1949C3.29293 16.065 3.61834 16 3.98571 16C4.34517 16 4.66266 
		16.061 4.93819 16.183C5.2153 16.305 5.43145 16.4729 5.58663 16.6868C5.7434 16.8991 5.82099 17.1423 5.81941 
		17.4163C5.82257 17.6888 5.72915 17.9122 5.53913 18.0865C5.35069 18.2608 5.11158 18.3638 4.8218 
		18.3954V18.4335C5.21451 18.4762 5.50983 18.5966 5.70777 18.7947C5.90571 18.9911 6.00309 19.2383 5.99993 
		19.5361C6.00151 19.8213 5.916 20.074 5.7434 20.2942C5.57238 20.5144 5.33406 20.6871 5.02845 20.8123C4.72441 
		20.9374 4.37367 21 3.97621 21Z"  stroke-width="4" mask="url(#path-4-inside-3_3331_9232)"/>
		</svg>`,
	TEXT_PANEL_UL = `<svg xmlns="http://www.w3.org/2000/svg" width="24" 
		height="24" viewBox="0 0 24 24" fill="none">
		<path d="M4 13C4.55228 13 5 12.5523 5 12C5 11.4477 4.55228 11 4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 
		13 4 13Z" />
		<path d="M4 7C4.55228 7 5 6.55228 5 6C5 5.44772 4.55228 5 4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7Z" 
		fill="#7F8184"/>
		<path d="M4 19C4.55228 19 5 18.5523 5 18C5 17.4477 4.55228 17 4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 
		19 4 19Z" />
		<path d="M21 12L9 12M21 6L9 6M21 18L9 18M5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477 
		3.44772 11 4 11C4.55228 11 5 11.4477 5 12ZM5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 
		3.44772 5 4 5C4.55228 5 5 5.44772 5 6ZM5 18C5 18.5523 4.55228 19 4 19C3.44772 19 3 18.5523 3 18C3 17.4477 
		3.44772 17 4 17C4.55228 17 5 17.4477 5 18Z"  stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		</svg>`,
	TEXT_PANEL_LINK = `<svg xmlns="http://www.w3.org/2000/svg" width="20" 
		height="20" viewBox="0 0 20 20" fill="none">
		<path d="M8.33057 10.8333C8.68845 11.3118 9.14503 11.7077 9.66936 11.9941C10.1937 12.2806 10.7735 12.4509 
		11.3694 12.4936C11.9654 12.5363 12.5636 12.4503 13.1234 12.2415C13.6832 12.0327 14.1915 11.7059 14.6139 
		11.2833L17.1139 8.78334C17.8729 7.9975 18.2929 6.94499 18.2834 5.85251C18.2739 4.76002 17.8357 3.71497 
		17.0631 2.94243C16.2906 2.1699 15.2456 1.7317 14.1531 1.7222C13.0606 1.71271 12.0081 2.13269 11.2222 
		2.89168L9.7889 4.31668M11.6639 9.16668C11.306 8.68824 10.8494 8.29236 10.3251 8.00589C9.80078 7.71943 
		9.22098 7.54908 8.62503 7.5064C8.02908 7.46372 7.43091 7.54971 6.87112 7.75853C6.31132 7.96735 5.80297 
		8.29412 5.38057 8.71668L2.88057 11.2167C2.12158 12.0025 1.7016 13.055 1.7111 14.1475C1.72059 15.24 2.15879 
		16.2851 2.93133 17.0576C3.70386 17.8301 4.74891 18.2683 5.8414 18.2778C6.93388 18.2873 7.98639 17.8673 8.77224 
		17.1083L10.1972 15.6833" stroke="#7F8184" stroke-width="1.66667" stroke-linecap="round" 
		stroke-linejoin="round"/>
		</svg><h5>Текст-ссылка</h5>`,

	MERGE_CELLS = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path d="M12 12L12 21M21 12L3 12M21 7.8L21 16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 
		19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21L7.8 21C6.11984 21 5.27976 21 4.63803 
		20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2L3 7.8C3 6.11984 3 
		5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3L16.2 
		3C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 
		21 7.8Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	SPLIT_CELLS = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path d="M3 11L21 11L21 7.26667C21 5.77319 21 5.02646 20.673 4.45603C20.3854 3.95426 19.9265 3.54631 
		19.362 3.29065C18.7202 3 17.8802 3 16.2 3L7.8 3C6.11984 3 5.27976 3 4.63803 3.29065C4.07354 3.54631 
		3.6146 3.95426 3.32698 4.45602C3 5.02646 3 5.77319 3 7.26667L3 11Z" stroke="black" stroke-width="2" 
		stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M10 21L10 14L3 14L3 17.2667C3 18.5735 3 19.2269 3.25432 19.726C3.47802 20.165 3.83498 20.522 
		4.27402 20.7457C4.77315 21 5.42654 21 6.73333 21L10 21Z" stroke="black" stroke-width="2" 
		stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M14 21L14 14L21 14L21 17.2667C21 18.5735 21 19.2269 20.7457 19.726C20.522 20.165 20.165 20.522 
		19.726 20.7457C19.2269 21 18.5735 21 17.2667 21L14 21Z" stroke="black" stroke-width="2" 
		stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	DELETE_COLUMN = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<g clip-path="url(#clip0_6944_3752)">
		<path d="M13 -8L13 8L20 8L20 -8L13 -8Z" stroke="black" stroke-width="2"/>
		<path d="M12 -7L12 8L4 8L4 -7L12 -7Z" stroke="black" stroke-width="2"/>
		<path d="M9.5 -8.5L9.5 8.5L14.5 8.5L14.5 -8.5L9.5 -8.5Z" fill="#0075FF" stroke="#0075FF"/>
		<path d="M9 13L15 19M15 13L9 19" stroke="#FF0000" stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		</g>
		<defs>
		<clipPath id="clip0_6944_3752">
		<rect width="18" height="19" fill="white" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 21 2.5)"/>
		</clipPath>
		</defs>
		</svg>`,
	DELETE_ROW = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<g clip-path="url(#clip0_6944_3762)">
		<path d="M-8 11L8 11L8 4L-8 4L-8 11Z" stroke="black" stroke-width="2"/>
		<path d="M-7 12L8 12L8 20L-7 20L-7 12Z" stroke="black" stroke-width="2"/>
		<path d="M-8.5 14.5L8.5 14.5L8.5 9.5L-8.5 9.5L-8.5 14.5Z" fill="#0075FF" stroke="#0075FF"/>
		<path d="M13 15L19 9M13 9L19 15" stroke="#FF0000" stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		</g>
		<defs>
		<clipPath id="clip0_6944_3762">
		<rect width="18" height="19" fill="white" transform="matrix(-4.37114e-08 1 1 4.37114e-08 2.5 3)"/>
		</clipPath>
		</defs>
		</svg>`,

	OL_TABLE = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path d="M21 12L9 12M21 6L9 6M21 18L9 18" stroke="#7F8184" stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		<mask id="path-2-inside-1_6944_3721" fill="white">
		<path d="M4 3C4.55228 3 5 3.44772 5 4V7.17913C5 7.63249 4.63249 8 4.17913 8V8C3.72578 8 3.35827 7.63249 3.35827
		 7.17913V4.25H3.32283L2.74739 4.53462C2.40323 4.70485 2 4.45444 2 4.07048V4.07048C2 3.87513 2.10994 3.6964 
		 2.28429 3.60831L3.2755 3.10747C3.41534 3.03681 3.56981 3 3.72648 3H4Z"/>
		</mask>
		<path d="M4 3C4.55228 3 5 3.44772 5 4V7.17913C5 7.63249 4.63249 8 4.17913 8V8C3.72578 8 3.35827 7.63249 
		3.35827 7.17913V4.25H3.32283L2.74739 4.53462C2.40323 4.70485 2 4.45444 2 4.07048V4.07048C2 3.87513 2.10994 
		3.6964 2.28429 3.60831L3.2755 3.10747C3.41534 3.03681 3.56981 3 3.72648 3H4Z" stroke="#7F8184" stroke-width="4"
		 mask="url(#path-2-inside-1_6944_3721)"/>
		<mask id="path-3-inside-2_6944_3721" fill="white">
		<path d="M2.7504 14.5C2.39078 14.5 2.09926 14.2085 2.09926 13.8489V13.8489C2.09926 13.6515 2.18881 13.4647 
		2.34273 13.3411L3.99504 12.0145C4.12076 11.9133 4.22829 11.8186 4.31762 11.7303C4.4086 11.6403 4.47808 11.548
		 4.52605 11.4533C4.57568 11.3585 4.6005 11.2534 4.6005 11.1378C4.6005 11.0109 4.57237 10.9025 4.51613 
		 10.8126C4.46154 10.7227 4.38544 10.6537 4.28784 10.6055C4.19024 10.5557 4.07775 10.5308 3.95037 
		 10.5308C3.82299 10.5308 3.7105 10.5557 3.6129 10.6055C3.51696 10.6553 3.44251 10.7283 3.38958 
		 10.8247V10.8247C3.29268 11.001 3.14706 11.1763 2.94584 11.1763H2.65509C2.29329 11.1763 1.9806 
		 10.8718 2.11168 10.5346C2.14653 10.445 2.18954 10.3602 2.24069 10.2803C2.40116 10.0299 2.62779 9.83719 
		 2.9206 9.70231C3.2134 9.56744 3.55666 9.5 3.95037 9.5C4.35732 9.5 4.70968 9.56342 5.00744 9.69027C5.30687 
		 9.81551 5.53763 9.99213 5.69975 10.2201C5.86352 10.4481 5.94541 10.7155 5.94541 11.0222C5.94541 11.2116 
		 5.90488 11.4003 5.82382 11.5882C5.74276 11.7744 5.59719 11.9807 5.3871 12.2071C5.17701 12.4335 4.87841 
		 12.7033 4.49131 13.0164L4.02215 13.3959C4.01756 13.3996 4.01489 13.4052 4.01489 13.4111V13.4111C4.01489 
		 13.4219 4.02365 13.4306 4.03445 13.4306H5.46532C5.76061 13.4306 6 13.67 6 13.9653V13.9653C6 14.2606 
		 5.76061 14.5 5.46532 14.5H2.7504Z"/>
		</mask>
		<path d="M2.7504 14.5C2.39078 14.5 2.09926 14.2085 2.09926 13.8489V13.8489C2.09926 13.6515 2.18881 13.4647 
		2.34273 13.3411L3.99504 12.0145C4.12076 11.9133 4.22829 11.8186 4.31762 11.7303C4.4086 11.6403 4.47808 
		11.548 4.52605 11.4533C4.57568 11.3585 4.6005 11.2534 4.6005 11.1378C4.6005 11.0109 4.57237 10.9025 4.51613
		 10.8126C4.46154 10.7227 4.38544 10.6537 4.28784 10.6055C4.19024 10.5557 4.07775 10.5308 3.95037 
		 10.5308C3.82299 10.5308 3.7105 10.5557 3.6129 10.6055C3.51696 10.6553 3.44251 10.7283 3.38958 
		 10.8247V10.8247C3.29268 11.001 3.14706 11.1763 2.94584 11.1763H2.65509C2.29329 11.1763 1.9806 10.8718 
		 2.11168 10.5346C2.14653 10.445 2.18954 10.3602 2.24069 10.2803C2.40116 10.0299 2.62779 9.83719 2.9206 
		 9.70231C3.2134 9.56744 3.55666 9.5 3.95037 9.5C4.35732 9.5 4.70968 9.56342 5.00744 9.69027C5.30687 
		 9.81551 5.53763 9.99213 5.69975 10.2201C5.86352 10.4481 5.94541 10.7155 5.94541 11.0222C5.94541 11.2116 
		 5.90488 11.4003 5.82382 11.5882C5.74276 11.7744 5.59719 11.9807 5.3871 12.2071C5.17701 12.4335 4.87841 
		 12.7033 4.49131 13.0164L4.02215 13.3959C4.01756 13.3996 4.01489 13.4052 4.01489 13.4111V13.4111C4.01489 
		 13.4219 4.02365 13.4306 4.03445 13.4306H5.46532C5.76061 13.4306 6 13.67 6 13.9653V13.9653C6 14.2606 
		 5.76061 14.5 5.46532 14.5H2.7504Z" stroke="#7F8184" stroke-width="4" mask="url(#path-3-inside-2_6944_3721)"/>
		<mask id="path-4-inside-3_6944_3721" fill="white">
		<path d="M3.97621 21C3.593 21 3.25255 20.9343 2.95485 20.8028C2.65874 20.6697 2.42596 20.4867 2.25653 
		20.2538C2.21975 20.2032 2.18692 20.1511 2.15805 20.0972C1.98282 19.7704 2.30163 19.4506 2.67244 
		19.4506H3.0596C3.20975 19.4506 3.326 19.582 3.41565 19.7025V19.7025C3.47266 19.7753 3.55025 19.8324 
		3.64842 19.8736C3.7466 19.9148 3.85903 19.9354 3.98571 19.9354C4.10764 19.9354 4.21532 19.914 4.30875 
		19.8712C4.40217 19.8268 4.47501 19.7658 4.52727 19.6882C4.57952 19.6106 4.60486 19.5219 4.60328 
		19.4221C4.60486 19.3238 4.57477 19.2367 4.51302 19.1606C4.45284 19.0846 4.36813 19.0252 4.25886 
		18.9824C4.1496 18.9396 4.02371 18.9183 3.8812 18.9183H3.84342C3.59143 18.9183 3.38715 18.714 3.38715 
		18.462V18.462C3.38715 18.21 3.59143 18.0057 3.84342 18.0057H3.8812C4.01263 18.0057 4.12823 17.9843 
		4.22799 17.9415C4.32933 17.8988 4.40771 17.8394 4.46314 17.7633C4.52014 17.6873 4.54785 17.6001 4.54627 
		17.5019C4.54785 17.4068 4.52489 17.3229 4.47739 17.25C4.42988 17.1771 4.36338 17.1201 4.27787 
		17.0789C4.19394 17.0377 4.09656 17.0171 3.98571 17.0171C3.86536 17.0171 3.75769 17.0385 3.66268 
		17.0813C3.56925 17.124 3.49562 17.1835 3.44178 17.2595V17.2595C3.35245 17.3857 3.23463 17.5209 3.08003 
		17.5209H2.7336C2.38147 17.5209 2.07858 17.2188 2.2363 16.904C2.26605 16.8446 2.30051 16.7872 2.33966 
		16.7319C2.50276 16.5038 2.72604 16.3248 3.00948 16.1949C3.29293 16.065 3.61834 16 3.98571 16C4.34517 
		16 4.66266 16.061 4.93819 16.183C5.2153 16.305 5.43145 16.4729 5.58663 16.6868C5.7434 16.8991 5.82099 
		17.1423 5.81941 17.4163C5.82257 17.6888 5.72915 17.9122 5.53913 18.0865C5.35069 18.2608 5.11158 18.3638 
		4.8218 18.3954V18.4335C5.21451 18.4762 5.50983 18.5966 5.70777 18.7947C5.90571 18.9911 6.00309 19.2383 
		5.99993 19.5361C6.00151 19.8213 5.916 20.074 5.7434 20.2942C5.57238 20.5144 5.33406 20.6871 5.02845 
		20.8123C4.72441 20.9374 4.37367 21 3.97621 21Z"/>
		</mask>
		<path d="M3.97621 21C3.593 21 3.25255 20.9343 2.95485 20.8028C2.65874 20.6697 2.42596 20.4867 2.25653 
		20.2538C2.21975 20.2032 2.18692 20.1511 2.15805 20.0972C1.98282 19.7704 2.30163 19.4506 2.67244 
		19.4506H3.0596C3.20975 19.4506 3.326 19.582 3.41565 19.7025V19.7025C3.47266 19.7753 3.55025 19.8324 
		3.64842 19.8736C3.7466 19.9148 3.85903 19.9354 3.98571 19.9354C4.10764 19.9354 4.21532 19.914 4.30875 
		19.8712C4.40217 19.8268 4.47501 19.7658 4.52727 19.6882C4.57952 19.6106 4.60486 19.5219 4.60328 
		19.4221C4.60486 19.3238 4.57477 19.2367 4.51302 19.1606C4.45284 19.0846 4.36813 19.0252 4.25886 
		18.9824C4.1496 18.9396 4.02371 18.9183 3.8812 18.9183H3.84342C3.59143 18.9183 3.38715 18.714 3.38715 
		18.462V18.462C3.38715 18.21 3.59143 18.0057 3.84342 18.0057H3.8812C4.01263 18.0057 4.12823 17.9843 
		4.22799 17.9415C4.32933 17.8988 4.40771 17.8394 4.46314 17.7633C4.52014 17.6873 4.54785 17.6001 4.54627 
		17.5019C4.54785 17.4068 4.52489 17.3229 4.47739 17.25C4.42988 17.1771 4.36338 17.1201 4.27787 
		17.0789C4.19394 17.0377 4.09656 17.0171 3.98571 17.0171C3.86536 17.0171 3.75769 17.0385 3.66268 
		17.0813C3.56925 17.124 3.49562 17.1835 3.44178 17.2595V17.2595C3.35245 17.3857 3.23463 17.5209 3.08003 
		17.5209H2.7336C2.38147 17.5209 2.07858 17.2188 2.2363 16.904C2.26605 16.8446 2.30051 16.7872 2.33966 
		16.7319C2.50276 16.5038 2.72604 16.3248 3.00948 16.1949C3.29293 16.065 3.61834 16 3.98571 16C4.34517 16 
		4.66266 16.061 4.93819 16.183C5.2153 16.305 5.43145 16.4729 5.58663 16.6868C5.7434 16.8991 5.82099 17.1423 
		5.81941 17.4163C5.82257 17.6888 5.72915 17.9122 5.53913 18.0865C5.35069 18.2608 5.11158 18.3638 4.8218 
		18.3954V18.4335C5.21451 18.4762 5.50983 18.5966 5.70777 18.7947C5.90571 18.9911 6.00309 19.2383 5.99993 
		19.5361C6.00151 19.8213 5.916 20.074 5.7434 20.2942C5.57238 20.5144 5.33406 20.6871 5.02845 
		20.8123C4.72441 20.9374 4.37367 21 3.97621 21Z" stroke="#7F8184" stroke-width="4" 
		mask="url(#path-4-inside-3_6944_3721)"/>
		</svg>`,
	TEXT_ALIGN_LEFT = `<svg xmlns="http://www.w3.org/2000/svg" 
		width="19" height="14" viewBox="0 0 19 14" fill="none">
		<path d="M14 5H1M18 1H1M18 9H1M14 13H1" stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		</svg>`,
	TEXT_ALIGN_CENTER = `<svg xmlns="http://www.w3.org/2000/svg" 
		width="20" height="14" viewBox="0 0 20 14" fill="none">
		<path d="M16 5H4M19 1H1M19 9H1M16 13H4"  stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		</svg>`,
	TEXT_ALIGN_RIGHT = `<svg xmlns="http://www.w3.org/2000/svg" 
		width="19" height="14" viewBox="0 0 19 14" fill="none">
		<path d="M18 5H5M18 1H1M18 9H1M18 13H5" stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		</svg>`,
	TEXT_ALIGN_JUSTIFY = `<svg xmlns="http://www.w3.org/2000/svg" 
		width="20" height="14" viewBox="0 0 20 14" fill="none">
		<path d="M19 5H1M19 13H1M19 1H1M19 9H1" stroke-width="2" stroke-linecap="round" 
		stroke-linejoin="round"/>
		</svg>`,
	TEXT_ALIGN_HEIGHT = `<svg xmlns="http://www.w3.org/2000/svg" 
		width="20" height="18" viewBox="0 0 20 18" fill="none">
		<path d="M19 7H11M19 3H11M19 11H11M19 15H11M4 17L4 1M4 17L1 14M4 17L7 14M4 1L1 4M4 1L7 4" 
		stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`,
	FONT_BOLD = `<svg xmlns="http://www.w3.org/2000/svg" 
		width="16" height="12" viewBox="0 0 16 12" fill="none">
		<path d="M9.16102 0H6.83898V4.93945H5.63925L3.09606 0H0.149274L3.54941 5.67188L0 12H2.83621L5.29095 
		7.2832H6.83898V12H9.16102V7.2832H10.7478L13.1417 12H16L12.5169 5.6543L15.8341 0H12.8984L10.4216 
		4.93945H9.16102V0Z" fill="#7F8184"/>
		</svg>`,
	FONT_ITALIC = `<svg xmlns="http://www.w3.org/2000/svg" width="11"
		 height="12" viewBox="0 0 11 12" fill="none">
		<path d="M0 12H2.31663L2.84095 8.60156L4.2213 7.06641L6.45768 12H9.22909L6.13667 5.37305L11 0H8.22325L3.48298 
		5.29102H3.33852L4.1357 0H1.81907L0 12Z" />
		</svg>`,
	FIGURE_TOOL = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" >
			<path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.93828C0 1.31552 1.31551 0 2.93828 0H25.0617C26.6845 
			0 28 1.31551 28 2.93828V25.0617C28 26.6845 26.6845 28 25.0617 28H2.93828C1.31552 28 0 26.6845 0 
			25.0617V2.93828ZM2.93828 1.95885C2.39736 1.95885 1.95885 2.39736 1.95885 2.93828V25.0617C1.95885 
			25.6026 2.39736 26.0411 2.93828 26.0411H25.0617C25.6026 26.0411 26.0411 25.6026 26.0411 
			25.0617V2.93828C26.0411 2.39736 25.6026 1.95885 25.0617 1.95885H2.93828Z" />
		</svg>`,
	CROP_IMAGE = `<svg width="14" height="14" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.375 2.24998H4.85C3.58988 2.24998 2.95982 2.24998 2.47852 2.49522C2.05516 2.71093 1.71095 
			3.05514 1.49524 3.47851C1.25 3.95981 1.25 4.58987 1.25 5.84998V12.15C1.25 13.4101 1.25 14.0402 1.49524 
			14.5215C1.71095 14.9448 2.05516 15.289 2.47852 15.5047C2.95982 15.75 3.58988 15.75 4.85 15.75H11.75C12.4475 
			15.75 12.7962 15.75 13.0823 15.6733C13.8588 15.4653 14.4653 14.8588 14.6733 14.0823C14.75 13.7962 14.75 
			13.4475 14.75 12.75M10.4421 4.42833C10.4421 4.42833 10.9758 5.15556 11.4094 5.58948C11.8431 6.0234 12.4423 
			6.29181 13.1042 6.29181C14.4274 6.29181 15.5001 5.21913 15.5001 3.8959C15.5001 2.57268 14.4274 1.5 13.1042 
			1.5C12.3846 1.5 11.7391 1.81723 11.2999 2.31948M10.4421 4.42833V6.0256M10.4421 4.42833H12.0393M6.875 
			6.37498C6.875 7.20341 6.20343 7.87498 5.375 7.87498C4.54657 7.87498 3.875 7.20341 3.875 6.37498C3.875 
			5.54656 4.54657 4.87498 5.375 4.87498C6.20343 4.87498 6.875 5.54656 6.875 6.37498ZM10.2425 
			8.93859L3.89836 14.706C3.54152 15.0304 3.3631 15.1926 3.34732 
			15.3331C3.33364 15.4549 3.38034 15.5757 3.47239 15.6566C3.57858 
			15.75 3.81971 15.75 4.30196 15.75H11.342C12.4214 15.75 12.961 15.75 13.3849 15.5687C13.9171 15.341 14.341 
			14.9171 14.5687 14.3849C14.75 13.961 14.75 13.4213 14.75 12.342C14.75 11.9788 14.75 11.7972 14.7103 
			11.6281C14.6604 11.4156 14.5647 11.2165 14.4299 11.0448C14.3227 10.9081 14.1809 10.7947 13.8973 
			10.5678L11.7994 8.88948C11.5155 8.66242 11.3736 8.54888 11.2173 8.50882C11.0796 8.4735 10.9346 8.47807 
			10.7994 8.522C10.646 8.57184 10.5115 8.6941 10.2425 8.93859Z" stroke="#FFFFFF" stroke-width="1.5" 
			   stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
	SEND_KP = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12 4L4 12"
				stroke="#249C5C"
				strokeWidth="1.33"
				strokeLinecap="round"
				strokeLinejoin="round"
				/>
				<path
					d="M5.33594 4H12.0026V10.6667"
				stroke="#249C5C"
				strokeWidth="1.33"
				strokeLinecap="round"
				strokeLinejoin="round"
					/>
					</svg>`,
	DELETE = `<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.66667 4.00016V3.46683C9.66667 2.72009 9.66667 2.34672 9.52134 2.06151C9.39351 1.81063 
		9.18954 1.60665 8.93865 1.47882C8.65344 1.3335 8.28007 1.3335 7.53333 1.3335H6.46667C5.71993 1.3335
		5.34656 1.3335 5.06135 1.47882C4.81046 1.60665 4.60649 1.81063 4.47866 2.06151C4.33333 2.34672 4.33333
		2.72009 4.33333 3.46683V4.00016M5.66667 7.66683V11.0002M8.33333 7.66683V11.0002M1 4.00016H13M11.6667
		4.00016V11.4668C11.6667 12.5869 11.6667 13.147 11.4487 13.5748C11.2569 13.9511 10.951 14.2571 10.5746
		14.4488C10.1468 14.6668 9.58677 14.6668 8.46667 14.6668H5.53333C4.41323 14.6668 3.85318 14.6668 3.42535
		14.4488C3.04903 14.2571 2.74307 13.9511 2.55132 13.5748C2.33333 13.147 2.33333 12.5869 2.33333
		11.4668V4.00016" 
		stroke="#FF3636"
		strokeWidth="1.33"
		strokeLinecap="round"
		strokeLinejoin="round"
			/>
			</svg>`,
	// Столбец справа
	COLUMN_RIGHT = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<g clip-path="url(#clip0_8001_31964)">
		<path d="M20.8871 10.6637C20.8871 10.4309 21.1408 10.2868 21.3408 10.4061L23.5803 11.7424C23.7753 11.8587 
		23.7753 12.1412 23.5803 12.2576L21.3408 13.5938C21.1408 13.7132 20.8871 13.5691 20.8871 13.3362V10.6637Z" 
		fill="#565656"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M20.0098 5C20.0098 3.34315 18.6666 2 17.0098 
		2H9.0055V13H20.0098V5ZM17.0098 4C17.5621 4 18.0098 4.44772 18.0098 5V11H11.0055V4H17.0098Z" fill="#A4A4A4"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M9.00781 22L17.0121 22C18.6689 22 20.0121 20.6569 20.0121 
		19L20.0121 11L9.00781 11L9.00781 22ZM11.0078 20L11.0078 13L18.0121 13L18.0121 19C18.0121 19.5523 17.5644 20 
		17.0121 20L11.0078 20Z" fill="#A4A4A4"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M11.0059 2H3.0016C1.34474 2 0.00159645 3.34315 0.00159645 
		5V13H11.0059V2ZM9.00586 4V11H2.0016V5C2.0016 4.44772 2.44931 4 3.0016 4H9.00586Z" fill="black"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M-2.62268e-07 19C-1.17422e-07 20.6569 1.34315 22 3 22L11.0043 
		22L11.0043 11L-9.6165e-07 11L-2.62268e-07 19ZM3 20C2.44772 20 2 19.5523 2 19L2 13L9.00426 13L9.00426 20L3 20Z" 
		fill="black"/>
		</g>
		<defs>
		<clipPath id="clip0_8001_31964">
		<rect width="24" height="24" fill="white"/>
		</clipPath>
		</defs>
	</svg>`,
	// Столбец слева
	COLUMN_LEFT = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<g clip-path="url(#clip0_8001_31940)">
		<path d="M3.12466 10.6637C3.12466 10.4309 2.87092 10.2868 2.67095 10.4061L0.43144 11.7424C0.236395 11.8587 
		0.236395 12.1412 0.431439 12.2576L2.67095 13.5938C2.87092 13.7132 3.12466 13.5691 3.12466 13.3362V10.6637Z" 
		fill="#565656"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M4.00195 5C4.00195 3.34315 5.3451 2 7.00195 
		2H15.0062V13H4.00195V5ZM7.00195 4C6.44967 4 6.00195 4.44772 6.00195 5V11H13.0062V4H7.00195Z" fill="#A4A4A4"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M15.0039 22L6.99964 22C5.34279 22 3.99964 20.6569 3.99964 
		19L3.99964 11L15.0039 11L15.0039 22ZM13.0039 20L13.0039 13L5.99964 13L5.99964 19C5.99964 19.5523 6.44736 20 
		6.99964 20L13.0039 20Z" fill="#A4A4A4"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M13.0059 2H21.0101C22.667 2 24.0101 3.34315 24.0101 
		5V13H13.0059V2ZM15.0059 4V11H22.0101V5C22.0101 4.44772 21.5624 4 21.0101 4H15.0059Z" fill="black"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M24.0117 19C24.0117 20.6569 22.6686 22 21.0117 22L13.0075 
		22L13.0075 11L24.0117 11L24.0117 19ZM21.0117 20C21.564 20 22.0117 19.5523 22.0117 19L22.0117 13L15.0075 
		13L15.0075 20L21.0117 20Z" fill="black"/>
		</g>
		<defs>
		<clipPath id="clip0_8001_31940">
		<rect width="24" height="24" fill="white"/>
		</clipPath>
		</defs>
	</svg>`,
	// Строка снизу
	ROW_UNDER = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<g clip-path="url(#clip0_8001_31948)">
		<path d="M10.6628 20.8871C10.4299 20.8871 10.2858 21.1408 10.4051 21.3408L11.7414 23.5803C11.8578 23.7753 
		12.1403 23.7753 12.2566 23.5803L13.5929 21.3408C13.7122 21.1408 13.5681 20.8871 13.3352 20.8871L10.6628 
		20.8871Z" fill="#565656"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M5 20.0098C3.34315 20.0098 2 18.6666 2 17.0098L2 9.0055L13 
		9.0055L13 20.0098L5 20.0098ZM4 17.0098C4 17.5621 4.44772 18.0098 5 18.0098L11 18.0098L11 11.0055L4 11.0055L4 
		17.0098Z" fill="#A4A4A4"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.00781L22 17.0121C22 18.6689 20.6569 20.0121 19 
		20.0121L11 20.0121L11 9.00781L22 9.00781ZM20 11.0078L13 11.0078L13 18.0121L19 18.0121C19.5523 18.0121 20 
		17.5644 20 17.0121L20 11.0078Z" fill="#A4A4A4"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M2 11.0059L2 3.0016C2 1.34474 3.34315 0.00159639 
		5 0.00159632L13 0.00159597L13 11.0059L2 11.0059ZM4 9.00586L11 9.00586L11 2.0016L5 2.0016C4.44771 2.0016 4 
		2.44931 4 3.0016L4 9.00586Z" fill="black"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M19 -1.31134e-07C20.6569 -5.87108e-08 22 1.34315 22 3L22 
		11.0043L11 11.0043L11 -4.80825e-07L19 -1.31134e-07ZM20 3C20 2.44772 19.5523 2 19 2L13 2L13 9.00426L20 9.00426L20
		 3Z" fill="black"/>
		</g>
		<defs>
		<clipPath id="clip0_8001_31948">
		<rect width="24" height="24" fill="white"/>
		</clipPath>
		</defs>
	</svg>`,
	// Строка сверху
	ROW_OVER = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path d="M13.3372 2.98209C13.5701 2.98209 13.7142 2.72834 13.5949 2.52837L12.2586 0.288861C12.1422 0.0938172 
		11.8597 0.0938168 11.7434 0.288861L10.4071 2.52837C10.2878 2.72834 10.4319 2.98209 10.6648 2.98209L13.3372 
		2.98209Z" fill="#565656"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M19 3.84668C20.6569 3.84668 22 5.18983 22 6.84668L22 
		14.8509L11 14.8509L11 3.84668L19 3.84668ZM20 6.84668C20 6.29439 19.5523 5.84668 19 5.84668L13 5.84668L13 
		12.8509L20 12.8509L20 6.84668Z" fill="#A4A4A4"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M2 14.8486L2 6.84437C2 5.18752 3.34315 3.84437 5 3.84437L13 
		3.84437L13 14.8486L2 14.8486ZM4 12.8486L11 12.8486L11 5.84437L5 5.84437C4.44772 5.84437 4 6.29209 4 6.84437L4 
		12.8486Z" fill="#A4A4A4"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12.8506L22 20.8548C22 22.5117 20.6569 23.8548 19 
		23.8548L11 23.8548L11 12.8506L22 12.8506ZM20 14.8506L13 14.8506L13 21.8548L19 21.8548C19.5523 21.8548 20 
		21.4071 20 20.8548L20 14.8506Z" fill="black"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M5 23.8574C3.34315 23.8574 2 22.5143 2 20.8574L2 12.8532L13 
		12.8532L13 23.8574L5 23.8574ZM4 20.8574C4 21.4097 4.44772 21.8574 5 21.8574L11 21.8574L11 14.8532L4 14.8532L4 
		20.8574Z" fill="black"/>
	</svg>`,
	LOADER = `
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="4.06592" cy="12" r="1.75" fill="#2D2D2D"/>
			<circle cx="21.5659" cy="12" r="1.75" fill="#D9D9D9"/>
			<circle cx="12.8159" cy="3.25" r="1.75" fill="#D9D9D9"/>
			<circle cx="12.8159" cy="20.75" r="1.75" fill="#D9D9D9"/>
			<circle cx="19.0029" cy="5.81276" r="1.75" transform="rotate(45 19.0029 5.81276)" fill="#D9D9D9"/>
			<circle cx="6.62891" cy="18.1873" r="1.75" transform="rotate(45 6.62891 18.1873)" fill="#D9D9D9"/>
			<circle cx="19.0032" cy="18.1873" r="1.75" transform="rotate(135 19.0032 18.1873)" fill="#D9D9D9"/>
			<circle cx="6.62864" cy="5.81274" r="1.75" transform="rotate(135 6.62864 5.81274)" fill="#D9D9D9"/>
		</svg>
	`,
	GREEN_SAVED_CIRCLE = `
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 
			12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#249C5C" stroke-width="2" 
			stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	`,
	CANCELED = `
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 
			6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#FF3636" stroke-width="2" stroke-linecap="round" 
			stroke-linejoin="round"/>
		</svg>
	`,
	PDF = `<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4.99079 10.4284C4.99079 10.4937 5.04469 10.5466 5.11117 10.5466H5.82754C5.89403
			 10.5466 5.94792 10.4937 5.94792 10.4284V9.38012C5.94792 9.31484 6.00182 9.26192 6.06831 
			 9.26192H6.59762C7.50448 9.26192 8.07102 8.70038 8.07102 7.8419C8.07102 6.99151 7.51608
			  6.40977 6.62662 6.40977H5.11117C5.04469 6.40977 4.99079 6.46268 4.99079 6.52796V10.4284ZM6.06831
			   8.47414C6.00182 8.47414 5.94792 8.42123 5.94792 8.35595V7.33392C5.94792 7.26864 6.00182 7.21572
			    6.06831 7.21572H6.41972C6.84898 7.21572 7.07908 7.45812 7.07908 7.8419C7.07908 8.22367 6.84898
			     8.47414 6.41972 8.47414H6.06831Z" fill="#272727"/>
			<path d="M10.0066 10.5466C11.2131 10.5466 11.9614 9.76893 11.9614 8.47617C11.9614 7.18542 11.2131
			 6.40977 10.0046 6.40977H8.66321C8.59672 6.40977 8.54282 6.46268 8.54282 6.52796V10.4284C8.54282
			  10.4937 8.59672 10.5466 8.66321 10.5466H10.0066ZM9.62034 9.69419C9.55386 9.69419 9.49996 9.64127
			   9.49996 9.57599V7.38038C9.49996 7.3151 9.55386 7.26218 9.62034 7.26218H9.95629C10.6369 7.26218
			    11.0024 7.58537 11.0024 8.47617C11.0024 9.371 10.6369 9.69419 9.96983 9.69419H9.62034Z" fill="#272727"/>
			<path d="M12.5203 10.4284C12.5203 10.4937 12.5742 10.5466 12.6406 10.5466H13.357C13.4235 10.5466 13.4774
			 10.4937 13.4774 10.4284V9.00239C13.4774 8.93711 13.5313 8.88419 13.5978 8.88419H14.931C14.9974 8.88419
			  15.0513 8.83127 15.0513 8.766V8.18835C15.0513 8.12308 14.9974 8.07016 14.931 8.07016H13.5978C13.5313
			   8.07016 13.4774 8.01724 13.4774 7.95196V7.33998C13.4774 7.2747 13.5313 7.22178 13.5978 
			   7.22178H15.1031C15.1695 7.22178 15.2234 7.16886 15.2234 7.10359V6.52796C15.2234 6.46268 15.1695
			    6.40977 15.1031 6.40977H12.6406C12.5742 6.40977 12.5203 6.46268 12.5203 6.52796V10.4284Z"
			     fill="#272727"/>
			<path d="M6.66422 1.54032C6.8061 1.54032 6.8846 1.54066 6.94327 1.54517C6.97462 1.54757 6.98883
			 1.55053 6.99362 1.5518C6.99787 1.55432 7.00985 1.56232 7.03337 1.58272C7.07785 1.62131 7.13347
			  1.67701 7.23384 1.7784L9.75217 4.32242C9.85255 4.42383 9.90767 4.47999 9.94584 4.5249C9.96489
			   4.54731 9.9729 4.55923 9.9757 4.56386C9.97695 4.56912 9.97957 4.58312 9.98176 4.61214C9.98619
			    4.67096 9.98653 4.74984 9.98653 4.89331V5.22781H11.0098V4.89331L11.0099 4.83949C11.0105 4.62409
			     11.0113 4.38552 10.9203 4.1637C10.8295 3.94222 10.6624 3.7745 10.5112 3.62269L10.4735 3.58469L7.95513
			      1.04066L7.91767 1.00266C7.76711 0.849645 7.60148 0.681307 7.38317 0.589956C7.16518 0.49874 6.93085
			       0.499458 6.71733 0.500112L6.66422 0.500221L3.88507 0.50022C3.29482 0.500199 2.80111 0.500182 2.40884
			        0.553459C1.99442 0.609744 1.61734 0.733875 1.31365 1.04066C1.00952 1.34789 0.88605 1.73013 0.830131
			         2.1503C0.777307 2.5472 0.777324 3.0465 0.777345 3.6423V10.3594C0.777324 10.9552 0.777307 11.4545
			          0.830131 11.8514C0.88605 12.2716 1.00952 12.6538 1.31365 12.961C1.61734 13.2678 1.99442 13.392
			           2.40884 13.4482C2.80111 13.5015 3.29482 13.5015 3.88507 13.5015H7.90204C8.4923 13.5015 8.98601
			            13.5015 9.37828 13.4482C9.79269 13.392 10.1698 13.2678 10.4735 12.961C10.7776 12.6538 10.9011
			             12.2716 10.957 11.8514C10.9623 11.8115 10.9671 11.7705 10.9714 11.7286H9.94072C9.90002 12.0202
			              9.83188 12.1428 9.75217 12.2233C9.67053 12.3058 9.54486 12.3763 9.24276 12.4173C8.92627 
			              12.4603 8.50151 12.4614 7.86695 12.4614H3.92016C3.28561 12.4614 2.86085 12.4603 2.54435
			               12.4173C2.24226 12.3763 2.11659 12.3058 2.03495 12.2233C1.95374 12.1413 1.88455 12.0156
			                1.84414 11.712C1.80169 11.393 1.80059 10.9647 1.80059 10.3234V3.67831C1.80059 3.03701
			                 1.80169 2.60868 1.84414 2.28973C1.88455 1.98608 1.95374 1.86043 2.03495 1.7784C2.11659
			                  1.69593 2.24226 1.62543 2.54435 1.5844C2.86085 1.54141 3.28561 1.54032 3.92016
			                   1.54032H6.66422Z" fill="#272727"/>
				<path d="M6.99362 1.5518C6.98883 1.55053 6.97462 1.54757 6.94327 1.54517C6.8846 1.54066 6.8061
				 1.54032 6.66422 1.54032H3.92016C3.28561 1.54032 2.86085 1.54141 2.54435 1.5844C2.24226 1.62543 
				 2.11659 1.69593 2.03495 1.7784C1.95374 1.86043 1.88455 1.98608 1.84414 2.28973C1.80169 2.60868
				  1.80059 3.03701 1.80059 3.67831V10.3234C1.80059 10.9647 1.80169 11.393 1.84414 11.712C1.88455 
				  12.0156 1.95374 12.1413 2.03495 12.2233C2.11659 12.3058 2.24226 12.3763 2.54435 12.4173C2.86085
				   12.4603 3.28561 12.4614 3.92016 12.4614H7.86695C8.50151 12.4614 8.92627 12.4603 9.24276
				    12.4173C9.54486 12.3763 9.67053 12.3058 9.75217 12.2233C9.83188 12.1428 9.90002 12.0202
				     9.94072 11.7286H10.9714C10.9671 11.7705 10.9623 11.8115 10.957 11.8514C10.9011 12.2716 
				     10.7776 12.6538 10.4735 12.961C10.1698 13.2678 9.79269 13.392 9.37828 13.4482C8.98601 
				     13.5015 8.4923 13.5015 7.90204 13.5015H3.88507C3.29482 13.5015 2.80111 13.5015 2.40884 
				     13.4482C1.99442 13.392 1.61734 13.2678 1.31365 12.961C1.00952 12.6538 0.88605 12.2716
				      0.830131 11.8514C0.777307 11.4545 0.777324 10.9552 0.777345 10.3594V3.6423C0.777324 
				      3.0465 0.777307 2.5472 0.830131 2.1503C0.88605 1.73013 1.00952 1.34789 1.31365 1.04066C1.61734
				       0.733875 1.99442 0.609744 2.40884 0.553459C2.80111 0.500182 3.29482 0.500199 3.88507
				        0.50022L6.66422 0.500221L6.71733 0.500112C6.93085 0.499458 7.16518 0.49874 7.38317
				         0.589956C7.60148 0.681307 7.76711 0.849645 7.91767 1.00266L7.95513 1.04066L10.4735 
				         3.58469L10.5112 3.62269C10.6624 3.7745 10.8295 3.94222 10.9203 4.1637C11.0113 4.38552 
				         11.0105 4.62409 11.0099 4.83949L11.0098 4.89331V5.22781H9.98653V4.89331C9.98653 4.74984
				          9.98618 4.67096 9.98176 4.61214C9.97957 4.58312 9.97695 4.56912 9.9757 4.56386C9.9729
				           4.55923 9.96489 4.54731 9.94584 4.5249C9.90767 4.47999 9.85255 4.42383 9.75217
				            4.32242L7.23384 1.7784C7.13347 1.67701 7.07785 1.62131 7.03337 1.58272C7.00985 1.56232
				             6.99787 1.55432 6.99362 1.5518ZM6.99362 1.5518L6.99558 1.55242M6.99362 1.5518L6.99177
				              1.55082M9.97697 4.56619L9.97622 4.56474M9.97545 4.56287L9.97496 4.56128M6.59762 
				              9.26192H6.06831C6.00182 9.26192 5.94792 9.31484 5.94792 9.38012V10.4284C5.94792 
				              10.4937 5.89403 10.5466 5.82754 10.5466H5.11117C5.04469 10.5466 4.99079 10.4937
				               4.99079 10.4284V6.52796C4.99079 6.46268 5.04469 6.40977 5.11117 6.40977H6.62662C7.51608
				                6.40977 8.07102 6.99151 8.07102 7.8419C8.07102 8.70038 7.50448 9.26192 6.59762
				                 9.26192ZM6.41972 7.21572H6.06831C6.00182 7.21572 5.94792 7.26864 5.94792
				                  7.33392V8.35595C5.94792 8.42123 6.00182 8.47414 6.06831 8.47414H6.41972C6.84898
				                   8.47414 7.07908 8.22367 7.07908 7.8419C7.07908 7.45812 6.84898 7.21572 6.41972
				                    7.21572ZM10.0066 10.5466C11.2131 10.5466 11.9614 9.76893 11.9614 8.47617C11.9614
				                     7.18542 11.2131 6.40977 10.0046 6.40977H8.66321C8.59672 6.40977 8.54282 6.46268
				                      8.54282 6.52796V10.4284C8.54282 10.4937 8.59672 10.5466 8.66321
				                       10.5466H10.0066ZM9.95629 7.26218H9.62034C9.55386 7.26218 9.49996 7.3151 
				                       9.49996 7.38038V9.57599C9.49996 9.64127 9.55386 9.69419 9.62034
				                        9.69419H9.96983C10.6369 9.69419 11.0024 9.371 11.0024 8.47617C11.0024
				                         7.58537 10.6369 7.26218 9.95629 7.26218ZM13.4774 7.33998V7.95196C13.4774
				         8.01724 13.5313 8.07016 13.5978 8.07016H14.931C14.9974 8.07016 15.0513 8.12308
				                           15.0513 8.18835V8.766C15.0513 8.83128 14.9974 8.88419 14.931
				         8.88419H13.5978C13.5313 8.88419 13.4774 8.93711 13.4774 9.00239V10.4284C13.4774 
				         10.4937 13.4235 
				         10.5466 13.357 10.5466H12.6406C12.5742 10.5466 12.5203 10.4937 12.5203 10.4284V6.52796C12.5203 
				         6.46268 12.5742 6.40977 12.6406 6.40977H15.1031C15.1695 6.40977 15.2234 6.46268 15.2234 
				         6.52796V7.10359C15.2234 7.16886 15.1695 7.22178 15.1031 7.22178H13.5978C13.5313 7.22178 
				         13.4774 7.2747 13.4774 7.33998Z" stroke="#272727" stroke-width="0.1"/>
</svg>
`,
	SPLIT_TABLE = `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M1 8.5L19 8.5L19 5C19 3.59987 19 2.8998 18.673 2.36502C18.3854 
 1.89462 17.9265 1.51217 17.362 1.27248C16.7202 1 15.8802 1 14.2 1L5.8 
 0.999999C4.11984 0.999999 3.27976 0.999999 2.63803 1.27248C2.07354 
 1.51217 1.6146 1.89462 1.32698 2.36502C1 2.8998 1 3.59987 1 5L1 8.5Z"
  stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
   <rect x="9" y="1" width="2" height="7.5" fill="black"/> <path d="M1 11.5691L19
    11.5691L19 15.0691C19 16.4692 19 17.1693 18.673 17.7041C18.3854 18.1745 17.9265
    18.5569 17.362 18.7966C16.7202 19.0691 15.8802 19.0691 14.2 19.0691L5.8 19.0691C4.11984
    19.0691 3.27976 19.0691 2.63803 18.7966C2.07354 18.5569 1.6146 18.1745 1.32698 
    17.7041C1 17.1693 1 16.4692 1 15.0691L1 11.5691Z" 
    stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> 
    <rect width="2" height="7.5" transform="matrix(1 0 0 -1 9 19.0691)" fill="black"/> </svg>`
	,
	PDF_REPLACE = `
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.25638 4.53352C9.18062 4.52801 9.07886 4.52758 8.89307 
4.52758H4.92932C4.09913 4.52758 3.5454 4.52897 3.13335 4.58198C2.73711 
4.63295 2.58543 4.72002 2.49531 4.80625C2.41325 4.88477 2.33324 5.00707 
2.28374 5.3593C2.23027 5.73989 2.22844 6.25632 2.22844 7.05538L2.22815 
16.9449C2.22815 17.7439 2.22998 18.2604 2.28346 18.641C2.33295 18.9932 
2.41297 19.1155 2.49502 19.194C2.58514 19.2803 2.73682 19.3673 3.13306 
19.4183C3.54511 19.4713 4.09884 19.4727 4.92903 19.4727H10.4652C11.2954 
19.4727 11.8491 19.4713 12.2612 19.4183C12.6574 19.3673 12.8091 19.2803 
12.8992 19.194C12.9813 19.1155 13.0613 18.9932 13.1108 18.641C13.1404 
18.4305 13.1541 18.1784 13.1605 17.86H14.6391C14.6319 18.2326 14.6147 
18.5677 14.5736 18.8604C14.4971 19.4045 14.3253 19.9111 13.9036 20.3146C13.4899 
20.7104 12.9843 20.8644 12.4438 20.9339C11.9296 21 11.2829 21 10.5123 21H4.88192C4.11129 
21 3.46468 21 2.95044 20.9339C2.40993 20.8644 1.90432 20.7104 1.49068 20.3146C1.06896 19.9111 
0.897091 19.4045 0.820643 18.8604C0.749923 18.3571 0.749959 17.7285 0.750002 16.999L0.750291 
7.00126C0.750248 6.27178 0.750211 5.64317 0.820932 5.13986C0.897382 4.59579 1.06925 4.08917 
1.49097 3.68567C1.90461 3.28988 2.41022 3.13594 2.95073 3.0664C3.46498 3.00025 4.11158 3.00027 
4.88222 3.0003L8.89307 3.0003L8.96356 3.00015C9.24117 2.99928 9.55221 2.99831 9.84308 
3.11359C10.1393 3.23097 10.3658 3.44914 10.562 3.63818L10.6114 3.68567L13.9038 
6.83593C13.9207 6.85203 13.938 6.86844 13.9556 6.88519C14.1488 7.06847 14.3848 7.29236 
14.5146 7.59239C14.6474 7.89896 14.6459 8.22966 14.6447 8.49088C14.6446 8.51442 14.6445 
8.53758 14.6445 8.55993L14.6445 9.47554H13.1663L13.1664 8.55993C13.1664 8.3797 13.1658 
8.28975 13.1607 8.22512L13.1599 8.21599C13.1565 8.21197 13.1524 8.20729 13.1476 8.20192C13.1008 
8.14979 13.0318 8.08314 12.8995 7.95652L9.60711 4.80625C9.47503 4.67988 9.40504 4.61358 
9.34989 4.56827C9.33091 4.55268 9.31905 4.54415 9.31271 4.53991C9.30432 4.53833 9.28703 
4.53575 9.25638 4.53352Z" fill="#7F8184"/>
<path d="M16.4144 12.3683C16.4281 12.4303 16.4781 12.4795 16.5396 12.4795H17.6724C17.7379 
12.4795 17.7913 12.4242 17.787 12.3567C17.7234 11.3635 16.9438 10.7145 15.725 10.7145C14.4796 
10.7145 13.5999 11.4068 13.6076 12.4373C13.605 13.2828 14.1727 13.7531 15.101 13.9592L15.6227 
14.0754C16.2057 14.2075 16.4052 14.3581 16.4103 14.6039C16.4052 14.8707 16.1699 15.0583 15.7147 
15.0583C15.2141 15.0583 14.9014 14.8351 14.8362 14.4133C14.8263 14.3489 14.7752 14.2974 14.7121 
14.2974H13.5933C13.5278 14.2974 13.4745 14.3524 13.4776 14.42C13.5347 15.6921 14.4115 16.2632 
15.7352 16.2632C17.0624 16.2632 17.8577 15.6713 17.8628 14.6039C17.8577 13.7795 17.382 13.2009 
16.2262 12.9446L15.7966 12.8494C15.3005 12.7411 15.078 12.5905 15.0908 12.3316C15.0933 12.0938 
15.2851 11.9194 15.725 11.9194C16.1344 11.9194 16.3516 12.0845 16.4144 12.3683Z" fill="#7F8184"/>
<path d="M5.45642 10.7885C5.4991 10.7885 5.53847 10.8123 5.55943 10.8507L6.37465 12.3448C6.38088 
12.3562 6.39257 12.3633 6.40524 12.3633C6.41786 12.3633 6.4295 12.3563 6.43575 12.345L7.26107 
10.8502C7.28211 10.812 7.32131 10.7885 7.36378 10.7885H8.67214C8.76396 10.7885 8.82075 10.8919 
8.77364 10.9734L7.35252 13.4302C7.32982 13.4695 7.33022 13.5185 7.35357 13.5574L8.82975 16.0131C8.87868 
16.0945 8.82203 16.1998 8.7293 16.1998H7.38412C7.34172 16.1998 7.30256 16.1763 7.28149 16.1383L6.4358 
14.6116C6.42952 14.6003 6.41784 14.5933 6.40518 14.5933C6.39253 14.5933 6.38085 14.6003 6.37456 
14.6116L5.52888 16.1383C5.50781 16.1763 5.46865 16.1998 5.42625 16.1998H4.08989C3.99746 16.1998 
3.94077 16.0951 3.98909 16.0137L5.44728 13.557C5.47022 13.5183 5.47056 13.4697 5.44815 13.4307L4.03585 
10.9731C3.98905 10.8916 4.04586 10.7885 4.13753 10.7885H5.45642Z" fill="#7F8184"/>
<path d="M9.61192 16.1998C9.54661 16.1998 9.49367 16.1451 9.49367 16.0776V10.9107C9.49367 10.8432 
9.54661 10.7885 9.61192 10.7885H10.7972C10.8625 10.7885 10.9155 10.8432 10.9155 10.9107V14.8939C10.9155 
14.9614 10.9684 15.0161 11.0337 15.0161H12.9146C12.9799 15.0161 13.0329 15.0708 13.0329 
15.1382V16.0776C13.0329 16.1451 12.9799 16.1998 12.9146 16.1998H9.61192Z" fill="#7F8184"/>
<path d="M19.8587 10.7885C19.9014 10.7885 19.9407 10.8123 19.9617 10.8507L20.7769 12.3448C20.7831 
12.3562 20.7948 12.3633 20.8075 12.3633C20.8201 12.3633 20.8318 12.3563 20.838 12.345L21.6633 
10.8502C21.6844 10.812 21.7236 10.7885 21.766 10.7885H23.0744C23.1662 10.7885 23.223 10.8919 
23.1759 10.9734L21.7548 13.4302C21.7321 13.4695 21.7325 13.5185 21.7558 13.5574L23.232 16.0131C23.2809 
16.0945 23.2243 16.1998 23.1316 16.1998H21.7864C21.744 16.1998 21.7048 16.1763 21.6838 16.1383L20.8381 
14.6116C20.8318 14.6003 20.8201 14.5933 20.8074 14.5933C20.7948 14.5933 20.7831 14.6003 20.7768 
14.6116L19.9311 16.1383C19.9101 16.1763 19.8709 16.1998 19.8285 16.1998H18.4922C18.3997 16.1998 
18.343 16.0951 18.3913 16.0137L19.8495 13.557C19.8725 13.5183 19.8728 13.4697 19.8504 13.4307L18.4381 
10.9731C18.3913 10.8916 18.4481 10.7885 18.5398 10.7885H19.8587Z" fill="#7F8184"/>
</svg>

	`
}

export default SvgCollection;
