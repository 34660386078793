import React, { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Loader from 'react-ts-loaders';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ModalWrapper } from '../../modal-wrapper/modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import {
	getIsGreetingsOpen,
	getIsWorkStagesOpen,
	setIsAiCategoriesModalOpen,
	setWorkStagesOpen,
} from '../../../model/slice/aiSlice';
import css from './css.module.scss';
import { ReactComponent as AiBtn } from '../../../../../shared/images/ai.svg';
import { symbolsCountSelectOptions } from '../../../model/symbolsCountSelectOptions';
import { AiCSelect } from '../../ai-select/AiCSelect';

type FormData = {
	titleStages: string,
	numCharacters: string,
};

export const WorkStagesModal: FC = () => {
	const dispatch = useAppDispatch();
	const active = useAppSelector(getIsWorkStagesOpen);

	const [textResponses, setTextResponses] = useState<string[]>([
		'нейросеть ответила тест 1',
		// 'нейросеть ответила тест 2',
	]);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<FormData>();

	const [data, setData] = useState('');

	// Добавить обработку запроса к нейросети (пока ждем видим loader)
	const [isLoading, setIsLoading] = useState(false);

	const onClose = () => {
		dispatch(setWorkStagesOpen(false));
	};
	const onGoBack = () => {
		dispatch(setWorkStagesOpen(false));
		dispatch(setIsAiCategoriesModalOpen(true));
	};

	const onSubmit = handleSubmit((data) => console.log(data));

	return (
		<ModalWrapper
			active={active}
			closeModal={onClose}
			onGoBack={onGoBack}
			title="Этапы работы"
		>
			<form className={css.form} onSubmit={handleSubmit((data) => setData(JSON.stringify(data)))}>
				<label htmlFor="titleStages">
					Название этапов/перечень
					<textarea 
						id="titleStages"
						{...register('titleStages', { required: true })}
						placeholder="Подготовка, разработка макета..."
					/>
					{errors.titleStages && (
						<span className={css.error} role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<div className={css.selectWrapper}>
					<AiCSelect
						title="Количество символов"
						options={symbolsCountSelectOptions}
						defaultOption={{ title: 'По умолчанию...', value: '3000' }}
					/>
				</div>

				<div className={css.submit}>
					<input className={css.submit_btn} type="submit" value="Написать" />
					<AiBtn className={css.submit_icon} />
				</div>
			</form>

			{
				(isLoading && textResponses.length == 0) && (
					<Loader
						type="roller"
						color="rgba(57, 150, 218, 1)"
						size={100}
						className="alt-loader"
					/>
				)
			}

			{ textResponses.length >= 1 && (
				<div className={css.responsesWrapper}>
					<div className={css.responses}>
						{textResponses.map((data) => (
							<div key={uuid()} className={css.response}>
								{data}
							</div>
						))}
					</div>

					{isLoading && (
						<Loader
							type="roller"
							color="rgba(57, 150, 218, 1)"
							size={100}
							className="alt-loader"
						/>
					)}
				</div>
			)}
		</ModalWrapper>
	);
};
