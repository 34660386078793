import IComponentStructure from './IComponentStructure';
import IComponent from './IComponent';
import SketchComponentType from './SketchComponentType';
import GraphicList from '../graphic/GraphicList';
import ComponentList from './ComponentList';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import Utils from '../utils/impl/Utils';
import IGraphic from '../graphic/IGraphic';
import { AnyGraphicStructure, AnySpatialArea } from '../Types';
import GraphicType from '../graphic/GraphicType';
import TableGraphic from '../graphic/table/TableGraphic';

/**
 * Графический компонент, основа для любого компонента манипулятора.
 */
abstract class GraphicComponent<ComponentTextureType, GraphicType extends IGraphic> implements IComponent {
	public readonly abstract type: SketchComponentType;
	public readonly isUniter: boolean = false;

	private readonly postDisableEditMode: VoidFunction[];
	private readonly postEnableEditMode: VoidFunction[];
	private readonly graphicsState: AnyGraphicStructure[];

	// Отображает, включена ли механика режима редактирования.
	private isEnableEditModeMechanic: boolean;

	private isFocus: boolean;
	private isEditMode: boolean;

	protected readonly componentList: ComponentList;
	protected readonly graphicList: GraphicList<GraphicType>;

	protected id: string;
	protected offset: number | null;
	protected parentComponent: IComponent | null;

	protected constructor() {
		this.offset = null;
		this.isFocus = false;
		this.isEditMode = false;
		this.graphicsState = [];
		this.parentComponent = null;
		this.id = 'not initializing';
		this.postEnableEditMode = [];
		this.postDisableEditMode = [];
		this.isEnableEditModeMechanic = true;
		this.componentList = new ComponentList();
		this.graphicList = new GraphicList<GraphicType>();
	}

	public addPostEnableEditMode = (event: VoidFunction) => {
		this.postEnableEditMode.push(event);
	};

	public getStructure = (): IComponentStructure<ComponentTextureType> => {
		const graphicStructures: AnyGraphicStructure[] = [];
		const graphics = this.getGraphics();
		graphics.forEach((graphic) => {
			const structure = graphic.getStructure();
			graphicStructures.push(structure);
		});

		const componentStructures: IComponentStructure<ComponentTextureType>[] = this.componentList.getElements()
			.map(component => component.getStructure());

		return {
			id: this.id,
			type: this.type,
			offset: this.offset,
			texture: this.getTexture(),
			graphics: graphicStructures,
			components: componentStructures,
		};
	};

	public getUniqueStructure = (): IComponentStructure<ComponentTextureType> => {
		const graphicStructures: AnyGraphicStructure[] = [];
		const graphics = this.getGraphics();
		graphics.forEach((graphic) => {
			const structure = graphic.getUniqueStructure();
			graphicStructures.push(structure);
		});

		const componentStructures: IComponentStructure<ComponentTextureType>[] = this.componentList.getElements()
			.map(component => component.getUniqueStructure());

		return {
			type: this.type,
			offset: this.offset,
			id: Utils.Generate.UUID4(),
			graphics: graphicStructures,
			components: componentStructures,
			texture: this.getUniqueTexture(),
		};
	};

	public setParentComponent = (component: IComponent | null): void => {
		this.parentComponent = component;
	};

	public setStructure = (
		fn: (prev: IComponentStructure<ComponentTextureType>) => IComponentStructure<ComponentTextureType>,
	): void => {
		const current = this.getStructure();
		const updated = fn(current);

		this.id = updated.id;
		this.offset = updated.offset;

		this.setTexture(() => updated.texture);
	};

	public disableHover = (): void => {
		const graphics = this.getGraphics();
		graphics.forEach((graphic) => {
			graphic.disableHover();
		});
	};

	public enableHover = (): void => {
		const graphics = this.getGraphics();
		graphics.forEach((graphic) => {
			graphic.enableHover();
		});
	};

	public enableFocus = () => {
		this.isFocus = true;
		this.getGraphics().forEach((graphic) => {
			graphic.enableFocus();
		});
	};

	public disableFocus = () => {
		this.isFocus = false;
		this.getGraphics().forEach((graphic) => {
			graphic.disableFocus();
		});
	};

	public isEnableFocus = (): boolean => this.isFocus;

	/**
	 * Отключает реагирование на включение режима редактирования.
	 */
	public disableEditModeMechanic = () => {
		this.isEnableEditModeMechanic = false;
	};

	/**
	 * Включает реагирование на включение режима редактирования.
	 */
	public enableEditModeMechanic = () => {
		this.isEnableEditModeMechanic = true;
	};

	public enableEditMode = () => {
		if (this.isEditMode || !this.isEnableEditModeMechanic) {
			return;
		}

		this.isEditMode = true;
		this.getGraphics().forEach((graphic) => {
			graphic.enableEditMode();
		});

		this.runPostEnableEditModeListeners();
	};

	public disableEditMode = () => {
		if (!this.isEditMode) {
			return;
		}

		this.isEditMode = false;
		this.getGraphics().forEach((graphic) => {
			graphic.disableEditMode();
		});

		this.runPostDisableEditModeListeners();
	};

	public appendComponent = (component: IComponent) => {
		component.setParentComponent(this);
		this.componentList.append(component);
	};

	public removeComponent = (component: IComponent) => {
		this.componentList.delete(component);
	};

	public appendGraphic = (graphic: GraphicType) => {
		graphic.setParentComponent(this);
		this.graphicList.append(graphic);
	};

	public appendGraphicAfter = (target: GraphicType, graphic: GraphicType) => {
		this.graphicList.after(graphic, target);
	};

	public appendGraphicBefore = (target: GraphicType, graphic: GraphicType): void => {
		this.graphicList.before(graphic, target);
	};

	public removeGraphic = (graphic: GraphicType) => {
		const isOwnGraphic = this.graphicList.isOwnGraphic(graphic);
		if (!isOwnGraphic) {
			throw new ManipulatorError('is not own graphic');
		}

		graphic.removeFrame();
		this.graphicList.delete(graphic);
	};

	public hasEditableComponent = (): boolean => {
		const components = this.getComponents();
		if (components === null) {
			return false;
		}
		return components.filter(component => component.isEnableEditMode()).length !== 0;
	};

	public getSpatialAreas = (): AnySpatialArea[] => {
		const graphics = this.getGraphics();
		return graphics.map(graphic => graphic.getSpatialAreas()).flat();
	};

	public addPostDisableEditModeListener = (event: VoidFunction) => {
		this.postDisableEditMode.push(event);
	};

	/**
	 * Сохраняет внутри компонента текущее состояние для последующего извлечения или восстановления.
	 */
	public fixGraphicState = () => {
		const graphics = this.getGraphics();
		if (graphics.length === 0) {
			throw new ManipulatorError('graphics nor found');
		}

		this.graphicsState.push(...graphics.map(graphic => graphic.getStructure()));
	};

	public clearGraphicState = () => {
		this.graphicsState.length = 0;
	};

	public getFirstGraphic = (): GraphicType | null => {
		const graphics = this.getGraphics();
		const firstGraphic = graphics[0];
		return firstGraphic === undefined ? null : firstGraphic;
	};

	public getLastGraphic = (): GraphicType | null => {
		const graphics = this.getGraphics();
		const lastGraphic = graphics[graphics.length - 1];
		return lastGraphic === undefined ? null : lastGraphic;
	};

	public recursiveSyncGraphicsOffset = () => {
		this.syncGraphicsOffset();

		const components = this.getComponents();
		components?.forEach(component => component.recursiveSyncGraphicsOffset());
	};

	public getComponents = (): IComponent[] | null => {
		const elements = this.componentList.getElements();
		return elements.length === 0 ? null : elements;
	};

	public getID = () => this.id;
	public getOffset = (): number | null => this.offset;
	public isEnableEditMode = (): boolean => this.isEditMode;

	/**
	 * Включает ли в себя передаваемый компонент.
	 * @param component - передаваемый компонент для проверки
	 */
	public isIncludeComponent = (component: IComponent): boolean => {
		const components = this.getComponents();
		if (components === null) return false;

		for (let i = 0; i < components.length; i++) {
			if (component === components[i]) return true;
			// TODO сделать рекурсию в которой учесть что нужный компонент в одой из нескольких групп
		}
		return false;
	};

	/**
	 * Запускает синхронизацию высоты каждой графики если она подключена к DOM.
	 */
	public syncGraphicsHeight = () => {
		const graphics = this.getGraphics();
		graphics.forEach(graphic => {
			if (!graphic.isConnected()) {
				return;
			}
			const realHeight = graphic.getRealHeight();
			graphic.setFrameConfiguration(prev => ({
				...prev,
				height: realHeight,
			}));
		});
	};

	public syncGraphicsOffset = () => this.graphicList.syncGraphicsOffset();
	public getGraphics = (): GraphicType[] => this.graphicList.getElements();
	public getParentComponent = (): IComponent | null => this.parentComponent;
	public getGraphicState = (): AnyGraphicStructure[] => [...this.graphicsState];
	public getComponentAll = (): IComponent[] => this.getRecursiveComponents(this);

	public abstract getTexture: () => ComponentTextureType;
	public abstract getUniqueTexture: () => ComponentTextureType;
	public abstract setTexture: (fn: (prev: ComponentTextureType) => ComponentTextureType) => void;

	private getRecursiveComponents = (graphicComponent: IComponent): this[] => {
		const result: this[] = [];
		const components = graphicComponent.getComponents();
		if (components === null) {
			return result;
		}
		result.push(...(components as this[]));
		components.forEach((component: IComponent) => {
			result.push(...this.getRecursiveComponents(component));
		});
		return result;
	};

	private runPostDisableEditModeListeners = () => {
		this.postDisableEditMode.forEach(event => event());
	};

	private runPostEnableEditModeListeners = () => {
		this.postEnableEditMode.forEach(event => event());
	};
}

export default GraphicComponent;
