import ScrollObserver from '../Sketch/utils/observers/ScrollObserver';
import ManipulatorError from '../Sketch/utils/manipulator-error/ManipulatorError';
import MouseOffsetObserver from '../Sketch/utils/observers/MouseOffsetObserver';
import MousePositionObserver from '../Sketch/utils/observers/MousePositionObserver';
import IComponentTree from '../Sketch/component-tree/IComponentTree';
import IBaseSketchManipulator from './IBaseSketchManipulator';
import IGraphicFactory from '../Sketch/factories/graphic/IGraphicFactory';
import ISketchStructure from '../Sketch/ISketchStructure';
import IComponentFactory from '../Sketch/factories/component/IComponentFactory';
import store from '../../redux/store/store';
import PageScaleListener from '../Sketch/interface/bars/tool-bar/panelZoom/PageScaleListener';
import { fileAPI } from '../../entities/file/api/api';
import { sketchAPI } from '../../entities/sketch/api/api';
import { templatePrivateAPI } from '../../entities/templates/private/api/api';
import { teamTemplateAPI } from '../../entities/templates/team/api/api';
import PageTextOverflowObserver from '../Sketch/utils/observers/PageTextOverflowObserver';
import PageStructureObserver from '../Sketch/utils/observers/PageStructureObserver';

/**
 * База для реализации конструктора компонентов.
 */
abstract class SketchManipulator<
	ComponentTreeType extends IComponentTree,
	GraphicFactoryType extends IGraphicFactory,
	ComponentFactoryType extends IComponentFactory,
	UseCasesType,
	SpatialAreaTreeType,
> implements IBaseSketchManipulator {
	private readonly destructListeners: VoidFunction[];
	private readonly postChangeNameListeners: VoidFunction[];
	private readonly prevChangeNameListeners: VoidFunction[];
	private readonly enableLoadingModeListeners: VoidFunction[];
	private readonly disableLoadingModeListeners: VoidFunction[];

	private id: string;
	private name: string;
	// Находится ли конструктор на этапе загрузки структуры
	private isLoadingMode: boolean;

	protected readonly scrollObserver: ScrollObserver;
	// Контейнер, который получаем со стороны react
	protected readonly manipulatorElement: HTMLDivElement;
	protected readonly pageScaleListener: PageScaleListener;
	protected readonly mouseOffsetObserver: MouseOffsetObserver;
	protected readonly mousePositionObserver: MousePositionObserver;
	protected readonly pageStructureObserver: PageStructureObserver;
	protected readonly pageTextOverflowObserver: PageTextOverflowObserver;

	protected useCases: UseCasesType;
	protected areaTree: SpatialAreaTreeType;
	protected componentTree: ComponentTreeType;
	protected graphicFactory: GraphicFactoryType;
	protected componentFactory: ComponentFactoryType;

	protected constructor(manipulatorContainer: HTMLDivElement) {
		this.isLoadingMode = false;
		this.destructListeners = [];
		this.postChangeNameListeners = [];
		this.prevChangeNameListeners = [];
		this.enableLoadingModeListeners = [];
		this.disableLoadingModeListeners = [];
		this.scrollObserver = new ScrollObserver();
		this.manipulatorElement = manipulatorContainer;
		this.pageScaleListener = new PageScaleListener();
		this.mouseOffsetObserver = new MouseOffsetObserver();
		this.mousePositionObserver = new MousePositionObserver();
		this.pageStructureObserver = new PageStructureObserver();
		this.pageTextOverflowObserver = new PageTextOverflowObserver();

		ManipulatorError.setSketchConstrictor(this);

		this.addDestructListener(ManipulatorError.resetManipulator);
	}

	public setName = (value: string) => {
		this.prevChangeNameListeners.forEach(listener => listener());
		this.name = value;
		this.postChangeNameListeners.forEach(listener => listener());
	};

	public getID = (): string => this.id;
	public getName = (): string => this.name;

	public getStructure = (): ISketchStructure => ({
		id: this.getID(),
		name: this.getName(),
		root: this.componentTree.getStructure(),
	});

	public addPostChangeNameListener = (listener: VoidFunction) => {
		this.postChangeNameListeners.push(listener);
	};

	public addPrevChangeNameListener = (listener: VoidFunction) => {
		this.prevChangeNameListeners.push(listener);
	};

	public addDestructListener = (event: (() => void)) => {
		this.destructListeners.push(event);
	};

	public destruct = () => {
		this.destructListeners.forEach(event => event());
	};

	public getManipulatorElement = (): HTMLDivElement => this.manipulatorElement;

	protected enableLoadingMode = (): void => {
		this.isLoadingMode = true;
		this.enableLoadingModeListeners.forEach(listener => listener());
	};

	protected addEnableLoadingModeListener = (listener: VoidFunction) => {
		this.isLoadingMode = false;
		this.enableLoadingModeListeners.push(listener);
	};

	protected disableLoadingMode = (): void => {
		this.disableLoadingModeListeners.forEach(listener => listener());
	};

	protected addDisableLoadingModeListener = (listener: VoidFunction) => {
		this.disableLoadingModeListeners.push(listener);
	};

	protected setID = (id: string) => {
		this.id = id;
	};

	/**
	 * Запускает общий алгоритм сохранения preview скетча на сервер.
	 */
	protected savePreview = () => {
		const sketchID = this.getID();
		const rootComponent = this.componentTree.getRootComponent();
		const graphics = rootComponent.getGraphics();
		const firstGraphic = graphics[0];

		if (firstGraphic === undefined) {
			throw new ManipulatorError('graphic not found');
		}

		setTimeout(() => {
			firstGraphic.toBase64((bytes) => {
				store.dispatch(fileAPI.endpoints?.save.initiate({
					name: `preview-${sketchID}-${new Date().toISOString()}`,
					bytes,
				}))
					.unwrap()
					.then(response => {
						this.savePreviewFile(response.id);
					})
					.then(() => {
						store.dispatch(teamTemplateAPI.util?.invalidateTags(['team']));
						store.dispatch(templatePrivateAPI.util?.invalidateTags(['templates']));
					});
			});
		}, 0);
	};

	protected abstract savePreviewFile: (fileID: string) => void;
}

export default SketchManipulator;
