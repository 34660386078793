import ConstructorInjector, { IComponentInjectorDependencies } from '../ConstructorInjector';
import { AnyComponentStructure } from '../../Types';
import SketchComponentType from '../../components/SketchComponentType';
import IComponent from '../../components/IComponent';
import IGraphic from '../../graphic/IGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import CSSCursor from '../../cursor/CSSCursor';
import ModulePlaceholder from '../../mechanics/module-placeholder/ModulePlaceholder';
import IDescartesPosition from '../../utils/IDescartesPosition';

export interface IModuleInjectorDependencies extends IComponentInjectorDependencies {
	modulePlaceHolder: ModulePlaceholder;
}

interface IPostModuleInjectLayersFromPage {
	// Последняя в контексте слоев графика на странице
	baseGraphic: IGraphic,
	// Графика компонентов модуля
	moduleGraphics: IGraphic[],
}

interface IPostModuleInjectLayers {
	pages: IPostModuleInjectLayersFromPage[],
}

class ModuleInjector extends ConstructorInjector<IModuleInjectorDependencies> {
	/* Оригинальная структура модуля, которая приходит с сервера. Её мы не меняем. */
	private moduleStructure: AnyComponentStructure | null;
	private preview: string | null;

	constructor() {
		super();
		this.moduleStructure = null;
		this.preview = null;
	}

	/**
	 * Вставляет компонент модуля на страницу.
	 */
	public inject = (): void => {
		// Если флаг, разрешающий инжекцию выключен - выкинем ошибку
		const isReady = this.isReadyInject();
		if (!isReady) {
			throw new ManipulatorError('injector not ready inject component');
		}

		this.dependencies.componentTree.executeMutations(tools => {
			if (this.moduleStructure === null) {
				throw new ManipulatorError('module structure not load');
			}

			const rootComponent = this.dependencies.componentTree.getRootComponent();

			/* Создадим компоненты с другими id. Смысл в том, что мы не мутируем структуры, которые
			приходят с сервера. */
			tools.componentBuilder.scanStructure(this.moduleStructure);
			tools.componentBuilder.regenerate();

			// Последовательность слоев во вставляемом модуле
			const layerSequences = tools.componentBuilder.getLayerSequences();
			
			const moduleStructure = tools.componentBuilder.getStructure();
			if (!moduleStructure.graphics) throw new ManipulatorError('not have module graphic', moduleStructure);
			// Получаем позицию мыши в момент использования инжектора
			const mousePosition = this.dependencies.mousePositionObserver.getCurrentPosition();
			// Получаем параметры инжекции для компонента
			const injectParams = this.calculateComponentInjectionParams(mousePosition);

			moduleStructure.offset = injectParams.componentOffset;

			/* Сформируем массив объектов, состоящих из страницы, на которую будет вставляться графика модуля и
			график модуля этой страницы. Таким образом мы решим проблему того, что при переборе графики ниже мы не
			сможем найти последнюю графику правильно, так как при mutateByAppendMultiComponent у нас сразу же появится
			физически графика пока не упорядоченная. */
			const firstPageForModule = injectParams.componentOffset;

			const postInjectLayers: IPostModuleInjectLayers = {
				pages: [],
			};

			// Находит базовую графику для каждой страницы, на которую вставляется модуль
			for (let i = 0; i < moduleStructure.graphics.length; i++) {
				const baseGraphic = this.dependencies.componentTree
					.getLastLayerFromRootGraphicNumber(firstPageForModule + i);
				postInjectLayers.pages.push({
					baseGraphic,
					moduleGraphics: [],
				});
			}

			// Физически вставляет компонент на страницу
			const moduleComponent = tools.mutator.mutateByAppendMultiComponent(rootComponent, moduleStructure);
			const components: IComponent[] = [moduleComponent, ...moduleComponent.getComponentAll()];
			const graphics: IGraphic[] = components.map(component => component.getGraphics()).flat();

			// Завершает формирование последовательности слоев после вставки последовательностью графики модуля
			for (let i = 0; i < postInjectLayers.pages.length; i++) {
				const moduleGraphics: IGraphic[] = layerSequences[i].map(graphicID => {
					const graphic = graphics.find(graphic => graphic.getID() === graphicID);
					if (graphic === undefined) {
						throw new ManipulatorError('graphic not found', {
							layerSequences: layerSequences[i],
							graphics: graphics.map(graphic => graphic.getID()),
						});
					}

					return graphic;
				});

				postInjectLayers.pages[i].moduleGraphics = moduleGraphics;
			}

			// Применяет правильную последовательность слоев
			postInjectLayers.pages.forEach(pageSequence => {
				tools.mutator.mutateByChangeLayer(pageSequence.baseGraphic, pageSequence.moduleGraphics[0]);
				for (let i = 0; i < pageSequence.moduleGraphics.length - 1; i++) {
					tools.mutator
						.mutateByChangeLayer(pageSequence.moduleGraphics[i], pageSequence.moduleGraphics[i + 1]);
				}
			});

			const moduleGraphics = moduleComponent.getGraphics();
			const firstGraphic = moduleGraphics[0];

			const moduleSection = this.getModuleAlignmentSection(mousePosition, firstGraphic.getRealWidth());

			firstGraphic.setFrameConfiguration(prev => ({
				...prev,
				x: moduleSection - injectParams.x,
				y: mousePosition.y - injectParams.y,
			}));

			this.stop();
			this.callSingleUseInjectListeners(moduleComponent);

			this.callPostInjectListeners(moduleComponent);
		});
	};

	public run(): void {
		this.dependencies.cursorView.enableComponentInjectMode();

		if (this.preview === null) {
			throw new ManipulatorError('preview module not found');
		}
		const currentPosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		this.dependencies.modulePlaceHolder.start(currentPosition, this.preview);

		this.dependencies.cursorView.setCursor(CSSCursor.CROSSHAIR);
		this.isProcess = true;
		this.setReadyInject();
	}

	public stop = (): void => {
		this.dependencies.cursorView.disableComponentInjectMode();
		this.dependencies.modulePlaceHolder.stop();
		this.isProcess = false;
	};

	public loadStructure = (originalStructure: AnyComponentStructure, preview: string) => {
		this.moduleStructure = originalStructure;
		this.preview = preview;
	};

	protected getDefaultStructure = (_: object): AnyComponentStructure => ({
		id: '',
		type: SketchComponentType.MODULE,
		texture: {},
		offset: null,
		graphics: null,
		components: null,
	});

	/**
	 * Рассчитывает позицию модуля в одной из трёх горизонтальных секций страницы
	 * (лево, центр или право) на основе текущей позиции мыши.
	 * @param position Позиция мыши.
	 * @param moduleWidth Ширина модуля.
	 */
	private getModuleAlignmentSection = (position: IDescartesPosition, moduleWidth: number) => {
		const treeRootGraphics = this.dependencies.componentTree.getRootGraphics();
		const { x, width } = treeRootGraphics[0].getFrameConfiguration();

		const sectionWidth = width / 3;

		// Левый край
		if (position.x <= sectionWidth + x) {
			return x;
		}

		// Центр
		if (position.x > sectionWidth + x && position.x <= (sectionWidth * 2) + x) {
			return (width / 2) - (moduleWidth / 2) + x;
		}

		// Правый край
		return (width - moduleWidth + x);
	};
}

export default ModuleInjector;
