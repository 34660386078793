import XLSXTableReplacer, { IXLSXTableReplacerDependencies } from './XLSXTableReplacer';
import SpatialAreaTree from '../spatial-quadrants/spatial-tree/SpatialAreaTree';
import SketchStructureStabilizer from '../mutation-observer/SketchStructureStabilizer';
import ComponentFocusObserver from '../../utils/observers/ComponentFocusObserver';
import TableComponent from '../../components/table/TableComponent';
import ComponentOrganizer from '../component-organizer/ComponentOrganizer';

interface IMultiPageTableReplacerDependencies extends IXLSXTableReplacerDependencies {
	spatialTree: SpatialAreaTree,
	componentOrganizer: ComponentOrganizer,
	sketchStabilizer: SketchStructureStabilizer,
	componentFocusObserver: ComponentFocusObserver,
}

class MultiPageTableReplacer extends XLSXTableReplacer<IMultiPageTableReplacerDependencies> {
	constructor() {
		super();
		this.addPrevReplaceListener(() => {
			this.dependencies.sketchStabilizer.startUserAction();
		});
		this.addPostReplaceListener(() => {
			setTimeout(this.dependencies.componentOrganizer.sync, 0);
			setTimeout(this.dependencies.componentOrganizer.sync, 100);
			setTimeout(this.dependencies.componentFocusObserver.sync.bind(this), 100);
			setTimeout(this.dependencies.sketchStabilizer.stopUserAction, 100);
		});
	}
}

export default MultiPageTableReplacer;
