import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAiScheme } from '../types/IAiScheme';
import { TextCategories } from '../types/types';

const initialState: IAiScheme = {
	categories: [
		{
			title:	TextCategories.GREETINGS,
			childs: null,
		},
		{
			title:	TextCategories.PRODUCT_DESCRIPTION,
			childs: null,
		},
		{
			title:	TextCategories.ADVANTAGES,
			childs: [
				{
					title:	TextCategories.ADVANTAGES_LIST,
					childs: null,
				},
				{
					title:	TextCategories.ADVANTAGES_DESCRIPTION,
					childs: null,
				},
			],
		},
		{
			title:	TextCategories.TEAM,
			childs: null,
		},
		{
			title:	TextCategories.HEADER,
			childs: [
				{
					title: TextCategories.SLOGAN,
					childs: null,
				},
			],
		},
		{
			title:	TextCategories.ABOUT_COMPANY,
			childs: null,
		},
		{
			title:	TextCategories.UNIQUE_PROPOSITION,
			childs: null,
		},
		{
			title:	TextCategories.WORK_STAGES,
			childs: null,
		},
	],
	modalsActive: {
		isAiCategoriesModalOpen: false, // Главная модалка для генерации текстовых промптов
		isGreetingsOpen: false, // Приветствие
		isProductDescriptionOpen: false, // Описание товара или услуги
		isAdvantagesListOpen: false, // Список преимуществ
		isAdvantagesDescriptionOpen: false, // Описание преимуществ
		isTeamOpen: false, // Команда
		isHeaderOpen: false, // Шапка
		isAboutCompanyOpen: false, // О компании
		isUniquePropositionOpen: false, // Уникальное торговое предложение
		isWorkStagesOpen: false, // Этапы работы
	},
};

export const aiSlice = createSlice({
	name: 'ai',
	initialState,
	selectors: {
		// Категории
		getCategories: (state) => state.categories,
		// Модалка категорий
		getIsAiCategoriesModalActive: (state) => state.modalsActive.isAiCategoriesModalOpen,
		// Модалка Приветствие
		getIsGreetingsOpen: (state) => state.modalsActive.isGreetingsOpen,
		// Модалка Описание товара или услуги
		getIsProductDescriptionOpen: (state) => state.modalsActive.isProductDescriptionOpen,
		// Модалка Список преимуществ
		getIsAdvantagesListOpen: (state) => state.modalsActive.isAdvantagesListOpen,
		// Модалка Описание преимуществ
		getIsAdvantagesDescriptionOpen: (state) => state.modalsActive.isAdvantagesDescriptionOpen,
		// Модалка Команда
		getIsTeamOpen: (state) => state.modalsActive.isTeamOpen,
		// Модалка Шапка
		getSloganOpen: (state) => state.modalsActive.isHeaderOpen,
		// Модалка О компании
		getIsAboutCompanyOpen: (state) => state.modalsActive.isAboutCompanyOpen,
		// Модалка Уникальное торговое предложение
		getIsUniquePropositionOpen: (state) => state.modalsActive.isUniquePropositionOpen,
		// Модалка Этапы работы
		getIsWorkStagesOpen: (state) => state.modalsActive.isWorkStagesOpen,
	},
	reducers: {
		setIsAiCategoriesModalOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isAiCategoriesModalOpen = action.payload;
		},
		setIsGreetingsOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isGreetingsOpen = action.payload;
		},
		setIsProductDescriptionOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isProductDescriptionOpen = action.payload;
		},
		setAdvantagesListOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isAdvantagesListOpen = action.payload;
		},
		setAdvantagesDescriptionOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isAdvantagesDescriptionOpen = action.payload;
		},
		setTeamOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isTeamOpen = action.payload;
		},
		setSloganOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isHeaderOpen = action.payload;
		},
		setAboutCompanyOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isAboutCompanyOpen = action.payload;
		},
		setUniquePropositionOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isUniquePropositionOpen = action.payload;
		},
		setWorkStagesOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isWorkStagesOpen = action.payload;
		},
	},
});

export const {
	setIsAiCategoriesModalOpen,
	setIsGreetingsOpen,
	setIsProductDescriptionOpen,
	setAdvantagesListOpen,
	setAdvantagesDescriptionOpen,
	setTeamOpen,
	setSloganOpen,
	setAboutCompanyOpen,
	setUniquePropositionOpen,
	setWorkStagesOpen,
} = aiSlice.actions;
export const {
	getCategories,
	getIsGreetingsOpen,
	getIsProductDescriptionOpen,
	getIsAdvantagesListOpen,
	getIsAdvantagesDescriptionOpen,
	getIsTeamOpen,
	getSloganOpen,
	getIsAboutCompanyOpen,
	getIsUniquePropositionOpen,
	getIsWorkStagesOpen,
} = aiSlice.selectors;

export const { actions: aiSliceActions, reducer } = aiSlice;

export default aiSlice.reducer;
