import React, { FC, useState } from 'react';
import Loader from 'react-ts-loaders';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ModalWrapper } from '../../modal-wrapper/modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import {
	getIsGreetingsOpen, getIsProductDescriptionOpen,
	setIsAiCategoriesModalOpen,
	setIsProductDescriptionOpen,
} from '../../../model/slice/aiSlice';
import css from './css.module.scss';
import { ReactComponent as AiBtn } from '../../../../../shared/images/ai.svg';
import { AiCSelect } from '../../ai-select/AiCSelect';
import { styleTextSelectOptions } from '../../../model/styleTextSelectOptions';
import { symbolsCountSelectOptions } from '../../../model/symbolsCountSelectOptions';

type FormData = {
	title: string,
	detailsInfo: string,
	keywords: string,
	textStyle: string,
	symbolsCount: string,
};

export const ProductDescriptionModal: FC = () => {
	const dispatch = useAppDispatch();
	const active = useAppSelector(getIsProductDescriptionOpen);

	const [textResponses, setTextResponses] = useState<string[]>([]);
	const [data, setData] = useState('');

	// Для подсчета символов поля ввода доп. информации
	const [symbolsCount, setSymbolsCount] = useState(0);
	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setSymbolsCount(event.target.value.length);
	};

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<FormData>();

	// Добавить обработку запроса к нейросети (пока ждем видим loader)
	const [isLoading, setIsLoading] = useState(false);

	const onClose = () => {
		dispatch(setIsProductDescriptionOpen(false));
	};
	const onGoBack = () => {
		dispatch(setIsProductDescriptionOpen(false));
		dispatch(setIsAiCategoriesModalOpen(true));
	};

	const onSubmit = handleSubmit((data) => console.log(data));

	return (
		<ModalWrapper
			active={active}
			closeModal={onClose}
			onGoBack={onGoBack}
			title="Описание товара или услуги"
		>
			<form className={css.form} onSubmit={handleSubmit((data) => setData(JSON.stringify(data)))}>
				<label htmlFor="title">
					Название
					<textarea
						id="title"
						{...register('title', { required: true })}
						placeholder="Товар или услуга"
					/>
					{errors.title && (
						<span className={css.error} role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				{/* textarea - Дополнительная информация */}
				<label htmlFor="detailsInfo">
					Дополнительная информация
					<span className={css.symbolsCount}>
						{symbolsCount}
						/200
					</span>
					<textarea
						id="detailsInfo"
						{...register('detailsInfo', {
							required: true,
						})}
						placeholder="Лучшее предложение на рынке"
						onChange={handleChange}
						maxLength={200}
					/>
					{errors.detailsInfo && (
						<span className={css.error} role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<label htmlFor="keywords">
					Ключевые слова
					<textarea
						id="keywords"
						{...register('keywords', {
							required: true,
						})}
						placeholder="Быстро, надежно"
					/>
					{errors.keywords && (
						<span className={css.error} role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<div className={css.selectWrapper}>
					<AiCSelect
						title="Стиль текста"
						options={styleTextSelectOptions}
						defaultOption={{ title: 'По умолчанию...', value: 'Нейтральный' }}
					/>
					<AiCSelect
						title="Количество символов"
						options={symbolsCountSelectOptions}
						defaultOption={{ title: 'По умолчанию...', value: '3000' }}
					/>
				</div>

				<div className={css.submit}>
					<input className={css.submit_btn} type="submit" value="Написать" />
					<AiBtn className={css.submit_icon} />
				</div>
			</form>

			{
				(isLoading && textResponses.length == 0) && (
					<Loader
						type="roller"
						color="rgba(57, 150, 218, 1)"
						size={100}
						className="alt-loader"
					/>
				)
			}

			{textResponses.length >= 1 && (
				<div className={css.responsesWrapper}>
					<div className={css.responses}>
						{textResponses.map((data) => (
							<div className={css.response}>
								{data}
							</div>
						))}
					</div>

					{isLoading && (
						<Loader
							type="roller"
							color="rgba(57, 150, 218, 1)"
							size={100}
							className="alt-loader"
						/>
					)}
				</div>
			)}
		</ModalWrapper>
	);
};
