import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'react-ts-loaders';
import cx from 'classnames';
import { TemplateCard } from '../../../../../../widgets/templates/template-card';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/libs/redux';
import { IWorkshopTemplate } from '../../../../../../entities/templates/workshop/template/model/types';
import { ReactComponent as GalleryIcon } from '../../images/template-gallery.svg';
import css from './template.module.scss';
import { TemplateInfoIcon } from '../../../../../../shared/ui';
import { WorkshopTemplateContextMenu } from './context-menu/Workshop-context-menu';
import { useWorkshopContextMenu } from '../../libs/useWorkshopContextMenu';
import useContextMenu from '../../../../../../hooks/mouse/useContextMenu';
import { notificationError } from '../../../../../Notifications/callNotifcation';
import {
	setActiveWorkshopTemplate,
	setSelectedTemplate,
	setTemplateForRenaming,
	setWorkshopTemplateSelectedList,
} from '../../../../../../entities/templates/workshop/template/model/slice';
import { workshopTemplateAPI } from '../../../../../../entities/templates/workshop/template/api/api';
import { TemplateCardProps } from '../../../../../../widgets/templates/template-card/ui/template-card';

interface WorkshopTemplateProps extends TemplateCardProps {
	template: IWorkshopTemplate,
	isGlobalLoading: boolean;
	setGlobalLoading: (templateId: string | null) => void,
}

/**
 * Компонент карточки шаблона мастерской
 */
export const WorkshopTemplate = ({
	selected,
	template,
	isGlobalLoading,
	setGlobalLoading,
}: WorkshopTemplateProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const templateForRenaming = useAppSelector((state) => state.workshopTemplate.templateForRename);
	const selectedTemplates = useAppSelector(state => state.workshopTemplate.workshopTemplateSelectedList);

	const cleanSelectedList = () => dispatch(setWorkshopTemplateSelectedList([]));

	const [updateTemplate] = workshopTemplateAPI.useUpdateMutation();

	const onFinishEditable = (id: string, name: string) => {
		dispatch(setTemplateForRenaming(null));
		updateTemplate({ template: id, name });
	};

	const { id } = template;

	const [isLoading, setIsLoading] = useState(false);

	// * Перейти на панель настройки шаблона
	const toTemplatePanel = async (event: React.MouseEvent) => {
		if (!isGlobalLoading) {
			setGlobalLoading(id);
			setIsLoading(true);
			try {
				// Проверяем, был ли клик на инпуте
				const target = event.target as HTMLElement;
				if (target.tagName !== 'INPUT') {
					// Если клик не на инпуте отправляем на панель шаблона
					await navigate(`/integrator/workshop/templates/${template.id}`);
				}
			} catch (error) {
				notificationError('Возникла ошибка при выборе шаблона!');
				console.error('Произошла ошибка: ', error);
			} finally {
				setIsLoading(false);
				setGlobalLoading(null);
			}
		}
	};

	const {
		onDelete,
		handleMenu,
		copyLink,
		isActionMenuOpened,
		rename,
	} = useWorkshopContextMenu(template.id);

	const {
		menuRef,
		wrapperRef,
		menuPosition,
		handleItemClick,
	} = useContextMenu({
		setMenuVisible: handleMenu,
	});

	const onContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
		handleItemClick(e);
		dispatch(setActiveWorkshopTemplate(template.id));
		dispatch(setSelectedTemplate([template]));

		if (!selectedTemplates.includes(template.id)) {
			cleanSelectedList();
			dispatch(setWorkshopTemplateSelectedList([template.id]));
		}
	};

	const isEditTitle = templateForRenaming === template.id;

	return (
		<div
			ref={wrapperRef}
			role="presentation"
			className={cx(css.wrapper, { [css.disabled]: isGlobalLoading })}
			onClick={!isGlobalLoading ? toTemplatePanel : undefined}
			onContextMenu={!isGlobalLoading ? onContextMenu : undefined}
		>
			{
				template?.gallery && !template?.integration && (
					<TemplateInfoIcon type="bright">
						<GalleryIcon />
					</TemplateInfoIcon>
				)
			}
			{
				!template?.gallery && template?.integration && (
					<TemplateInfoIcon type="bright">
						<img className={css.icon} src={`/api/file/${template?.integration.photo}`} alt="user" />
					</TemplateInfoIcon>
				)
			}
			<TemplateCard
				template={template}
				selected={selected}
				isEditTitle={isEditTitle}
				onFinishEditTemplateTitle={onFinishEditable}
			>
				{
					isLoading && <Loader className={css.loader} type="ring" color="#d8d7d7" size={80} />
				}
			</TemplateCard>
			<WorkshopTemplateContextMenu
				menuRef={menuRef}
				onDelete={onDelete}
				menuPosition={menuPosition}
				onCopyLink={copyLink}
				setIsActionMenuOpened={handleMenu}
				isActionMenuOpened={isActionMenuOpened}
				onContextMenuRename={rename}
			/>
		</div>
	);
};
