import MultiPageUseCases, { IMultiPageUseCasesDependencies } from '../multi-page/MultiPageUseCases';
import IModuleUseCases from '../IModuleUseCases';
import ModuleBuilder from '../../factories/ModuleBuilder';
import Utils from '../../utils/impl/Utils';
import { onCloseModuleWindow, onOpenCreateWorkshopModule } from '../../../../redux/thunk-creators/moduleWindow';
import store from '../../../../redux/store/store';
import IImportTableStructure from '../../mechanics/import-table/IImportTableStructure';
import XLSXTableImporter from '../../mechanics/import-table/implements/XLSXTableImporter';
import MultiPageTableReplacer from '../../mechanics/replace-table/MultiPageTableReplacer';
import TableComponent from '../../components/table/TableComponent';
import SketchComponentType from '../../components/SketchComponentType';
import { workshopModuleApi } from '../../../../entities/workshop-module';
import { templateModuleActions } from '../../../../entities/template-module';
import ComponentOrganizer from '../../mechanics/component-organizer/ComponentOrganizer';
import SpatialTableCellArea
	from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableCellArea';
import MultiPageTableSplitter from '../../mechanics/split-table/MultiPageTableSplitter';

interface IWorkshopTemplateUseCasesDependencies extends IMultiPageUseCasesDependencies {
	moduleBuilder: ModuleBuilder,
	componentOrganizer: ComponentOrganizer,
}

class WorkshopTemplateUseCases
	extends MultiPageUseCases<IWorkshopTemplateUseCasesDependencies>
	implements IModuleUseCases {
	private readonly tableSplitter: MultiPageTableSplitter;
	private readonly XLSXTableImporter: XLSXTableImporter;
	private readonly XLSXTableReplacer: MultiPageTableReplacer;

	constructor() {
		super();
		this.tableSplitter = new MultiPageTableSplitter();
		this.XLSXTableImporter = new XLSXTableImporter();
		this.XLSXTableReplacer = new MultiPageTableReplacer();

		this.addPostInjectDependenciesListener(dependencies => {
			this.tableSplitter.connectDependencies({
				spatialTree: dependencies.spatialTree,
				componentTree: dependencies.componentTree,
				sketchStabilizer: dependencies.sketchStabilizer,
				componentOrganizer: dependencies.componentOrganizer,
				componentFocusObserver: dependencies.componentFocusObserver,
			});
			this.XLSXTableReplacer.connectDependencies({
				spatialTree: dependencies.spatialTree,
				XLSXTableImporter: this.XLSXTableImporter,
				sketchStabilizer: dependencies.sketchStabilizer,
				componentOrganizer: dependencies.componentOrganizer,
				componentFocusObserver: dependencies.componentFocusObserver,
			});
			this.tableSplitter.injectDependencies();
			this.XLSXTableReplacer.injectDependencies();
		});
	}

	/**
	 * Создаёт модуль из компонентов в фокусе. При создании модуля из одного uniter компонента (например это компонент
	 * группы) этот компонент конвертируется в компонент модуля, т.к. никакого смысла в группе нет в данном случае.
	 */
	public createModule = () => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			return;
		}

		const moduleComponent = this.dependencies.moduleBuilder.build(...focusComponents);

		Utils.Generate.ComponentPreview(moduleComponent, fileID => {
			onOpenCreateWorkshopModule(showPreview => {
				showPreview(fileID);
			}, moduleComponent.getUniqueStructure());
		});

		store.dispatch(templateModuleActions.setOnEntityModuleClick(this.injectModule));
	};

	public injectModule = (moduleID: string) => {
		const res = store.dispatch(workshopModuleApi.endpoints?.getWorkshopModule.initiate({ moduleId: moduleID }));

		res.unwrap()
			.then(res => {
				this.dependencies.componentInjector.injectModule(res.rootComponent, res.module.preview);
				onCloseModuleWindow();
			});
	};

	public splitTable = (component: TableComponent, activeCellArea: SpatialTableCellArea) => {
		this.dependencies.sketchStabilizer.startUserAction();
		this.tableSplitter.splitTable(component, activeCellArea);
	};

	public replaceTablesFromXLSX = () => {
		const tables = this.dependencies.componentTree
			.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);
		if (tables === null) {
			return;
		}

		this.XLSXTableReplacer.replaceTable(tables);
	};

	/**
	 * Запускает процесс выбора XLSX файла пользователем и создания таблиц из его структуры.
	 * В случае, если в файле несколько листов, то будет создано несколько таблиц.
	 */
	public createTableFromXLSX = () => {
		this.XLSXTableImporter.getStructure((tables: IImportTableStructure[]) => {
			// Всегда вставляем таблицу из первого листа.
			const { rowCount, columnCount, cells } = tables[0];
			this.dependencies.sketchStabilizer.startUserAction();
			this.dependencies.componentInjector.injectTable(rowCount, columnCount, cells);
			this.dependencies.componentInjector
				.addSingleUseInjectListener(this.dependencies.sketchStabilizer.stopUserAction);
		});
	};
}

export default WorkshopTemplateUseCases;
